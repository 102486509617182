<script>

import redactor from "../form/redactor";
import pdf from "vue-pdf";
import audioAndVideoReview from "./audioAndVideoReview";
import annotationsLayer from "./annotationsLayer";

import html2canvas from 'html2canvas';
import VueDrawingCanvas from "vue-drawing-canvas";

const _ = require('lodash');

import {scroller} from "vue-scrollto/src/scrollTo";
import ProofViewEventsPanel from "./proof-view-events-panel";

const ViewMode = {
  Review: 1,
  Compare: 2,
}

const firstScrollTo = scroller();
const secondScrollTo = scroller();

export default {
  name: "proof-view-canvas",
  components: {
    ProofViewEventsPanel,
    redactor,
    pdf,
    audioAndVideoReview,
    annotationsLayer,
    VueDrawingCanvas,
  },
  props: {
    label: {
      type: String,
      required: true
    },
    activeFile: {
      type: Object
    },
    files: {
      required: true
    },
    mode: {
      type: Number,
      required: true
    },
    viewMode: {
      type: Number,
      required: true
    },
    isMobile: {
      type: Boolean,
      required: true
    },
    curUser: {
      required: true
    },
    curApprover: {
      required: true
    },
    curUsers: {
      required: true
    },
    activeUsers: {},
    proofActiveUsers: {},
    events: {},
    curApprovers: {
      required: true
    },
    curAccount: {
      required: true
    },
    curReviewers: {
      required: true
    },
    isReviewMode: {
      type: Boolean,
      default: true
    },
    isCompareMode: {
      type: Boolean,
      default: false
    },
    allowViewAllComments: {
      type: Boolean,
      default: true
    },
    curVersionId: {},
    isModalOpen: {},
    spaceAbove: {},
    spaceOnSides: {},
    curAnnotationType: {},
    deleteAnnotation: {},
    addAnnotation: {},
    curAnnotations: {},
    colorPickerColor: {},
    drawToolLineWidth: {},
    liveProofSizeSelected: {},
    liveProofPredefinedSizes: {},
    liveProofCommentsEnabled: {},
    usersColor: {},
    followingApprover: {},
  },
  data() {
    return {
      initFileLoadComplete: false,
      reviewWindowHeight: 0,
      reviewWindowWidth: 0,
      isContextImageEnabled: true,
      x: 0,
      y: 0,
      drawToolBackgroundColor: 'rgba(255,0,0,0)',
      drawToolImage: '',
      drawToolBackgroundImage: null,
      drawToolEraser: false,
      drawToolDisabled: false,
      drawToolVideoHeight: 0,
      useAnnotationLayerAsContextImage: true,
      annotationUnFocusedOpacity: 0.5,
      editingAnnotationId: 0,
      isRecording: false,
      isLiveProofView: false,
      liveProofLoaded: false,
      liveProofScriptDetected: true,
      liveProofInterval: undefined,
      liveProofActiveUrl: null,
      liveProofViewHeight: 0,
      editingCommentId: 0,
      viewAllComments: true,
      curAnnotation: null,
      showEvents: true,
      mouseX: 0,
      mouseY: 0,
      cursorUpdateEnabled: true,
      cursorUpdateInterval: 50,
      cursorVectorHistory:[]
    }
  },
  async mounted() {
    window.onmessage = this.getMessageHandler();
    if (this.cursorUpdateEnabled) {
      setInterval(this.sendCursorUpdate, this.cursorUpdateInterval);
    }
  },
  methods: {
    sendCursorUpdate: function (force=false) {
      if(!this.activeFile || !this.curVersionId) return;

      this.cursorVectorHistory.push({
        x: this.mouseX,
        y: this.mouseY,
        ts: this.curUnixTime
      });

      if(this.cursorVectorHistory.length>4){
        this.cursorVectorHistory.shift()
      }

      if(this.cursorVectorHistory.length > 1){
        let firstPoint = this.cursorVectorHistory[0];
        let lastPoint = this.cursorVectorHistory[this.cursorVectorHistory.length - 1];

        let vectorX = lastPoint.x - firstPoint.x;
        let vectorY = lastPoint.y - firstPoint.y;

        let magnitude = Math.sqrt(vectorX*vectorX + vectorY*vectorY);

        if(magnitude === 0 && !force){
          return;
        }
        // console.log(`Vector: (${vectorX}, ${vectorY}), Magnitude: ${magnitude}`);
      }

      const event = {
        x: this.mouseX,
        y: this.mouseY,
        proofFileId: this.activeFileId,
        proofVersionId: this.curVersionId,
        proofId: this.activeFile.proofId,
        pageToView: _.get(this.activeFile, 'pdf.pageToView', -1),
        userId: this.getUserId,
        approverId: this.getApproverId,
        containerWidth: this.activeFile.zoomWidth,
        accountId: this.curAccount.id,
        zoom: this.activeFile.zoom,
        zoomMode: this.activeFile.zoomMode,
        rotation:  _.get(this, 'activeFile.rotation', 0)
      };
      this.$emit('cursor-update', event);
    },
    updateCursorPosition: function (event) {
      let bounding = this.$refs.review_window.getBoundingClientRect();
      this.mouseX = event.x - bounding.left;
      this.mouseY = event.y - bounding.top;
    },
    getMessageHandler() {
      let self = this;
      let existingMessageHandler = window.onmessage
      return (e) => {
        if (e && e.data) {
          try {
            let payload = JSON.parse(e.data);
            if (payload.key && payload.key === 'ashore' && payload.iframe_url && payload.id === self.editingUniqueAnnotationId) {
              self.liveProofActiveUrl = new URL(payload.iframe_url).href;
              self.liveProofViewHeight = payload.iframe_height ? payload.iframe_height + 50 : self.liveProofViewHeight;
            }
          } catch (e) {
            // error
          }
        }
        if (existingMessageHandler) {
          existingMessageHandler(e);
        }
      }
    },
    get: function (prop, path, defaultValue) {
      return _.get(prop, path, defaultValue);
    },
    InitFileLoadCompleted() {
      let c = this;
      this.initFileLoadComplete = true;
      if (this.$route.query.pfid !== undefined) {
        let paid = this.$route.query.paid;

        if (paid > 0) {
          let selectedAnnotation = this.activeFile.proofFileAnnotations.find((a) => a.id == paid);
          // console.log(selectedAnnotation)
          if (selectedAnnotation != null) {
            this.viewAnnotation({
              annotation: selectedAnnotation,
              scroll: this.$route.query.pacid === undefined
            });
          }

          if (this.$route.query.pacid !== undefined) {
            let pacid = this.$route.query.pacid;
            if (pacid > 0) {
              let selectedComment = selectedAnnotation.proofFileAnnotationComments.find(
                  (c) => c.id == pacid
              );
              if (selectedComment != null) {
                setTimeout(function () {
                  c.$scrollTo("#" + c.label + "-comment-" + pacid, 500, {
                    container: `#${self.label}-right-bar`,
                    offset: 0,
                    cancelable: false,
                    onDone: function (element) {
                      let e = document.getElementById(
                          c.label + "-comment-" + pacid
                      );
                      e.classList.add("highlight-comment");
                    },
                  });
                }, 300);
              }
            }
          }
        }
      }
      if (this.curUser == null && this.curApprover != null && !this.curApprover.completedProductTour) {
        const reviewScreenTour = _.get(this.$tours, 'review-screen');
        if (reviewScreenTour) reviewScreenTour.start();
      }
    },
    curUnixTime: function () {
      return new Date().getTime() / 1000;
    },
    viewAnnotation: function ({annotation, scroll, toggle}) {
      if (this.isRecording || this.followingApprover) {
        return;
      }

      this.setEditingAnnotation(annotation, toggle);

      if (this.liveProofCommentsEnabled === false && this.isLiveProofView) {
        this.liveProofCommentsEnabled = true;
      }

      if (this.isLiveProofView) {
        let urlChanged = annotation.liveWebProofingUrl !== this.liveProofActiveUrl;
        this.liveProofActiveUrl = annotation.liveWebProofingUrl;
        let nextSize = this.liveProofPredefinedSizes.find(s => s.size === annotation.liveWebProofingResolution);
        let sizeChanged = nextSize != this.liveProofSizeSelected;
        this.$emit("on-live-proof-size-selected", nextSize);
        if (sizeChanged && !urlChanged) {
          this.requestIframeValues();
        }
      }

      if (annotation.docType === "document") {
        this.activeFile.pdf.pageToView = annotation.pdfPage;
      }

      if (annotation.docType === "video" || annotation.docType === "audio") {
        let s = this.getActiveAudioVideoPlayer();
        if (s) s.goTo(annotation.curTime);
      }

      if (scroll !== false) {
        let self = this;
        this.$nextTick().then(function () {
          setTimeout(function () {
            if (typeof self.scrollAnimationTask === "function") {
              self.scrollAnimationTask();
            }
            self.scrollAnimationTask = self.$scrollTo(
                `#${self.label}-annotation_id_${annotation.id}`,
                500,
                {container: `#${self.label}-review-container`, offset: -200, cancelable: true}
            );
          }, 500)
        })
      }
    },
    reviewFile: function (file) {
      if (this.isRecording) {
        return false;
      }

      this.liveProofScriptDetected = true;
      this.liveProofLoaded = false;
      this.isLiveProofView = (file.isLiveWebProofing) ? file.isLiveWebProofing : false;
      this.liveProofActiveUrl = this.isLiveProofView ? file.name : null;

      this.$emit("on-live-proof-view", {
        isLiveProofView: this.isLiveProofView,
        liveProofActiveUrl: this.liveProofActiveUrl,
      });

      //start tracking the loading status
      if (file.startedLoading === 0) file.startedLoading = this.curUnixTime();

      //pause the the player if it is playing
      if (this.activeFile) {
        if (!document.activeElement.isContentEditable) {
          this.pauseOrResumePlay();
        }
      }

      //save the last observed file
      this.$A.SaveDataCache("proof_version_id:" + this.curVersionId + "_fileId", file.id);

      return true;
    },
    getActiveAudioVideoPlayer: function () {
      return _.get(this.$refs['audioAndVideoReview_id_' + this.activeFileId], [0]);
    },
    getActivePdfDoc: function () {
      return _.get(this.$refs['pdfItem_id_' + this.activeFileId], [0]);
    },
    updateOnFileChanged: function () {
      if (!document.activeElement.isContentEditable) {
        let s = this.getActiveAudioVideoPlayer();
        if (s) this.$root.$emit("player:progress:change", s.lastTimeUpdateSecond);
      }

      this.updateZoom(1)
      this.triggerHeightUpdate();
      this.resetDrawing();

      this.$scrollTo(
          `#${this.label}-review-container`,
          500,
          {container: `#${this.label}-review-container`, offset: -200, cancelable: true}
      );
    },
    calcReviewWindowHeight: function () {
      if (this.isMobile) {
        return window.innerHeight;
      }
      return window.innerHeight - this.spaceAbove;
    },
    calcReviewWindowWidth: function () {
      let isCompareMode = this.viewMode === ViewMode.Compare;
      if (this.isMobile || isCompareMode) {
        return isCompareMode ? window.innerWidth / 2 : window.innerWidth;
      }
      return window.innerWidth - this.spaceOnSides;
    },
    handleDrawingWindowMouseDown: function ($event) {
      let drawingToolRef = this.$refs.drawing_tool;
      if (drawingToolRef && this.curAnnotationType.type === 'draw') {
        let isVideo = this.activeFile.docTypeGroup === 'video';
        if (isVideo) {
          this.pauseOrResumePlay();
        }
        if (Number.isInteger(this.editingAnnotationId)) {
          this.editingAnnotationId = 0;
          this.curAnnotation = null;
          this.handleWindowMouseDown($event);
        } else {
          let editingA = this.getEditingAnnotation();
          if (editingA) {
            if (!editingA.x || !editingA.y) {
              let bounding = this.isLiveProofView ? this.$refs.liveProofIframe[0].getBoundingClientRect() : this.$refs.review_window.getBoundingClientRect();
              editingA.x = $event.x - bounding.left;
              editingA.y = $event.y - bounding.top;
            }
            this.handleAnnotationMove(editingA, true);
          } else {
            this.handleWindowMouseDown($event);
          }
        }
      }
    },
    getAnnotationLayerImage: async function () {
      let target = this.$refs.annotationLayer.$el;
      let bounding = this.isLiveProofView ? this.$refs.liveProofIframe[0].getBoundingClientRect() : this.$refs.review_window.getBoundingClientRect();
      let maxHeight = Math.max(this.drawToolHeight, bounding.bottom);
      let maxWidth = this.isLiveProofView ? this.liveProofViewWidth : this.activeFile.zoomWidth
      let options = {
        x: 0,
        y: 0,
        width: maxWidth,
        height: maxHeight,
      }

      let canvas = await html2canvas(target, options);
      return canvas.toDataURL();
    },
    getContextImage: async function (a) {
      if (!this.isContextImageEnabled) {
        return "";
      }

      if (this.useAnnotationLayerAsContextImage) {
        return this.getAnnotationLayerImage();
      }

      let bounding = this.isLiveProofView ? this.$refs.liveProofIframe[0].getBoundingClientRect() : this.$refs.review_window.getBoundingClientRect();
      let target = document.getElementById(this.label + "-review-container");

      let imageStartX = a.startX ? a.startX : a.x;
      let imageStartY = a.startY ? a.startY : a.y;
      let imageEndX = a.endX ? a.endX : a.x;
      let imageEndY = a.endY ? a.endY : a.y;

      // add padding of parent container
      imageStartX += 15;
      imageStartY += 10;
      imageEndX += 15;
      imageEndY += 10;

      let maxHeight = Math.max(this.drawToolHeight, bounding.bottom, imageEndY);
      let maxWidth = this.activeFile.zoomWidth + 15;

      let width = imageEndX - imageStartX;
      let height = imageEndY - imageStartY;

      let largestDimension = Math.max(width, height, 180);
      let imageDimension = largestDimension + 20; // includes padding at each side

      let paddingX = Math.ceil((imageDimension - width) / 2);
      let paddingY = Math.ceil((imageDimension - height) / 2);

      imageStartX = Math.max(15, imageStartX - paddingX);
      imageStartY = Math.max(10, imageStartY - paddingY);
      imageEndX = Math.min(maxWidth, imageEndX + paddingX);
      imageEndY = Math.min(maxHeight, imageEndY + paddingY);

      width = imageEndX - imageStartX;
      height = imageEndY - imageStartY;

      // if the image width is less that desired width, use additional space in x
      if (width < imageDimension) {
        // lets use more space to the left
        imageStartX = Math.max(15, imageStartX - imageDimension + width);
        // lets use more space to the left
        imageEndX = Math.min(maxWidth, imageEndX + imageDimension - width);
      }

      // if the image height is less that desired height, use additional space in y
      if (height < imageDimension) {
        // lets use more space to the top
        imageStartY = Math.max(10, imageStartY - imageDimension + height);
        // lets use more space to the bottom
        imageEndY = Math.min(maxHeight, imageEndY + imageDimension - height);
      }

      width = imageEndX - imageStartX;
      height = imageEndY - imageStartY;

      let options = {
        x: imageStartX,
        y: imageStartY,
        width: width,
        height: height,
        // proxy: 'https://ashoreapp.com:3000'
      }

      let canvas = await html2canvas(target, options);
      return canvas.toDataURL();
    },
    triggerHeightUpdate: function () {
      if (this.activeFile && this.curAnnotationType.type === 'draw' && this.activeFile.docTypeGroup === 'video') {
        let self = this;
        this.$nextTick(() => {
          let videoRef = self.getActiveAudioVideoPlayer();
          if (videoRef && videoRef.$refs.videoHtml) {
            self.drawToolVideoHeight = videoRef.$refs.videoHtml.getBoundingClientRect().height;
          }
        })
      }
    },
    updateZoom: function (zoomLevel) {
      this.$emit('set-mobile-status');
      this.reviewWindowHeight = this.calcReviewWindowHeight();
      this.reviewWindowWidth = this.calcReviewWindowWidth();
      _.set(this, 'activeFile.zoom', zoomLevel);

      let containerMaxWidth = this.reviewWindowWidth - 20; // subtracting padding amt
      let containerMaxHeight = this.reviewWindowHeight - 20; // subtracting padding amt

      let zoomMode = (window.innerWidth <= 992) ? 'fill_width' : (this.activeFile.zoomMode === 0) ? 'fill_height' : 'fill_width'

      if (
          this.activeFile.docTypeGroup === "image" ||
          this.activeFile.docTypeGroup === "html" ||
          this.activeFile.docTypeGroup === "document" ||
          this.activeFile.docTypeGroup === "video"
      ) {
        if (!this.activeFile.width && !this.activeFile.height && this.activeFile.docTypeGroup === "video") {
          this.activeFile.width = containerMaxWidth;
          this.activeFile.height = containerMaxHeight;
        }
        if (zoomMode === 'fill_width') {
          this.activeFile.zoomWidth = containerMaxWidth * zoomLevel;
          this.activeFile.zoomHeight = (this.activeFile.height * this.activeFile.zoomWidth) / this.activeFile.width;
        }
        if (zoomMode === 'fill_height') {
          let rs = containerMaxWidth / containerMaxHeight;
          let ri = this.activeFile.width / this.activeFile.height;

          let s =
              rs > ri
                  ? [
                    this.activeFile.width *
                    (containerMaxHeight / this.activeFile.height) *
                    zoomLevel,
                    containerMaxHeight * zoomLevel,
                  ]
                  : [
                    containerMaxWidth * zoomLevel,
                    this.activeFile.height *
                    (containerMaxWidth / this.activeFile.width) *
                    zoomLevel,
                  ];

          const shouldUpdateDimensions = this.activeFile.width > containerMaxWidth || this.activeFile.height > containerMaxHeight;

          this.activeFile.zoomWidth =  shouldUpdateDimensions ? s[0] : this.activeFile.width * zoomLevel;
          this.activeFile.zoomHeight = shouldUpdateDimensions ? s[1] : this.activeFile.height * zoomLevel;
        }
      } else {
        this.activeFile.zoomWidth = containerMaxWidth * zoomLevel;
        if (this.activeFile.docTypeGroup !== "image" &&
            this.activeFile.docTypeGroup !== "html") {
          this.activeFile.zoomHeight = containerMaxHeight * zoomLevel;
        }
      }

      if (this.curAnnotationType.type === 'draw') {
        this.resetDrawing();
      }
      this.sendCursorUpdate(true);
      this.triggerHeightUpdate();
    },
    handleFileLoaded: function (event, file) {
      let self = this;
      this.isLiveProofView = (file.isLiveWebProofing) ? file.isLiveWebProofing : false;

      if (!this.isLiveProofView) {
        this.liveProofActiveUrl = null;
      }

      this.$emit("on-live-proof-view", {
        isLiveProofView: this.isLiveProofView,
        liveProofActiveUrl: this.liveProofActiveUrl,
      });

      if (this.shouldDisplayAsImage(file)) {
        let s = event.target === null ? event.path[0] : event.target;
        file.height = s.naturalHeight;
        file.width = s.naturalWidth;
      }

      //let the pdf load fully and then get the size

      if (file.docTypeGroup === "document" && file.width === 0 && file.height === 0 && !this.shouldDisplayPdfAsImage(file)) {
        file.z_index = -100;
        clearTimeout(window.loadingPDF);
        window.loadingPDF = setTimeout(function () {
          const pdfInstance = self.getActivePdfDoc();
          if (pdfInstance) {
            let canvas = pdfInstance.$refs.canvas;
            // console.log(canvas.height, canvas.width)
            file.height = canvas.height;
            file.width = canvas.width;
            file.z_index = 1;
          }
        }, 100);
      }

      // console.log("file loaded size", file.width, file.height)

      if (!file.isLiveWebProofing && (file.docTypeGroup === 'html' || file.docTypeGroup === "document" || file.docTypeGroup === "image") &&
          file.width === 0 && file.height === 0) {
        clearTimeout(window.checkingNextFrameLoadFile);
        window.checkingNextFrameLoadFile = setTimeout(function () {
          // console.log("Checking Next Frame To See If File Has Updated Its size")
          self.handleFileLoaded(event, file)
        }, 100);
        return;
      }

      file.loaded = true;
      self.$scrollTo("body");
      self.updateZoom(1);
      self.triggerHeightUpdate();
      if (!this.initFileLoadComplete) {
        this.InitFileLoadCompleted();
      }
    },
    handleIframeLoaded: function ($event, versionFile) {
      this.liveProofLoaded = true;
      this.handleFileLoaded($event, versionFile);
      this.checkLiveProofScriptDetected();
      this.requestIframeValues();
    },
    resize: function () {
      return this.activeFile ? this.updateZoom(this.activeFile.zoom) : 0;
    },
    rotate: function () {
      let rotation = _.get(this, 'activeFile.rotation', 0) + 90;
      if (rotation > 270) rotation = 0;
      this.setRotation(rotation);
      this.sendCursorUpdate(true);
    },
    setRotation: function(rotation) {
      this.resetDrawing();

      _.set(this, 'activeFile.rotation', rotation);

      let d = document.getElementById(`${this.label}-review-container`);
      if (d.length > 0) {
        d[0].scrollTo(0, 0);
      }
      this.reviewWindowHeight += 1;
    },
    setRecording: function (status) {
      this.isRecording = status;
      this.$emit("on-recording", this.isRecording);
    },
    checkLiveProofScriptDetected: function () {
      if (this.liveProofLoaded) {
        let self = this;
        if (this.isLiveProofView) {
          this.$A.LiveProofService.checkUrl(this.liveProofActiveUrl).then(status => {
            self.liveProofScriptDetected = status.scriptFound;
            if (!status.loaded) {
              let message = "Unable to contact site at given URL";
              self.$A.AlertUser(message);
            } else if (!status.scriptFound) {
              self.liveProofViewHeight = 6000;
              let message = `${self.getLabel('review_screen_labels', 'embed_script_no_detected_alert')}\n\n`
                  + `&lt;script src="${self.$A.LiveProofService.scriptUrl}"&gt;&lt;/script&gt;`;
              self.$A.AlertUser(message);
            }
          })
        }
      }
    },
    requestIframeValues: function () {
      let self = this;
      clearInterval(this.liveProofInterval);
      this.liveProofInterval = setInterval(function () {
        const liveProofIframe = _.get(self, '$refs.liveProofIframe[0].contentWindow');
        if (liveProofIframe) {
          let message = JSON.stringify({key: 'ashore', id: self.editingUniqueAnnotationId, method: 'get'});
          liveProofIframe.postMessage(message, "*");
        } else {
          clearInterval(self.liveProofInterval);
        }
      }, 200)
    },
    updateLiveProofSize: function () {
      let self = this;
      this.$nextTick().then(function () {
        self.liveProofViewHeight = 6000;
        self.requestIframeValues();
      });
    },
    undoDrawing: function () {
      if (this.$refs.drawing_tool) {
        this.$refs.drawing_tool.undo();
      }
    },
    resetDrawing: function () {
      if (this.$refs.drawing_tool && !this.$refs.drawing_tool.isEmpty() && this.activeFile.docTypeGroup !== 'audio') {
        this.$refs.drawing_tool.reset();
      }
    },
    refreshDrawing: function () {
      if (this.$refs.drawing_tool) {
        this.$refs.drawing_tool.redraw();
      }
    },
    shouldDisplayPdfAsImage: function (file) {
      let isPdfImage = file &&
          file.mimeType &&
          file.mimeType === "application/pdf" &&
          file.pdfExtractedJpgPages &&
          file.pdfExtractedJpgPages.length > 0;

      if (isPdfImage) {
        file.pdf.totalPages = file.pdfExtractedJpgPages.length;
      }

      return isPdfImage;
    },
    shouldDisplayAsImage: function (file) {
      return !this.isLiveProofView &&
          (file.docTypeGroup === 'image' || file.docTypeGroup === 'html' || this.shouldDisplayPdfAsImage(file));
    },
    getLabel: function (section, key) {
      return this.$A.LangService.getLabel(section, key);
    },
    getEditingAnnotation: function () {
      if (this.editingAnnotationId !== 0) {
        let editing = this.curAnnotations.filter(a => a.id === this.editingAnnotationId);
        if (editing && editing.length > 0) {
          return editing[0];
        }
      }
      return null;
    },
    toggleZoomMode: function () {
      this.setZoomMode(_.get(this.activeFile, 'zoomMode', 0) === 1 ? 0 : 1);
      this.sendCursorUpdate(true);
    },
    setZoomMode: function(mode) {
      if (!this.activeFile)
        return;
      this.activeFile.zoomMode = mode;
      this.updateZoom(1);
    },
    loadProofVersion: function (version) {
      this.$emit("on-load-proof-version", version);
    },
    setEditingAnnotation: async function (a, toggle) {
      if (!a) return;
      a.isExpanded = true;
      if (this.editingAnnotationId !== 0) {
        let editingA = this.getEditingAnnotation();
        if (
            editingA &&
            Array.isArray(editingA.proofFileAnnotationComments) &&
            editingA.proofFileAnnotationComments.length === 0
        ) {
          await this.deleteAnnotation(editingA);
          this.editingAnnotationId = 0;
        }
      }

      if (this.editingAnnotationId === a.id && toggle) {
        this.editingAnnotationId = 0;
        a.isExpanded = false;
      } else {
        this.editingAnnotationId = a.id;
      }
      this.$emit("on-editing-annotation-id", this.editingAnnotationId);
    },
    handleAnnotationMoveEvent: function ($event) {
      let a = $event.a;
      $event = $event.event;
      if (this.isRecording || this.followingApprover) {
        return;
      }
      this.setEditingAnnotation(a, true);
      if (this.editingAnnotationId !== a.id) {
        return;
      }
      let self = this;
      if (!this.$refs.review_window) {
        return;
      }

      if (a.userId !== _.get(this.curUser, 'id') &&
          a.approverId !== _.get(this.curApprover, 'id')) {
        return; // not the owner so can't move
      }
      a.containerWidth = this.activeFile.zoomWidth;

      let bounding = this.$refs.review_window.getBoundingClientRect();

      // console.log($event.target);
      let calcNewCords = function (e, a) {
        let px = e.x - bounding.left;
        let py = e.y - bounding.top;

        if (a.type === "rect" || a.type === "arrow") {
          a.x2 += px - a.x;
          a.y2 += py - a.y;
          a.x = px;
          a.y = py;

          a.startX = Math.min(a.x, a.x2);
          a.startY = Math.min(a.y, a.y2);
          a.endX = Math.max(a.x, a.x2);
          a.endY = Math.max(a.y, a.y2);
          a.w = a.endX - a.startX;
          a.h = a.endY - a.startY;
        } else {
          a.x = px;
          a.y = py;
        }

      };

      document.onmouseup = function (e) {
        // console.log("annotation moved",a,e);
        document.onmouseup = null;
        if (self.$refs.review_window !== undefined)
          self.$refs.review_window.onmousemove = null;
        // self.editingAnnotationId = 0;
        if (a && a.id && a.id > 0) {
          self.$A.AnnotationService.UpdateAnnotation(a).then(
              (annotationUpdated) => {
                if (annotationUpdated !== null && annotationUpdated.id > 0) {
                  a = annotationUpdated;
                }
                // console.log("annotation move saved",a)
              }
          );
        }
      };

      calcNewCords($event, a);
      this.$refs.review_window.onmousemove = (e) => {
        calcNewCords(e, a);
      };
    },
    updateAnnotationResolvedStatus: function (annotation) {
      let s = this;
      setTimeout(function () {
        s.$A.AnnotationService.UpdateAnnotationResolvedStatus(
            annotation.id,
            annotation.resolved
        );
      }, 1);
    },
    prepNewAnnotation: function () {
      // debugger
      if (this.curAnnotationType.type === "na") {
        return;
      }
      let a = {
        id: this.$A.GetUUID(),
        accountId: this.curAccount.id,
        proofFileId: this.activeFileId,
        proofVersionId: this.curVersionId,
        proofId: this.activeFile.proofId,
        type: this.curAnnotationType.type,
        docType: this.activeFile.docTypeGroup,
        userId: _.get(this.curUser, 'id', 0),
        approverId: _.get(this.curApprover, 'id', 0),
        ownerName: _.get(this.curApprover, 'name', '') || _.get(this.curUser, 'name', ''),
        ownerInitials: _.get(this.curApprover, 'initials', '') || _.get(this.curUser, 'initials', ''),
        createdAt: new Date(),
        resolved: false,
        proofFileAnnotationComments: [],
        zoom: this.activeFile.zoom,
      };
      a.containerWidth = this.activeFile.zoomWidth;
      // special doc additional data
      if (a.docType === "document") {
        a.pdfPage = this.activeFile.pdf.pageToView;
      }
      if (a.docType === "audio") {
        a.curTime = this.activeFile.player.curPlayerTime;
        a.type = "timeline";
      }
      if (a.docType === "video") {
        a.curTime = this.activeFile.player.curPlayerTime;
      }
      if (this.isLiveProofView) {
        a.liveWebProofingUrl = this.liveProofActiveUrl
        a.liveWebProofingResolution = this.liveProofSizeSelected.size
      }

      return a;
    },
    appendNewAnnotation: async function (a, isExistingAnnotation) {
      this.sidebarVisible = true;

      let self = this;
      self.curAnnotation = a;

      let added;
      if (!isExistingAnnotation) {
        await this.setEditingAnnotation(a);
        added = this.addAnnotation(self.curAnnotation);
      }

      self.curAnnotation = null;
      if (!added) return;
      clearTimeout(window.scrollFunc);
      window.scrollFunc = setTimeout(function () {
        if (typeof self.scrollAnimationTask === "function") {
          self.scrollAnimationTask();
        }
        if (typeof self.scrollAnimationRootTask === "function") {
          self.scrollAnimationRootTask();
        }
        self.scrollAnimationTask = firstScrollTo(
            `#${self.label}-comment-${a.id}`,
            500,
            {cancelable: true, container: `#${self.label}-right-bar`}
        );
        self.scrollAnimationRootTask = secondScrollTo(
            `#${self.label}-comment-${a.id}`,
            500,
            {cancelable: true, offset: -30}
        );
      }, 100);
    },
    handleNewAudioCommentCreated: async function ($event) {
      let a = this.prepNewAnnotation();
      a.x = $event.offsetX;
      a.y = 0;

      await this.appendNewAnnotation(a);
    },
    pauseOrResumePlay(resumePlay = false) {
      const s = this.getActiveAudioVideoPlayer();
      if (s) {
        s.pause();
        resumePlay && s.play();
      }
    },
    handleWindowMouseDown: function ($event) {
      if (this.followingApprover || !this.$refs.review_window || this.isRecording || (this.isLiveProofView && !this.liveProofCommentsEnabled)) {
        return;
      }
      // pause when new comment added
      this.pauseOrResumePlay();

      let bounding = this.isLiveProofView ? this.$refs.liveProofIframe[0].getBoundingClientRect() : this.$refs.review_window.getBoundingClientRect();

      let a = this.prepNewAnnotation();
      a.x = $event.x - bounding.left;
      a.y = $event.y - bounding.top;

      this.handleAnnotationMove(a);
    },
    normalizeCoordinates: function (x, y) {
      const degrees = this.toDegrees( _.get(this, 'activeFile.rotation', 0));
      // remove padding
      x -= 15;
      y -= 10;
      let xP = x * Math.cos(degrees) + y * Math.sin(degrees);
      let yP = -1 * x * Math.sin(degrees) + y * Math.cos(degrees);
      return [xP, yP];
    },
    toDegrees: function (angle) {
      return angle * (Math.PI / 180);
    },
    handleAnnotationMove: function (a, skipAppend) {
      let self = this;
      a.down = true;

      if (a.type === "rect" || a.type === "arrow" || a.type === 'draw') {
        this.$refs.review_window.onmousemove = (e) => {
          let bounding = this.isLiveProofView ? this.$refs.liveProofIframe[0].getBoundingClientRect() : this.$refs.review_window.getBoundingClientRect();
          let px = e.x - bounding.left;
          let py = e.y - bounding.top;
          a.x2 = px;
          a.y2 = py;

          if (a.type === 'draw') {
            a.startX = Math.min(a.x, a.x2, a.startX ? a.startX : a.x);
            a.startY = Math.min(a.y, a.y2, a.startY ? a.startY : a.y);
            a.endX = Math.max(a.x, a.x2, a.endX ? a.endX : a.x);
            a.endY = Math.max(a.y, a.y2, a.endY ? a.endY : a.y);
          } else {
            a.startX = Math.min(a.x, a.x2);
            a.startY = Math.min(a.y, a.y2);
            a.endX = Math.max(a.x, a.x2);
            a.endY = Math.max(a.y, a.y2);
          }

          a.w = a.endX - a.startX;
          a.h = a.endY - a.startY;

          self.curAnnotation = a;
        };
      }

      document.onmouseup = (e) => {
        document.onmouseup = null;
        this.$refs.review_window.onmousemove = null;
        self.annotationUnFocusedOpacity = 0;
        a.down = false;
        if (a.x && a.y) {
          [a.x, a.y] = self.normalizeCoordinates(a.x, a.y);
        }
        if (a.x2 && a.y2) {
          [a.x2, a.y2] = self.normalizeCoordinates(a.x2, a.y2);
        }
        if (a.type === "rect" || a.type === "arrow" || a.type === 'draw') {
          [a.startX, a.startY] = self.normalizeCoordinates(a.startX, a.startY);
          [a.endX, a.endY] = self.normalizeCoordinates(a.endX, a.endY);
        }

        self.appendNewAnnotation(a, skipAppend).then(() => {
          self.getContextImage(a).then(data => {
            self.annotationUnFocusedOpacity = 0.5;
            a.contextImageBase64Encoded = data;
            // let img = document.createElement("img");
            // img.src = a.contextImageBase64Encoded;
            // document.body.append(img);
            // console.log(data)
          });
        });
      };
    },
    handleWheelZoomEvent: function ($event, isSmoothFileType = true) {
      // console.log("zoom called");
      // only zoom if we combine the wheel scroll with ctrl key, otherwise actual view scroll functionality will break
      if ($event.ctrlKey) {
        $event.preventDefault();
        // scale based on vertical wheel scroll
        let delta = isSmoothFileType ? $event.deltaY * -0.003 : Math.sign($event.deltaY) * -0.05;
        let scrollZoom = Math.max(this.activeFile.zoom + delta, 0.5);
        this.updateZoom(scrollZoom);
      }
    },
    handleNumPagesLoaded: function (numPages, file) {
      file.pdf.totalPages = numPages;
    },
    setViewComments: function (viewComments) {
      this.viewAllComments = viewComments;
    },
    getDrawToolImage: function () {
      const drawingTool = _.get(this.$refs, 'drawing_tool');
      if (!drawingTool || _.isEmpty(drawingTool)) return null;

      return this.drawToolImage;
    },
    shouldShowLoader: function (activeFile) {
      return activeFile && (!activeFile.loaded || activeFile.converting) && activeFile.docTypeGroup !== 'document';
    },
    shouldShowTimer: function (activeFile) {
      return activeFile.converting && activeFile.docTypeGroup !== 'document';
    }
  },
  computed: {
    activeFileId: function () {
      return _.get(this.activeFile, 'id', 0);
    },
    _activeUsers: function() {
      // debugger
      return this.curAnnotationType && this.curAnnotationType.type === 'draw' ? this.activeUsers : [];
    },
    reviewContainerClasses: function () {
      switch (this.viewMode) {
        case ViewMode.Review:
          return "review-container " + (this.sidebarVisible ? "col-lg-9 col-sm-12" : "review-container col-12");
        case ViewMode.Compare:
          return "review-container col-lg-12";
        default:
          return "review-container col-lg-3 col-sm-12";
      }
    },
    drawToolHeight: function () {
      if (this.activeFile) {
        if (this.activeFile.docTypeGroup === 'video') {
          if (this.drawToolVideoHeight == 0) {
            this.triggerHeightUpdate();
          }
          return this.drawToolVideoHeight;
        }
        if (this.isLiveProofView) {
          return this.liveProofViewHeight;
        }
        return this.activeFile.zoomHeight;
      }
      return 500;
    },
    liveProofViewWidth: function () {
      if (this.liveProofSizeSelected.size > 0) {
        return this.liveProofSizeSelected.size;
      }
      let self = this;
      let reviewScreenWidthSize = this.calcReviewWindowWidth();

      this.liveProofPredefinedSizes.forEach(sizeOption => {
        let optionFitsOnScreen = sizeOption.size <= reviewScreenWidthSize
        let optionIsLargerThanCurrent = sizeOption.size > self.liveProofSizeSelected.size
        if (optionFitsOnScreen && optionIsLargerThanCurrent) {
          this.$emit("on-live-proof-size-selected", sizeOption);
        }
      })

      return this.liveProofSizeSelected.size;
    },
    annotationTypes: function () {
      if (this.activeFile) {
        let annotationTypes = [
          {
            docTypes: ["document", "video", "image", "html", "web_url"],
            type: "point",
            title: () => this.getLabel('review_screen_labels', 'anno_single_click'),
            image: "/img/icon-doubleclick.svg",
            cursor: "/img/icon-doubleclick.png",
          },
          {
            docTypes: ["document", "video", "image", "html", "web_url"],
            type: "rect",
            title: () => this.getLabel('review_screen_labels', 'anno_rect'),
            image: "/img/icon-rectangle.svg",
            cursor: "/img/icon-rectangle.png",
          },
          {
            docTypes: ["document", "video", "image", "html", "web_url"],
            type: "arrow",
            title: () => this.getLabel('review_screen_labels', 'anno_arrow'),
            image: "/img/icon-arrow.svg",
            cursor: "/img/icon-arrow.png",
          },
          {
            docTypes: ["document", "video", "image", "html", "web_url"],
            type: "draw",
            title: () => this.getLabel('review_screen_labels', 'anno_draw'),
            image: "/img/draw.svg",
            cursor: ""
          },
        ];

        if (this.isMobile) {
          return annotationTypes.filter((i) => (i.type === "point" || i.type === "draw"));
        }

        return annotationTypes.filter(
            (i) => i.docTypes.indexOf(this.activeFile.docTypeGroup) > -1
        );
      } else {
        return [];
      }
    },
    filesLoaded: function () {
      return this.files.filter(file => file.startedLoading !== 0 || file.loaded);
    },
    curFileSrcImageUrl: function () {
      if (this.shouldDisplayPdfAsImage(this.activeFile)) {
        return this.activeFile.pdfExtractedJpgPages[this.activeFile.pdf.pageToView - 1].uri;
      }
      if (this.activeFile) {
        return this.activeFile.src;
      }
      return "";
    },
    editingUniqueAnnotationId: function () {
      return this._uid + "_" + this.editingAnnotationId;
    },
    rotation: function () {
      return _.get(this.activeFile, 'rotation', 0);
    },
    reviewWindowHeightPx: function () {
      if (this.reviewWindowHeight === 0) {
        return '100%';
      }
      return this.reviewWindowHeight + 'px';
    },
    reviewWindowWidthPx: function () {
      if (this.reviewWindowWidth === 0) {
        return '100%';
      }
      return this.reviewWindowWidth + 'px';
    },
    getUserId: function () {
      return _.get(this.curUser, 'id', 0);
    },
    getApproverId: function () {
      return _.get(this.curApprover, 'id', 0);
    },
  },
  watch: {
    sidebarVisible: function () {
      let self = this;
      this.$nextTick().then(function () {
        if (self.activeFile)
          self.updateZoom(self.activeFile.zoom);
      });
    },
    editingAnnotationId: function () {
      let drawingToolRef = this.$refs.drawing_tool;
      if (drawingToolRef && this.curAnnotationType.type === 'draw'
          && !drawingToolRef.isEmpty()) {
        if (Number.isInteger(this.editingAnnotationId) && this.editingAnnotationId > 0) {
          this.resetDrawing();
        }
      }
    },
    curAnnotationType: function () {
      if (this.curAnnotationType.type === 'draw') {
        this.resetDrawing();
        let a = this.prepNewAnnotation();
        this.appendNewAnnotation(a);
        this.triggerHeightUpdate();
      }
    },
  },
}
</script>

<template>
  <div>
    <!-- Live Web Proofing Warning Message -->
    <div v-if="!liveProofScriptDetected && isLiveProofView"
         :class="'d-flex justify-content-between bg-white p-2 ' + (!isCompareMode ? 'col-12': 'col-12 border-left')">
      <span class="ml-2"> <span class="rounded-circle bg-danger d-inline-block mr-1"
                                style="width: 7px; height: 7px;"></span> {{
          getLabel('review_screen_labels', 'web_script_error')
        }}</span>
      <a class="mr-2 font-weight-bold" href="https://kb.ashoreapp.com/ashore-embed-script/"
         target="_blank">{{ getLabel('review_screen_labels', 'embed_script_instructions') }}<img
          src="/img/icon-arrow-right.svg" class="ml-1"/> </a>
    </div>

    <!-- Proof Window -->
    <b-col
        :id="`${label}-review-container`"
        ref="reviewContainer"
        class="reviewContainerClasses p-2"
        :style="(isMobile) ? '': {
              height: reviewWindowHeightPx,
              width: reviewWindowWidthPx,
              overflow: 'scroll',
              border: followingApprover ? `5px solid ${followingApprover.color}` : '',
              cursor: (activeFile && activeFile.docTypeGroup !== 'audio' ? 'url(' + curAnnotationType.cursor + '), auto' : 'default'),
            }"
        @mouseover="isMouseOver=true"
        @mouseleave="isMouseOver=false"
        @mousemove="updateCursorPosition"
    >
      <div v-if="followingApprover"
           :style="{
              position: 'absolute',
              'z-index': 5000,
              top: 0,
              left: '50%',
              transform: 'translate(-50%)',
              'background-color': followingApprover.color,
              color: 'white',
              padding: '0px 3px 3px 3px',
              'border-radius': '0 0 5px 5px'
            }"
      >
        <span class="pr-2" style="font-size: 12px">Following {{followingApprover.name}}</span>
        <button
            @click="$emit('stop-following-approver')"
            :style="{
              'background-color': `lighten(${followingApprover.color}, 0.8)`,
              'color': followingApprover.color,
              opacity: '0.8 !important',
              border: '1px solid white',
              'border-radius': '25px',
              'font-size': '12px',
            }"
        >Stop Following</button>
      </div>
      <div v-if="shouldShowLoader(activeFile)">
        <img src="/img/review-screen-loading.gif" class="loading-img"/>
        <p class="loading-text" v-if="shouldShowTimer(activeFile)">
          {{ activeFile.curTimer }}
        </p>
      </div>
      <div
          class="review-window"
          ref="review_window"
          :id="`${label}-review-window`"
          draggable="false"
      >
        <!-- draw tool -->
        <div
            v-if="!followingApprover && activeFile && curAnnotationType.type==='draw' && activeFile.docTypeGroup !== 'audio' && (!isLiveProofView || liveProofCommentsEnabled)"
            @mousedown="handleDrawingWindowMouseDown"
            :style="{
                    'border': '0px',
                    'position': 'absolute',
                    'overflow': 'visible',
                    'z-index': '900',
                    'height': drawToolHeight + 'px',
                    'width': activeFile.zoomWidth + 'px'
                }">
          <vue-drawing-canvas ref="drawing_tool"
                              v-show="viewAllComments"
                              :image.sync="drawToolImage"
                              :eraser="drawToolEraser"
                              :lineWidth="drawToolLineWidth"
                              :color="colorPickerColor.hex8 ? colorPickerColor.hex8 : colorPickerColor"
                              :background-color="drawToolBackgroundColor"
                              :background-image="drawToolBackgroundImage"
                              :width="isLiveProofView ? liveProofViewWidth : activeFile.zoomWidth"
                              :height="drawToolHeight"
                              :disabled="drawToolDisabled"
                              saveAs="png"
                              :styles="{
                                          cursor: 'url(' + curAnnotationType.cursor + '), auto',
                                           border: '0px',
                                           position: 'absolute',
                                           overflow: 'visible',
                                           'z-index': '2000',
                                  }"
          />
        </div>
        <!-- end draw tool -->
        <annotationsLayer
            ref="annotationLayer"
            :parent-id="label"
            :editingAnnotationId="editingAnnotationId"
            :curAnnotationType="curAnnotationType"
            :curAnnotations="curAnnotations"
            :curAnnotation="curAnnotation"
            :viewAllComments="viewAllComments && (!isLiveProofView || liveProofCommentsEnabled)"
            :pdf-page="get(activeFile, 'pdf.pageToView', 1)"
            :approvers="curApprovers"
            :users="curUsers"
            :cur-user="curUser"
            :cur-approver="curApprover"
            :reviewers="curReviewers"
            :active-users="activeUsers"
            :cur-container-width="(isLiveProofView&&liveProofCommentsEnabled)?liveProofViewWidth:get(activeFile, 'zoomWidth', 0)"
            :cur-container-height="get(activeFile, 'zoomHeight', 0)"
            :live-proof-active-url="liveProofActiveUrl"
            :live-proof-size-selected="liveProofSizeSelected"
            :unFocusedOpacity="annotationUnFocusedOpacity"
            :allow-view-all-comments="allowViewAllComments"
            v-on:onannotationmoved="handleAnnotationMoveEvent($event)"
            v-on:mousedown="handleWindowMouseDown($event)"
            :rotation="activeFile ? activeFile.rotation : 0"
        />
        <div v-for="versionFile in filesLoaded.filter(i=>i.isLiveWebProofing)"
             :key="`liveproof_layer_${versionFile.id}`"
             v-if="activeFileId === versionFile.id"
             v-show="liveProofCommentsEnabled"
             class="live-proof-layer"
             :style="{width: liveProofViewWidth + 'px', height: liveProofViewHeight + 'px'}"
             v-on:mousedown="handleWindowMouseDown($event)"
        ></div>
        <iframe class="d-block border-0 overflow-scroll"
                v-for="versionFile in filesLoaded.filter(i=>i.isLiveWebProofing)"
                :id="`liveproof_id_${versionFile.id}`"
                :key="`liveproof_id_${versionFile.id}`"
                ref="liveProofIframe"
                draggable="false"
                v-on:load="handleIframeLoaded($event, versionFile)"
                v-if="activeFileId === versionFile.id"
                :src="liveProofActiveUrl?liveProofActiveUrl:versionFile.name"
                :width="liveProofViewWidth"
                :height="liveProofViewHeight"
        />
        <!-- WIP Added transform:.. -->
        <img v-for="versionFile in filesLoaded.filter(file=>shouldDisplayAsImage(file))"
             :ref="`image_id_${versionFile.id}`"
             :key="`image_id_${versionFile.id}`"
             :id="`image_id_${versionFile.id}`"
             draggable="false"
             v-on:mousedown="handleWindowMouseDown($event)"
             v-on:wheel="handleWheelZoomEvent($event)"
             v-show="activeFileId === versionFile.id"
             :src="curFileSrcImageUrl"
             v-on:load="handleFileLoaded($event, versionFile)"
             :class="`rotate${activeFile&&activeFile.rotation?activeFile.rotation:0}`"
             :style=" (activeFile)?{
                    width: activeFile.zoomWidth + 'px',
                    height: activeFile.zoomHeight+ 'px'
                }:{}"
        />
        <div class="click-window-pdf"
             v-for="versionFile in filesLoaded.filter(i=>i.docTypeGroup === 'document' && !shouldDisplayPdfAsImage(i))"
             :key="`pdfItem_id_${versionFile.id}`"
             v-show="activeFileId === versionFile.id"
             v-on:mousedown="handleWindowMouseDown($event)"
             v-on:wheel="handleWheelZoomEvent($event, false)"
             :style=" (activeFile && activeFile.loaded)?{
                    transform: `rotate(${activeFile.rotation}deg)`,
                    width: activeFile.zoomWidth + 'px',
                    height: activeFile.zoomHeight+ 'px',
                    'z-index': activeFile.z_index,
                    'position':(isMobile)?'':'absolute',
                    'margin-bottom':(isMobile)?'10px':'',
                  }:{}"
        >
          <pdf
              v-if="activeFile"
              :ref="`pdfItem_id_${versionFile.id}`"
              draggable="false"
              :src="versionFile.src"
              :page="activeFile.pdf.pageToView"
              @loaded="handleFileLoaded($event, versionFile)"
              @num-pages="handleNumPagesLoaded($event, versionFile)"
          />
        </div>
        <audioAndVideoReview
            v-for="versionFile in filesLoaded.filter(i=>i.docTypeGroup === 'video' || i.docTypeGroup === 'audio')"
            :key="`audioAndVideoReview_id_${versionFile.id}`"
            :ref="`audioAndVideoReview_id_${versionFile.id}`"
            draggable="false"
            v-show="activeFileId === versionFile.id"
            :file-id="versionFile.id"
            v-on:onplayerloaded="handleFileLoaded($event, versionFile)"
            v-on:mousedown="handleWindowMouseDown($event, versionFile)"
            v-on:oncommentcreated="handleNewAudioCommentCreated($event, versionFile)"
            :doc-type="versionFile.docTypeGroup"
            :contentType="versionFile.convertedMimeType"
            :annotations="versionFile.proofFileAnnotations"
            :src="versionFile.src"
            :poster="versionFile.thumbnailFileKey"
            :curWidth="(activeFile)?activeFile.zoomWidth:0"
            :isMobile="isMobile"
            :curHeight="(activeFile)?activeFile.zoomHeight:0"
            :zoom-mode="(activeFile)?activeFile.zoomMode:1"
            :editingAnnotationId="editingAnnotationId"
            :is-recording="isRecording"
        />
        <proof-view-events-panel
            v-show="showEvents && activeFile && !isMobile"
            :approvers="curApprovers"
            :users="curUsers"
            :reviewers="curReviewers"
            :events="events"
            :users-color="usersColor"
            :active-users="proofActiveUsers"
            :parent-id="label"
            @annotation-selected="setEditingAnnotation"
            @version-selected="loadProofVersion"
        />
      </div>
    </b-col>
    <!--    </div>-->
  </div>
</template>

<style scoped>
.rotate0 {
  transform: rotate(0deg);
  transform-origin: center center;
}

.rotate90 {
  transform: rotate(90deg) translateY(-100%);
  transform-origin: top left;
}

.rotate180 {
  transform: rotate(180deg);
  transform-origin: center center;
}

.rotate270 {
  transform: translateX(-100%) rotate(-90deg);
  transform-origin: top right;
}

.live-proof-layer {
  background-color: transparent !important;
  position: absolute;
  display: block;
  z-index: 100;
}

.loading-img {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  box-shadow: transparent 1px 1px;
  position: absolute;
  z-index: 2;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
}

.loading-text {
  font-weight: bold;
  font-size: 16px;
  position: absolute;
  z-index: 2;
  left: calc(50% - 22px);
  top: calc(50% - 8px);
}


</style>
