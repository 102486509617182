<script>
import ProofViewFileThumbnail from "./proof-view-file-thumbnail";

export default {
  name: "proof-view-sidebar-left",
  components: {ProofViewFileThumbnail},
  props: ["files", "activeFile", "isCompareMode", "isMobile", "disabled"],
  data() {
    return {
      open: false
    };
  },
  mounted() {
    this.$emit('width-changed', this.$el.offsetWidth);
  },
  methods: {
    setActiveFile(file) {
      if (this.disabled) return;
      this.curFile = file;
      this.$emit('set-active-file', file);
    },
    setOpen() {
      // debugger
      if (this.disabled) return;
      this.open = true;
    },
    setClose() {
      // debugger
      if (this.disabled) return;
      this.open = false;
    }
  },
  computed: {
    activeFileID() {
      return _.get(this.activeFile, 'id', 0);
    },
    showHorizontal() {
      return this.isCompareMode || this.isMobile;
    }
  }
}
</script>

<template>
  <div @mouseleave="setClose()" @mouseover="setOpen()" id="review-available-files"
       :class="{'file-sidebar' : !disabled && !showHorizontal, 'horizontal d-flex flex-row' : isCompareMode, 'd-flex flex-row w-100' : isMobile, 'flex-column d-none position-absolute' : !showHorizontal}"
       class="py-3 px-2 bg-ashore-white border-end border-gray-100 d-lg-flex h-100 start-0 top-0 overflow-auto">
    <proof-view-file-thumbnail v-for="f in files"
                               :file="f"
                               :active-file-id="activeFileID"
                               :show-title="open && !isCompareMode"
                               :show-horizontal="showHorizontal"
                               :is-mobile="isMobile"
                               :disabled="disabled"
                               @selected="setActiveFile"
    />
  </div>
</template>

<style scoped>
.horizontal {
  width: 100%;
  height: 73px !important;
}
.file-sidebar{
  z-index: 900;
  transition: all ease-in-out 300ms;
  width: 73px;
}
.file-sidebar:hover{
  width: 400px;
}
</style>
