<script>
import CursorSmoothing from "../../models/CursorSmoothing"
import { DOC_TYPES } from "../../utils/constants";

const { VIDEO, HTML, AUDIO, IMAGE, DOCUMENT, WEB_URL } = DOC_TYPES;

export default {
  components: {},
  props: {
    curAnnotations: {
      required: true,
      type: Array,
    },
    editingAnnotationId: {
      required: true,
    },
    curAnnotation: {
      required: true,
    },
    viewAllComments: {
      required: true,
      type: Boolean,
    },
    curContainerWidth: {
      required: true,
      type: Number,
    },
    curContainerHeight: {
      required: true,
      type: Number,
    },
    pdfPage: {
      required: true,
      type: Number,
    },
    approvers: {
      required: true,
      type: Array,
    },
    users: {
      required: true,
      type: Array,
    },
    curApprover: {
      type: Object,
    },
    curUser: {
      type: Object,
    },
    reviewers: {
      required: true,
      type: Array,
    },
    parentId: {
      required: false,
      type: String
    },
    liveProofActiveUrl: {
      required: false,
      type: String
    },
    liveProofSizeSelected: {
      type: Object,
      required: false,
      default: {
        size: 0
      }
    },
    unFocusedOpacity: {
      required: false,
      type: Number,
      default: 0.5
    },
    rotation: {
      type: Number,
      required: false,
      default: 0
    },
    isLiveProof: {
      type: Boolean,
      required: false,
      default: false
    },
    activeUsers: {
      type: Array,
      required: false,
      default: () => []
    },
    allowViewAllComments: {
      type: Boolean,
      default: true
    },
    curAnnotationType: {}
  },
  data() {
    return {
      cursorSmoothing: null,
      playerCurTime: 0,
      drawCursor: "default",
      realTimeCursorSVG: '<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.59999 0.0834776C1.16667 -0.0898498 0.671748 0.0117366 0.341742 0.341742C0.0117366 0.671748 -0.0898498 1.16667 0.0834776 1.59999L4.75013 13.2666C4.92076 13.6932 5.32584 13.9799 5.78487 13.999C6.24389 14.0181 6.67139 13.766 6.87685 13.3551C8.69974 9.7093 9.76233 8.67322 13.3551 6.87685C13.766 6.67139 14.0181 6.24389 13.999 5.78487C13.9799 5.32584 13.6932 4.92076 13.2666 4.75013L1.59999 0.0834776Z" fill="#CC333F"/></svg>',
      devicePixelRatio: 1,
      userCursorCache:{},
      userCursorCacheBubble:{},
      userDomCache:{},
    };
  },
  updated: function () {
    let self = this;
    this.$nextTick(function () {
      if (self.curAnnotation !== null && self.curAnnotation.type === "arrow")
        self.drawArrow(self.curAnnotation);
      self.curAnnotations
          .filter((a) => a.type === "arrow")
          .forEach(function (a) {
            self.drawArrow(a);
          });

    });
    // window.requestAnimationFrame(()=>{
    //   self.activeUsers.forEach(function (u) {
    //     self.drawUser(u);
    //   });
    // });
  },
  watch: {
    curContainerWidth: function () {
      this.$forceUpdate();
    },
    activeUsers: function(newVal, oldVal) {
      const oldUsers = Object.fromEntries(oldVal.map((u) => [u.targetId, true]));
      const newUsers = Object.fromEntries(newVal.map((u) => [u.targetId, true]));
      const removedUsers = Object.keys(oldUsers).filter((u) => !newUsers[u]);
      removedUsers.forEach((u) => {
        this.removeUser(u);
      });
      this.$nextTick(this.drawUsers);
    },
    curAnnotationType: function () {
      this.$nextTick(this.drawUsers);
    },
  },
  mounted() {
    let self = this;

    this.cursorSmoothing = new CursorSmoothing(this);

    this.$root.$on("cursor_position_update", async (user) => {
      self.cursorSmoothing.updateMousePosition(
          user.targetId,
          user.targetX,
          user.targetY,
          user.timestamp,
          (newX, newY) => {
            user.x = newX;
            user.y = newY;
            self.drawUser(user);
          });
    });
    self.curAnnotations
        .filter((a) => a.type === "arrow")
        .forEach(function (a) {
          self.drawArrow(a);
        });
    this.$root.$on("player:progress:change", function (progressUpdate) {
      self.playerCurTime = progressUpdate;
    });
    self.drawUsers();
    this.devicePixelRatio = this.getDevicePixelRatio();
  },
  computed: {
    curAnnotationId() {
      return _.get(this.curAnnotation, "id", "");
    },
    prefixId: function () {
      return this.parentId ? this.parentId + "-" : "";
    },
    drawings: function () {
      return this.curAnnotations.filter(a => a.type === "draw");
    },
    isDrawing: function () {
      return this.curAnnotationType && this.curAnnotationType.type === "draw";
    }
  },
  methods: {
    shouldShowAnnotation(a) {
      if (!this.viewAllComments && this.curAnnotationId !== a.id) {
        return false;
      }
      if (a.docType === VIDEO) {
        return (
            this.playerCurTime >= a.curTime && this.playerCurTime <= a.curTime + 1
        );
      }
      if (a.docType === HTML || a.docType === AUDIO || a.docType === IMAGE || (a.docType === DOCUMENT && a.pdfPage === this.pdfPage)) return true;
      
      if (a.docType === WEB_URL) {
        if (this.isLiveProofAnnotation(a)) {
          try {
            const proofingUrl = new URL(a.liveWebProofingUrl);
            const activeUrl = new URL(this.liveProofActiveUrl);
            if (proofingUrl.href !== activeUrl.href || a.liveWebProofingResolution !== this.liveProofSizeSelected.size) return false;
          } catch (error) {
            console.error(error);
            return false;
          }
        }
        return true;
      }
      return false;
    },
  validateUserDomCache() {
    Object.entries(this.userDomCache).forEach(([key, canvas]) => {
      if (!canvas || !(canvas instanceof HTMLElement)) return;
      const canvasId = canvas.getAttribute('id');
        if (!canvasId.includes(key)) {
            canvas = document.getElementById(this.prefixId + "user_id_" + key);
            if (canvas) this.$set(this.userDomCache, key, canvas);
        }
    });
  },
    getColor(annotation) {
      let result = null;
      if (annotation.userId > 0) {
        result = this.users.filter((a) => a.id === annotation.userId);
      }
      if (annotation.approverId > 0) {
        result = this.approvers.filter(a => a.id === annotation.approverId);
        if (result.length === 0) {
          result = this.reviewers.filter(a => a.id === annotation.approverId);
        }
      }
      return _.get(result, '[0].color', '#000000');
    },
    getCoordinates(x, y, down) {
      return down ? {x, y} : this.normalizeCoordinates(x, y);
    },
    getDistance(s, e) {
      return (s < e) ? e - s : s - e;
    },
    genStyle(a) {
      if (Number.isNaN(this.curContainerWidth)) {
        return;
      }
      let zoom = this.isLiveProofAnnotation(a) ? 1 : this.curContainerWidth / a.containerWidth;
      let opacity =
          this.$props.editingAnnotationId === a.id ||
          this.$props.editingAnnotationId === 0
              ? 1
              : this.$props.unFocusedOpacity;
      let zindex =
          this.$props.editingAnnotationId === a.id ||
          this.$props.editingAnnotationId === 0
              ? 503
              : 500;

      let coordinates, startCoordinates, endCoordinates, w, h, l, t;

      switch (a.type) {
        case "point":
          coordinates = this.getCoordinates(a.x * zoom, a.y * zoom, a.down);
          return {
            display: this.shouldShowAnnotation(a) ? "" : "none",
            opacity: opacity,
            "z-index": zindex,
            top: coordinates.y - 25 + "px",
            left: coordinates.x - 25 + "px",
            "border-color": this.getColor(a),
          };
        case "rect":
          startCoordinates = this.getCoordinates(a.startX * zoom, a.startY * zoom, a.down);
          endCoordinates = this.getCoordinates(a.endX * zoom, a.endY * zoom, a.down);
          w = this.getDistance(startCoordinates.x, endCoordinates.x);
          h = this.getDistance(startCoordinates.y, endCoordinates.y);
          t = Math.min(startCoordinates.y, endCoordinates.y);
          l = Math.min(startCoordinates.x, endCoordinates.x);
          return {
            display: this.shouldShowAnnotation(a) ? "" : "none",
            opacity: opacity,
            "z-index": zindex,
            top: t + "px",
            left: l + "px",
            width: w + "px",
            height: h + "px",
            "border-color": this.getColor(a),
          };
        case "arrow":
          startCoordinates = this.getCoordinates(a.startX * zoom, a.startY * zoom, a.down);
          endCoordinates = this.getCoordinates(a.endX * zoom, a.endY * zoom, a.down);
          w = this.getDistance(startCoordinates.x, endCoordinates.x);
          h = this.getDistance(startCoordinates.y, endCoordinates.y);
          t = Math.min(startCoordinates.y, endCoordinates.y);
          l = Math.min(startCoordinates.x, endCoordinates.x);
          return {
            display: this.shouldShowAnnotation(a) ? "" : "none",
            opacity: opacity,
            "z-index": zindex,
            top: t + "px",
            left: l + "px",
            width: w + "px",
            height: h + "px",
          };
        default:
          return {};
      }
    },
    getDrawingStyle(a) {
      if (Number.isNaN(this.curContainerWidth)) {
        return;
      }
      let opacity =
          this.$props.editingAnnotationId === a.id ||
          this.$props.editingAnnotationId === 0
              ? 1
              : 0.5;
      let zindex =
          this.$props.editingAnnotationId === a.id ||
          this.$props.editingAnnotationId === 0
              ? 502
              : 499;
      let result = {
        display: this.shouldShowAnnotation(a) ? "" : "none",
        opacity: opacity,
        "z-index": zindex,
        width: this.curContainerWidth + "px",
        cursor: this.drawCursor,
      };
      switch (this.rotation) {
        case 0:
          result["transform"] = "rotate(0deg)";
          result["transform-origin"] = "center center";
          break;
        case 90:
          result["transform"] = "rotate(90deg) translateY(-100%)";
          result["transform-origin"] = "top left";
          break;
        case 180:
          result["transform"] = "rotate(180deg)";
          result["transform-origin"] = "center center";
          break;
        case 270:
          result["transform"] = "translateX(-100%) rotate(-90deg)";
          result["transform-origin"] = "top right";
          break;
      }
      return result;
    },
    drawArrow(a) {
      let color = this.getColor(a);
      if (Number.isNaN(this.curContainerWidth)) {
        return;
      }
      let zoom = this.curContainerWidth / a.containerWidth;
      let element = document.getElementById(this.prefixId + "annotation_id_" + a.id);
      if (element == null) return;
      let ctx = element.getContext("2d");

      const startCoordinates = this.getCoordinates(a.x * zoom, a.y * zoom, a.down);
      const endCoordinates = this.getCoordinates(a.x2 * zoom, a.y2 * zoom, a.down);
      const w = this.getDistance(startCoordinates.x, endCoordinates.x);
      const h = this.getDistance(startCoordinates.y, endCoordinates.y);

      ctx.clearRect(0, 0, w, h);

      let fitting = 1;
      let x1 = startCoordinates.x;
      let y1 = startCoordinates.y;
      let x2 = endCoordinates.x;
      let y2 = endCoordinates.y;
      // let w = a.w * zoom;
      // let h = a.h * zoom;

      let fromx = 0;
      let fromy = 0;
      let tox = 0;
      let toy = 0;

      if (x1 < x2) {
        //left
        if (y1 > y2) {
          //bottom
          fromx = 0;
          fromy = h;
          tox = w;
          toy = 0;
        } else if (y1 < y2) {
          //top
          fromx = 0;
          fromy = 0;
          tox = w;
          toy = h;
        }
      } else if (x1 > x2) {
        //right
        if (y1 > y2) {
          //bottom
          fromx = w;
          fromy = h;
          tox = 0;
          toy = 0;
        } else if (y1 < y2) {
          //top
          fromx = w;
          fromy = 0;
          tox = 0;
          toy = h;
        }
      }
      // console.log(fromx, fromy, tox, toy)

      if (w < 50) {
        element.width = 50;
        tox = 25;
      } else {
        element.width = w;
      }
      if (h < 50) {
        element.height = 50;
        toy = 50;
      } else {
        element.height = h;
      }

      let headlen = 26;

      let angle = Math.atan2(toy - fromy, tox - fromx);
      // console.log(angle)

      //starting path of the arrow from the start square to the end square and drawing the stroke
      ctx.beginPath();
      ctx.moveTo(fromx, fromy);
      ctx.lineTo(tox, toy);
      ctx.strokeStyle = color;
      ctx.lineWidth = 3;
      ctx.stroke();

      //starting a new path from the head of the arrow to one of the sides of the point
      ctx.beginPath();
      ctx.moveTo(tox, toy);
      ctx.lineTo(
          tox - headlen * Math.cos(angle - Math.PI / 7),
          toy - headlen * Math.sin(angle - Math.PI / 7)
      );

      //path from the side point of the arrow, to the other side point
      ctx.lineTo(
          tox - headlen * Math.cos(angle + Math.PI / 7),
          toy - headlen * Math.sin(angle + Math.PI / 7)
      );

      //path from the side point back to the tip of the arrow, and then again to the opposite side point
      ctx.lineTo(tox, toy);
      ctx.lineTo(
          tox - headlen * Math.cos(angle - Math.PI / 7),
          toy - headlen * Math.sin(angle - Math.PI / 7)
      );

      //draws the paths created above
      ctx.strokeStyle = color;
      ctx.lineWidth = 2;
      ctx.stroke();
      ctx.fillStyle = color;
      ctx.fill();
    },
    drawImage(a) {
      let image = document.getElementById(this.prefixId + "image_annotation_id_" + a.id)
      let canvas = document.getElementById(this.prefixId + "annotation_id_" + a.id);
      if (canvas == null) return;
      let ctx = canvas.getContext("2d");
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0, image.width, image.height);
    },
    getUserStyle(u) {
      let zoom = this.isLiveProof ? 1 : this.curContainerWidth / u.containerWidth;
      const coordinates = this.getCoordinates(u.x * zoom, u.y * zoom, u.down);
      return {
        top: coordinates.y + "px",
        left: coordinates.x + "px",
        "border-color": u.color
      };
    },
    changeSvgColor(svgContent, newColor) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(svgContent, 'image/svg+xml');
      const path = doc.querySelector('path');
      path.setAttribute('fill', newColor);
      return new XMLSerializer().serializeToString(doc);
    },
    getDevicePixelRatio() {
      let ctx = document.createElement("canvas").getContext("2d"),
          dpr = window.devicePixelRatio || 1,
          bsr = ctx.webkitBackingStorePixelRatio ||
              ctx.mozBackingStorePixelRatio ||
              ctx.msBackingStorePixelRatio ||
              ctx.oBackingStorePixelRatio ||
              ctx.backingStorePixelRatio || 1;

      return dpr / bsr;
    },
    drawUser(u) {
      if (Number.isNaN(this.curContainerWidth) || this.isDrawing) {
        this.userCursorCache = {};
        this.userCursorCacheBubble = {};
        this.userDomCache = {};
        return;
      }

      if (!this.shouldShowCursor(u)) return;

      let canvasCache = this.userDomCache[u.targetId];
      let canvas = null;

      if(canvasCache === undefined){
        canvas = document.getElementById(this.prefixId + "user_id_" + u.targetId);
        this.validateUserDomCache();

        this.userDomCache[u.targetId] = canvas;
      } else {
        canvas = canvasCache;
      }

      if (canvas == null){
        canvas = document.getElementById(this.prefixId + "user_id_" + u.targetId);
        this.validateUserDomCache();

        this.userDomCache[u.targetId] = canvas;
      }
      if (canvas == null) return;


      // Set canvas size
      canvas.width = 320 * this.devicePixelRatio;
      canvas.height = 96 * this.devicePixelRatio;
      canvas.style.width = 80 + "px";
      canvas.style.height = 24 + "px";
      canvas.getContext("2d").setTransform(this.devicePixelRatio, 0, 0, this.devicePixelRatio, 0, 0);

      let ctx = canvas.getContext("2d");

      let user = [...this.approvers, ...this.reviewers].find(a => `a:${a.id}` === u.targetId);
      if (!user) user = this.users.find(a => `u:${a.id}` === u.targetId);
      if (!user) return;
      const {color, name} = user;

      let cursorImageCache = this.userCursorCache[u.targetId];

      if(cursorImageCache === undefined){
        let img = new Image();
        const modifiedSvgContent = this.changeSvgColor(this.realTimeCursorSVG, color);
        img.src = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(modifiedSvgContent)}`;
        img.onload = function () {
          ctx.drawImage(img, 0, 40, 48, 48);
        };
        this.userCursorCache[u.targetId] = img;
      }else{
        ctx.drawImage(cursorImageCache, 0, 40, 48, 48);
      }

      let cursorImageCacheBubble = this.userCursorCacheBubble[u.targetId];
      if(cursorImageCacheBubble === undefined){
        let img = new Image();
        const modifiedSvgContent = this.changeSvgColor(this.realTimeCursorSVG, color);
        img.src = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(modifiedSvgContent)}`;
        img.onload = function () {
          ctx.drawImage(img, 0, 40, 48, 48);
        };
        this.userCursorCacheBubble[u.targetId] = img;
      }else{
        ctx.drawImage(cursorImageCacheBubble, 0, 40, 48, 48);
      }

      ctx.font = "bold 32px Open Sans";
      if (!name) return;
      let nameVal = name;
      const textOffsetX = 80;
      const textMetrics = ctx.measureText(nameVal);
      let endX = textOffsetX + textMetrics.width;
      const maxWidth = canvas.width / this.devicePixelRatio;
      if (endX > maxWidth) {
        while (endX > maxWidth && nameVal.length > 0) {
          nameVal = nameVal.substring(0, nameVal.length - 1);
          endX = textOffsetX + ctx.measureText(nameVal + "...").width;
        }
        nameVal += "...";
      }

      endX = textOffsetX + ctx.measureText(nameVal).width;

      // Draw rounded background
      const cornerRadius = 24;
      const x1 = 48, x2 = endX, y1 = 0, y2 = 64;
      ctx.beginPath();
      ctx.moveTo(cornerRadius, 0);
      ctx.arcTo(x2, y1, x2, y2, cornerRadius);
      ctx.arcTo(x2, y2, x1, y2, cornerRadius);
      ctx.arcTo(x1, y2, x1, y1, cornerRadius);
      ctx.arcTo(x1, y1, x2, y1, cornerRadius);

      ctx.closePath();

      // Fill the rounded rectangle with a color
      ctx.fillStyle = color;
      ctx.fill();


      ctx.fillStyle = 'white';
      ctx.textAlign = "left";
      ctx.textBaseline = 'top';
      ctx.fillText(nameVal, 64, 20);
    },
    drawUsers() {
      this.activeUsers.forEach(this.drawUser);
    },
    removeUser(id){
      delete this.userCursorCache[id];
      delete this.userCursorCacheBubble[id];
      delete this.userDomCache[id];
      this.cursorSmoothing.deleteUserPointer(id);
    },
    handleMouseDownOnAnnotation: function (e, a) {
      let self = this;
      // v1
      if (document.getElementById(self.prefixId + "annotation_side_bar_" + a.id)) {
        setTimeout(() => {
          this.$scrollTo("#" + self.prefixId + "annotation_side_bar_" + a.id, 500, {
            container: "#" + self.prefixId + "sidebar_right",
          });
        }, 100);
      }
      // v2
      if (document.getElementById(self.prefixId + "comment-" + a.id)) {
        setTimeout(() => {
          this.$scrollTo("#" + self.prefixId + "comment-" + a.id, 500, {
            container: "#" + self.prefixId + "right-bar",
          });
        }, 100);
      }
      this.$emit("onannotationmoved", {event: e, a: a});
    },
    findPos: function (obj) {
      let current_left = 0, current_top = 0;
      if (obj.offsetParent) {
        do {
          current_left += obj.offsetLeft;
          current_top += obj.offsetTop;
        } while (obj = obj.offsetParent);
        return {x: current_left, y: current_top};
      }
      return undefined;
    },
    isEventInsideDraw: function (e, a) {
      let canvas = document.getElementById(this.prefixId + "annotation_id_" + a.id);
      let position = this.findPos(canvas);
      let x = e.pageX - position.x;
      let y = e.pageY - position.y;
      let ctx = canvas.getContext('2d');
      let pixelsWide = 5;
      for (let i = x - pixelsWide; i <= x + pixelsWide; i++) {
        for (let j = y - pixelsWide; j <= y + pixelsWide; j++) {
          let d = ctx.getImageData(i, j, 1, 1);
          if (d && d.data) {
            let p = d.data;
            let isTransparentPixel = p[0] == 0 && p[1] == 0 && p[2] == 0 && p[3] == 0;
            if (!isTransparentPixel) {
              return true;
            }
          }
        }
      }
      return false;
    },
    handleMouseOverDraw: function (e, drawings) {
      let self = this;
      let overDrawing = drawings.filter(d => self.isEventInsideDraw(e, d));
      if (overDrawing.length > 0) {
        this.drawCursor = "pointer";
      } else {
        this.drawCursor = "default";
      }
    },
    handleMouseDownOnDrawing: function (e, drawings) {
      this.$emit("mousedown", e);
    },
    isLiveProofAnnotation(a) {
      return a.liveWebProofingUrl && a.liveWebProofingUrl !== '';
    },
    toDegrees: function (angle) {
      return angle * (Math.PI / 180);
    },
    normalizeCoordinates: function (x, y) {
      const degrees = this.toDegrees(this.rotation || 0);
      let xP = x * Math.cos(degrees) - y * Math.sin(degrees);
      let yP = x * Math.sin(degrees) + y * Math.cos(degrees);
      let [w, h] = (this.rotation === 90 || this.rotation === 270) ? [this.curContainerHeight, this.curContainerWidth] : [this.curContainerWidth, this.curContainerHeight];
      // add padding
      xP += 15;
      yP += 10;
      if (xP < 0) xP = w + xP;
      if (yP < 0) yP = h + yP;
      return {x: xP, y: yP};
    },
    isCurrentUserAnnotation: function (annotation) {
      const { userId, approverId } = annotation;
      const currentApproverId  = _.get(this.curApprover, 'id', -1);
      return  !!userId || approverId === currentApproverId;
      
    },
    shouldShowCursor: function (user) {
      return this.allowViewAllComments || this.curUser || user.userId
    }
    },
};
</script>
<template>
  <div class="annotation-layer" id="annotation-layer">
    <template
        v-for="a in curAnnotations"
        v-if="curAnnotation === null && (allowViewAllComments || curUser|| isCurrentUserAnnotation(a))"
    >
      <div
          v-if="a.type === 'point' || a.type === 'rect'"
          class="annotation"
          :class="{ rectangle: a.type === 'rect' }"
          :style="genStyle(a)"
          :id="prefixId + 'annotation_id_' + a.id"
          v-on:mousedown="handleMouseDownOnAnnotation($event, a)"
      >
        {{ a.index }}
      </div>
      <canvas
          v-if="a.type === 'arrow'"
          class="annotation"
          :class="{ arrow: a.type === 'arrow' }"
          :style="genStyle(a)"
          :id="prefixId + 'annotation_id_' + a.id"
          v-on:mousedown="handleMouseDownOnAnnotation($event, a)"
      >
      </canvas>
      <img v-if="a.type === 'draw'"
           class="drawing"
           :style="getDrawingStyle(a)"
           :id="prefixId + 'annotation_id_' + a.id"
           :src="a.drawingLayerImageUrl"
           crossOrigin=""
           v-on:mousedown="handleMouseDownOnDrawing($event, a)"
      >
    </template>

    <template v-if="curAnnotation !== null">
      <div
          v-if="curAnnotation.type === 'point' || curAnnotation.type === 'rect'"
          class="annotation new-annotation"
          :class="{ rectangle: curAnnotation.type === 'rect' }"
          :style="genStyle(curAnnotation)"
          :id="prefixId + 'annotation_id_' + curAnnotation.id"
      >
        {{ curAnnotations.length + 1 }}
      </div>
      <canvas
          v-if="curAnnotation.type === 'arrow'"
          class="annotation new-annotation"
          :class="{ arrow: curAnnotation.type === 'arrow' }"
          :style="genStyle(curAnnotation)"
          :id="prefixId + 'annotation_id_' + curAnnotation.id"
      >
      </canvas>
      <img v-if="curAnnotation.type === 'draw'"
           class="drawing"
           :style="getDrawingStyle(curAnnotation)"
           :id="prefixId + 'annotation_id_' + curAnnotation.id"
           :src="curAnnotation.drawingLayerImageUrl"
           crossOrigin=""
           v-on:mousedown="handleMouseDownOnDrawing($event, a)"
      />
    </template>

    <canvas
        v-for="u in activeUsers"
        v-if="!isDrawing && shouldShowCursor(u)"
        class="user"
        :style="getUserStyle(u)"
        :id="prefixId + 'user_id_' + u.targetId"
    ></canvas>
  </div>
</template>


<style scoped>
.new-annotation {
  z-index: 1001;
}

.user {
  position: absolute;
  margin: 0;
  padding: 0;
  overflow: visible;
  z-index: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  background-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.annotation:hover {
  cursor: pointer;
}

.annotation {
  position: absolute;
  margin: 0;
  padding: 0;
  overflow: visible;
  z-index: 1000;

  width: 50px;
  height: 50px;
  line-height: 30px;
  border-radius: 25px;
  background-color: #f9f9f9;
  border: 5px solid blue;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rectangle {
  border-radius: 0px;
  background-color: transparent;
}

.arrow {
  background: transparent;
  border: 0px;
  border-radius: 0px;
}

.drawing {
  position: absolute;
  margin: 0;
  padding: 0;
  overflow: visible;
  z-index: 1000;
  background: transparent;
}
</style>

