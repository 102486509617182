<script>
import redactor from "../form/redactor";
import pdf from "vue-pdf";
import audioAndVideoReview from "./audioAndVideoReview";
import annotationsLayer from "./annotationsLayer";
import {scroller} from "vue-scrollto/src/scrollTo";
import html2canvas from 'html2canvas';
import VueDrawingCanvas from "vue-drawing-canvas";
import {Chrome} from 'vue-color';
import ProofViewFreeUserPromo from "./proof-view-free-user-promo";
import { PROOF_VIEW_MODES } from "../../utils/constants";

const _ = require('lodash');

const { PROOF_OWNER_MODE } = PROOF_VIEW_MODES;
const firstScrollTo = scroller();
const secondScrollTo = scroller();
const ViewMode = {
  Review: 1,
  Compare: 2,
}
const FileProcessedEventType = 62;
const MIN_DATE_TIME = "0001-01-01T00:00:00";

export default {
  components: {
    redactor,
    pdf,
    audioAndVideoReview,
    annotationsLayer,
    VueDrawingCanvas,
    'chrome-picker': Chrome,
    ProofViewFreeUserPromo
  },
  props: {
    label: {
      type: String,
      required: true
    },
    mode: {
      type: Number,
      required: true
    },
    accessDenied: {
      type: Boolean
    },
    accessDeniedMessage: {
      type: String
    },
    commentFileApi: {
      type: String,
      required: true
    },
    curUser: {},
    curApprover: {},
    curAccount: {},
    approverFeedback: {},
    approverPublicId: {},
    viewMode: {
      type: Number,
    },
    isReviewMode: {
      type: Boolean,
      default: true
    },
    isCompareMode: {
      type: Boolean,
      default: false
    },
    topNavBarOffsetHeight: {
      type: Number,
      default: 0
    },
    publicId: {},
    curLang: {},
    curLogo: {}
  },
  data() {
    return {
      initFileLoadComplete: false,
      proof: null,
      goToPageInputValue: -1,
      sidebarVisible: true,
      filesVisible: true,
      commentsVisible: true,
      viewAllComments: true,
      curAnnotationType: {
        docTypes: ["document", "video", "audio", "image", "html"],
        type: "point",
        title: this.getLabel('review_screen_labels', 'anno_single_click'),
        image: "/img/icon-doubleclick.svg",
        cursor: "/img/icon-doubleclick.png",
      },
      reviewWindowHeight: null,
      reviewWindowWidth: null,
      sideBarHeight: null,
      curAnnotation: null,
      curFile: undefined,
      editingAnnotationId: 0,
      isMobile: window.innerWidth < 991,
      isSmallMobileView: window.innerWidth < 576,
      isMouseOver: false,
      steps: [
        {
          target: "#review-available-tools",
          label: "review_available_tools",
          params: {
            enableScrolling: false,
          },
          disableOnFileTypes: ['audio']
        },
        {
          target: "#review-available-files",
          label: "review_available_files",
          params: {
            enableScrolling: false,
          },
        },
        {
          target: "#review-your-canvas",
          label: "review_your_canvas",
          params: {
            enableScrolling: false,
            placement: "bottom",
          },
        },
        {
          target: "#approval-button-group",
          label: "approval_button_group",
          params: {
            enableScrolling: false,
          },
          disableOnModes: [2],
        },
      ],
      tourCallbacks: {
        onStop: function () {
          window.$A.ReviewService.MarkTourCompleted();
        },
      },
      isRecording: false,
      isLiveProofView: false,
      liveProofLoaded: false,
      liveProofCommentsEnabled: true,
      liveProofScriptDetected: true,
      liveProofInterval: undefined,
      liveProofSizeSelected: {
        size: 0,
        label: ""
      },
      predefinedSizes: [
        {
          size: window.innerWidth < 576 ? window.innerWidth - 30 : 500,
          label: "web_x_small"
        },
        {
          size: 576,
          label: "web_small"
        },
        {
          size: 768,
          label: "web_medium"
        },
        {
          size: 992,
          label: "web_large"
        },
        {
          size: 1200,
          label: "web_xl"
        },
        {
          size: 1400,
          label: "web_xxl"
        },
        {
          size: 1920,
          label: "web_xxxl"
        }
      ],
      liveProofActiveUrl: null,
      liveProofViewHeight: 0,
      editingCommentId: 0,
      //draw annotation tool
      x: 0,
      y: 0,
      drawToolBackgroundColor: 'rgba(255,0,0,0)',
      drawToolLineWidth: 5,
      drawToolLineWidthOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20],
      drawToolImage: '',
      drawToolBackgroundImage: null,
      drawToolEraser: false,
      drawToolDisabled: false,
      colorPickerColor: "#D44D68",
      colorPickerVisible: false,
      drawToolVideoHeight: 0,
      isContextImageEnabled: true,
      useAnnotationLayerAsContextImage: true,
      annotationUnFocusedOpacity: 0.5,
      colorPerUserId: {},
      tourLabelKeys: [
        {button: 'buttonSkip', label: "skip_tour_btn"},
        {button: "buttonPrevious", label: "previous_btn"},
        {button: "buttonNext", label: "next_btn"},
        {button: "buttonStop", label: "finish_btn"},
      ],
      proofViewVersion: 'minimal',
      PROOF_OWNER_MODE
    };
  },
  async created() {

  },
  async mounted() {
    let self = this;

    let existingMessageHandler = window.onmessage

    window.onmessage = function (e) {
      if (e && e.data) {
        try {
          let payload = JSON.parse(e.data);
          if (payload.key && payload.key === 'ashore' && payload.iframe_url && payload.id === self.editingUniqueAnnotationId) {
            self.liveProofActiveUrl = new URL(payload.iframe_url).href;
            self.liveProofViewHeight = payload.iframe_height ? payload.iframe_height + 50 : self.liveProofViewHeight;
          }
        } catch (e) {
          // error
        }
      }
      if (existingMessageHandler) {
        existingMessageHandler(e);
      }
    };

    this.$root.$on("player:progress:change", function (progressUpdate) {
      if (self.curFile)
        self.curFile.player.curPlayerTime = progressUpdate;
    });
    this.disableContextMenu();
    this.handleKeyDown();

    window.onresize = this.refreshIsSmallMobile();

  },
  methods:
      {
        InitFileLoadCompleted() {
          let c = this;
          this.initFileLoadComplete = true;
          if (this.$route.query.pfid !== undefined) {
            let paid = this.$route.query.paid;

            if (paid > 0) {
              let selectedAnnotation = c.curFile.proofFileAnnotations.find(
                  (a) => a.id == paid
              );
              // console.log(selectedAnnotation)
              if (selectedAnnotation) {
                c.viewAnnotation({
                  annotation: selectedAnnotation,
                  scroll: this.$route.query.pacid === undefined
                });
              }

              if (this.$route.query.pacid !== undefined) {
                let pacid = this.$route.query.pacid;
                if (pacid > 0) {
                  const proofFileAnnotationComments = _.get(selectedAnnotation, 'proofFileAnnotationComments', [])
                  let selectedComment = proofFileAnnotationComments.find(
                      (c) => c.id == pacid
                  );
                  if (selectedComment) {
                    setTimeout(function () {
                      c.$scrollTo("#" + c.label + "-annotation_comment_item_" + pacid, 500, {
                        container: ".review-sidebar-content",
                        offset: 0,
                        cancelable: false,
                        onDone: function (element) {
                          let e = document.getElementById(
                              c.label + "-annotation_comment_item_" + pacid
                          );
                          e.classList.add("highlight-comment");
                        },
                      });
                    }, 300);
                  }
                }
              }
            }
          }
          if (
              this.curUser == null &&
              this.curApprover != null &&
              !this.curApprover.completedProductTour
          ) {
            const reviewScreenTour = _.get(this.$tours, 'review-screen');
            if (reviewScreenTour) reviewScreenTour.start();
          }
        },
        viewProofVersion: async function (proofVersionId, proof) {
          if (this.proof &&
              this.proof.currentVersion &&
              this.proof.currentVersion.id &&
              proofVersionId === this.proof.currentVersion.id
          ) {
            return;
          }
          if (proofVersionId === undefined) {
            proofVersionId = 0;
          }

          if(proof) return this.SetCurrentProof(proof, true);

          if (this.curUser !== null) {
            this.SetCurrentProof(await this.$A.ProofService.GetFullProof(
                this.proof.id,
                proofVersionId
            ), true)
          }
          if (this.curApprover !== null) {
            this.SetCurrentProof(await this.$A.ReviewService.GetProofToReview(
                proofVersionId
            ), true);
          }
        },
        SetCurrentProof(proof, loadFile) {
          let self = this;
          if (loadFile === undefined)
            loadFile = false

          if (proof !== undefined &&
              proof !== null &&
              proof.message !== undefined &&
              proof.message === "Token Expired"
          ) {
            this.$A.AlertUser(
                this.$A.ReviewService.Lang("link-expired"),
                "warning",
                20000
            );
            return;
          }
          if (proof && proof.id && !(proof.id > 0)) {
            return;
          }

          this.editingCommentId = 0;
          this.editingAnnotationId = 0;
          this.curFile = undefined;
          this.curAnnotation = null;

          let updateCdnLinks = function (cf) {
            if (
                cf.thumbnailFileKey != null &&
                cf.thumbnailFileKey.indexOf("cdn.ashoreapp.com") === -1 &&
                cf.convertedFileKey.length > 3
            ) {
              cf.thumbnailFileKey =
                  "https://cdn.ashoreapp.com/" + cf.thumbnailFileKey;
            }
            if (
                cf.convertedFileKey != null &&
                cf.convertedFileKey.indexOf("cdn.ashoreapp.com") === -1 &&
                cf.convertedFileKey.length > 3
            ) {
              cf.convertedFileKey =
                  "https://cdn.ashoreapp.com/" + cf.convertedFileKey;
            }
            if (
                cf.originalFileKey != null &&
                cf.originalFileKey.indexOf("cdn.ashoreapp.com") === -1 &&
                cf.originalFileKey.length > 3
            ) {
              cf.originalFileKey = "https://cdn.ashoreapp.com/" + cf.originalFileKey;
            }

            if (cf.convertedFileKey === "") {//html doesn't set the conversion key right fix server side
              cf.convertedFileKey = cf.originalFileKey;
            }
          };

          const fileConversionProofEvents = proof.proofEvents.filter((event) => event.eventType === FileProcessedEventType);

          const markFileAsConverted = (versionFile) => {
            versionFile.curTimer = "done";
            versionFile.converting = false;
          }

          const updateFilesAfterConversion = (versionFile) => {
            const convertedFile = fileConversionProofEvents.find((each) => each.appEventObject.file.id === versionFile.id);

            if (convertedFile) {
              markFileAsConverted(versionFile);
              versionFile.convertedFileKey = _.get(convertedFile, 'appEventObject.file.convertedFileKey', '');
              versionFile.conversionJobCompletedAt = _.get(convertedFile, 'appEventObject.file.conversionJobCompletedAt', null);
            } else if (versionFile.conversionJobCompletedAt !== null && versionFile.conversionJobCompletedAt !== MIN_DATE_TIME) {
              // Handle existing file's conversion status, as they don't have FileProcessedEventType
              markFileAsConverted(versionFile);
            } else if (versionFile.conversionJobQueuedAt !== null && versionFile.conversionJobQueuedAt === MIN_DATE_TIME) {
              // Handle case where conversion job was queued at min date time
              versionFile.converting = false;
            } else {
              let t = self.$A.TimeSinceDateTime(versionFile.conversionJobQueuedAt);
              versionFile.curTimer = `${t.minutes}:${t.seconds}`;
              versionFile.conversionTimerMin = t.minutes;
              versionFile.converting = true;
            }
            updateCdnLinks(versionFile);
          };

          let curProofVersionFiles = proof.currentVersion.versionFiles
              ? proof.currentVersion.versionFiles : [];

          curProofVersionFiles.forEach(versionFile => {
            updateCdnLinks(versionFile);
            versionFile.startedLoading = 0;
            versionFile.zoom = 1;
            versionFile.zoomMode = 0;//0 is fill Height 1 is fill width
            versionFile.height = 0;
            versionFile.zoomHeight = 0;
            versionFile.rotation = 0;
            versionFile.width = 0;
            versionFile.zoomWidth = 0;
            versionFile.player = {
              curPlayerTime: 0
            };
            versionFile.pdf = {
              totalPages: 0,
              curPage: 0,
              pageToView: 1
            };
            versionFile.element = null;
            versionFile.loaded = versionFile.loaded || false;
            versionFile.src = (!versionFile.conversionJobQueuedAt) ? versionFile.originalFileKey : versionFile.convertedFileKey;
            versionFile.conversionTimerMin = 0;
            versionFile.curTimer = '';
            updateFilesAfterConversion(versionFile);
          })

          //set active proof
          this.proof = proof;

          this.$emit("proofLoaded", proof);

          //set colors
          if (
              this.proof &&
              this.proof.currentVersion &&
              this.proof.currentVersion.currentStage &&
              this.proof.currentVersion.currentStage.approvers
          ) {
            this.proof.currentVersion.currentStage.approvers.forEach(function (a) {
              if (a.color === undefined) {
                a.color = self.getColor(a.id);
              }

              if (self.curApprover !== null && self.curApprover.id === a.id) {
                self.curApprover.color = a.color;
              }
            });
            this.proof.currentVersion.currentStage.reviewers.forEach(function (a) {
              if (a.color === undefined) {
                a.color = self.getColor(a.id);
              }

              if (self.curApprover !== null && self.curApprover.id === a.id) {
                self.curApprover.color = a.color;
              }
            });
          }

          if (
              this.proof &&
              this.proof.currentVersion &&
              this.proof.currentVersion.sender &&
              this.proof.currentVersion.sender.color
          ) {
            this.proof.currentVersion.sender.color = self.getColor(this.proof.currentVersion.sender.id);
          }

          if (this.curUser !== null && this.curUser.color === undefined) {
            this.curUser.color = self.getColor(this.curUser.id);
          }

          if (this.curApprover !== null && this.curApprover.color === undefined) {
            // this is only used when the approver has not been added to the list yet
            this.curApprover.color = self.curApprover.id;
          }

          if (this.proof !== null && this.proof.engagedUsers) {
            let curUserInListYet = false;
            this.proof.engagedUsers.forEach(function (a) {
              if (self.curUser !== null ? self.curUser.id === a.id : false) {
                curUserInListYet = true;
              }
              if (a.color === undefined) {
                a.color = self.getColor(a.id);
              }
            });

            if (!curUserInListYet && this.curUser !== null) {
              this.proof.engagedUsers.push({
                id: self.curUser.id,
                name: self.curUser.name,
                initials: self.curUser.name.match(/\b\w/g).join(""),
                color: self.curUser.color,
              });
            }
          }

          //save current proof version id
          let defaultFileIdToSelect = this.$A.LoadDataCache(
              "proof_version_id:" + this.proof.currentVersion.id + "_fileId"
          );

          //load id from link
          if (this.$route.query.pfid &&
              this.$route.query.pfid > 0 &&
              this.proof.currentVersion.versionFiles.find(
                  (f) => f.id === this.$route.query.pfid
              ) !== undefined
          ) {
            defaultFileIdToSelect = this.$route.query.pfid;
          }

          if (loadFile) {
            let a = this.proof.currentVersion.versionFiles
                .find(i => i.id === defaultFileIdToSelect);
            if (a) {
              this.reviewFile(
                  a
              );
            } else if (this.proof.currentVersion.versionFiles.length > 0) {
              this.reviewFile(
                  this.proof.currentVersion.versionFiles[0]
              );
            }
          }
        },
        GoToNextProofFile() {
          if (this.proof && this.proof.currentVersion) {
            let curIndex = this.proof.currentVersion.versionFiles
                .indexOf(this.curFile)
            curIndex++
            if (this.proof.currentVersion.versionFiles[curIndex]) {
              this.reviewFile(this.proof.currentVersion.versionFiles[curIndex]);
            }
          }
        },
        GoToPreviousProofFile() {
          if (this.proof && this.proof.currentVersion) {
            let curIndex = this.proof.currentVersion.versionFiles
                .indexOf(this.curFile)
            curIndex--;
            if (this.proof.currentVersion.versionFiles[curIndex]) {
              this.reviewFile(this.proof.currentVersion.versionFiles[curIndex]);
            }
          }
        },
        ShowGoToPage() {
          if (this.goToPageInputValue === "") {
            this.goToPageInputValue = -1;
            return;
          }
          this.goToPageInputValue = "";
          let s = this;
          setTimeout(function () {
            if (s.$refs.goToPageInput) {
              s.$refs.goToPageInput.focus();
            }
          }, 100);
        },
        GoToPage() {
          let newPageNumber = parseInt(this.goToPageInputValue);
          if (newPageNumber > 0 && newPageNumber <= this.curFile.pdf.totalPages) {
            this.curFile.pdf.pageToView = newPageNumber;
            let d = document.getElementById(`${this.label}-review-container`);
            if (d.length > 0) {
              d[0].scrollTo(0, 0);
            }
          }
          this.goToPageInputValue = -1;
        },
        GoToNextPage() {
          this.resetDrawing();
          if ((this.curFile.pdf.pageToView + 1) <= this.curFile.pdf.totalPages) {
            this.curFile.pdf.pageToView = this.curFile.pdf.pageToView + 1;
          }
          let d = document.getElementById(`${this.label}-review-container`);
          if (d.length > 0) {
            d[0].scrollTo(0, 0);
          }
        },
        GoToPreviousPage() {
          this.resetDrawing();
          (this.curFile.pdf.pageToView > 1) ? this.curFile.pdf.pageToView-- : true;
          let d = document.getElementById(`${this.label}-review-container`);
          if (d.length > 0) {
            d[0].scrollTo(0, 0);
          }
        },
        updateZoom: function (zoomLevel) {
          this.calcMobileStatus();
          this.reviewWindowHeight = this.calcReviewWindowHeight();
          this.reviewWindowWidth = this.calcReviewWindowWidth();
          this.sideBarHeight = this.calcSideBarHeight();
          this.curFile.zoom = zoomLevel;

          let containerMaxWidth = this.reviewWindowWidth - 30; // subtracting padding amt
          let containerMaxHeight = this.reviewWindowHeight - 20; // subtracting padding amt
          let zoomMode = (window.innerWidth <= 992) ? 'fill_width' : (this.curFile.zoomMode === 0) ? 'fill_height' : 'fill_width'

          if (
              this.curFile.docTypeGroup === "image" ||
              this.curFile.docTypeGroup === "html" ||
              this.curFile.docTypeGroup === "document" ||
              this.curFile.docTypeGroup === "video"
          ) {
            if (!this.curFile.width && !this.curFile.height && this.curFile.docTypeGroup === "video") {
              this.curFile.width = containerMaxWidth;
              this.curFile.height = containerMaxHeight;
            }
            if (zoomMode === 'fill_width') {
              this.curFile.zoomWidth = containerMaxWidth * zoomLevel;
              this.curFile.zoomHeight = (this.curFile.height * this.curFile.zoomWidth) / this.curFile.width;
            }
            if (zoomMode === 'fill_height') {
              let rs = containerMaxWidth / containerMaxHeight;
              let ri = this.curFile.width / this.curFile.height;

              let s =
                  rs > ri
                      ? [
                        this.curFile.width *
                        (containerMaxHeight / this.curFile.height) *
                        zoomLevel,
                        containerMaxHeight * zoomLevel,
                      ]
                      : [
                        containerMaxWidth * zoomLevel,
                        this.curFile.height *
                        (containerMaxWidth / this.curFile.width) *
                        zoomLevel,
                      ];

              const shouldUpdateDimensions = this.curFile.width > containerMaxWidth || this.curFile.height > containerMaxHeight;

              this.curFile.zoomWidth =  shouldUpdateDimensions ? s[0] : this.curFile.width * zoomLevel;
              this.curFile.zoomHeight = shouldUpdateDimensions ? s[1] : this.curFile.height * zoomLevel;
            }
          } else {
            this.curFile.zoomWidth = containerMaxWidth * zoomLevel;
            if (this.curFile.docTypeGroup !== "image" &&
                this.curFile.docTypeGroup !== "html") {
              this.curFile.zoomHeight = containerMaxHeight * zoomLevel;
            }
          }

          if (this.curAnnotationType.type === 'draw') {
            this.resetDrawing();
          }
        },
        rotate: function () {
          this.resetDrawing();

          this.curFile.rotation += 90;
          if (this.curFile.rotation > 270) this.curFile.rotation = 0;

          let d = document.getElementById(`${this.label}-review-container`);
          if (d.length > 0) {
            d[0].scrollTo(0, 0);
          }
          // [this.curFile.zoomWidth, this.curFile.zoomHeight] = [this.curFile.zoomHeight, this.curFile.zoomWidth];
          // [this.curFile.width, this.curFile.height] = [this.curFile.height, this.curFile.width];
        },
        handleFileLoaded: function (event, file) {
          let self = this;
          this.isLiveProofView = (file.isLiveWebProofing) ? file.isLiveWebProofing : false;
          if (!this.isLiveProofView) {
            this.liveProofActiveUrl = null;
          }

          if (this.shouldDisplayAsImage(file)) {
            let s = event.target === null ? event.path[0] : event.target;
            file.height = s.naturalHeight;
            file.width = s.naturalWidth;
          }

          //let the pdf load fully and then get the size

          if (file.docTypeGroup === "document" && file.width === 0 && file.height === 0 && !this.shouldDisplayPdfAsImage(file)) {
            file.z_index = -100;
            clearTimeout(window.loadingPDF);
            window.loadingPDF = setTimeout(function () {
              if (self.$refs["pdfItem_id_" + self.curFile.id]) {
                let pdfInstance = self.$refs["pdfItem_id_" + self.curFile.id][0];
                let canvas = pdfInstance.$refs.canvas;
                // console.log(canvas.height, canvas.width)
                file.height = canvas.height;
                file.width = canvas.width;
                file.z_index = 1;
              }
            }, 100);
          }

          // console.log("file loaded size", file.width, file.height)

          if (!file.isLiveWebProofing && (file.docTypeGroup === 'html' || file.docTypeGroup === "document" || file.docTypeGroup === "image") &&
              file.width === 0 && file.height === 0) {
            clearTimeout(window.checkingNextFrameLoadFile);
            window.checkingNextFrameLoadFile = setTimeout(function () {
              // console.log("Checking Next Frame To See If File Has Updated Its size")
              self.handleFileLoaded(event, file)
            }, 100);
            return;
          }

          file.loaded = true;
          self.$scrollTo("body");
          self.updateZoom(1);
          self.triggerHeightUpdate();
          if (!this.initFileLoadComplete) {
            this.InitFileLoadCompleted();
          }
        },
        triggerHeightUpdate: function () {
          if (this.curFile && this.curAnnotationType.type === 'draw' && this.curFile.docTypeGroup === 'video') {
            let videoRefId = `audioAndVideoReview_id_${this.curFile.id}`;
            let self = this;
            this.$nextTick(() => {
              let videoRef = self.$refs[videoRefId];
              if (videoRef && videoRef.length > 0 && videoRef[0].$refs.videoHtml) {
                self.drawToolVideoHeight = videoRef[0].$refs.videoHtml.getBoundingClientRect().height;
              }
            })
          }
        },
        handleNumPagesLoaded: function (numPages, file) {
          file.pdf.totalPages = numPages;
        },
        calcReviewWindowHeight: function () {
          if (this.isMobile) {
            return window.innerHeight;
          }
          if (this.$refs.reviewSecondaryNav) {
            return (window.innerHeight - 1 - (this.topNavBarOffsetHeight +
                this.$refs.reviewSecondaryNav.offsetHeight))
          } else {
            return 500
          }
        },
        calcReviewWindowWidth: function () {
          let isCompareMode = this.viewMode === ViewMode.Compare;
          if (this.isMobile || isCompareMode) {
            return isCompareMode ? window.innerWidth / 2 : window.innerWidth;
          }
          if (this.$refs.sidebar_right) {
            let sidebarWidth = this.sidebarVisible ? this.$refs.sidebar_right.$el.offsetWidth : 0;
            return (window.innerWidth - sidebarWidth);
          } else {
            return window.innerWidth * 0.75;
          }
        },
        calcSideBarHeight: function () {
          if (this.isMobile) {
            return 0;
          }
          return (this.reviewWindowHeight - 52);
        },
        calcMobileStatus: function () {
          if (window.innerWidth < 991) { //mobile
            this.isMobile = true;
            this.curAnnotationType = {
              docTypes: ["document", "video", "image", "html"],
              type: "point",
              title: this.getLabel('review_screen_labels', 'anno_single_click'),
              image: "/img/icon-doubleclick.svg",
              cursor: "/img/icon-doubleclick.png",
            };
          } else { // desktop
            this.isMobile = false;
          }
        },
        getEditingAnnotation: function () {
          if (this.editingAnnotationId !== 0) {
            let editing = this.curAnnotations.filter(
                (a) => a.id === this.editingAnnotationId
            );
            if (editing && editing.length > 0) {
              return editing[0];
            }
          }
          return null;
        },
        toggleZoomMode: function () {
          if (!this.curFile)
            return;
          this.curFile.zoomMode = (this.curFile.zoomMode === 1) ? 0 : 1;
          this.updateZoom(1)
        },
        setEditingAnnotation: async function (a, toggle) {
          a.isExpanded = true;
          if (this.editingAnnotationId !== 0) {
            let editingA = this.getEditingAnnotation();
            if (
                editingA &&
                Array.isArray(editingA.proofFileAnnotationComments) &&
                editingA.proofFileAnnotationComments.length === 0
            ) {
              await this.deleteAnnotation(editingA);
            }
          }
          if (this.editingAnnotationId === a.id && toggle) {
            this.editingAnnotationId = 0;
            a.isExpanded = false;
          } else {
            this.editingAnnotationId = a.id;
          }
        },
        handleAnnotationMoveEvent: function ($event) {
          let a = $event.a;
          $event = $event.event;
          if (this.isRecording) {
            return;
          }
          this.setEditingAnnotation(a);
          let self = this;
          if (!this.$refs.review_window) {
            return;
          }

          if (
              (this.curUser === null || a.userId !== this.curUser.id) &&
              (this.curApprover === null || a.approverId !== this.curApprover.id)
          ) {
            return; // not the owner so can't move
          }
          a.containerWidth = this.curFile.zoomWidth;

          let bounding = this.$refs.review_window.getBoundingClientRect();

          // console.log($event.target);
          let calcNewCords = function (e, a) {
            let px = e.x - bounding.left;
            let py = e.y - bounding.top;

            if (a.type === "rect" || a.type === "arrow") {
              a.x2 += px - a.x;
              a.y2 += py - a.y;
              a.x = px;
              a.y = py;

              a.startX = Math.min(a.x, a.x2);
              a.startY = Math.min(a.y, a.y2);
              a.endX = Math.max(a.x, a.x2);
              a.endY = Math.max(a.y, a.y2);
              a.w = a.endX - a.startX;
              a.h = a.endY - a.startY;
            } else {
              a.x = px;
              a.y = py;
            }

          };

          document.onmouseup = function (e) {
            // console.log("annotation moved",a,e);
            document.onmouseup = null;
            if (self.$refs.review_window !== undefined)
              self.$refs.review_window.onmousemove = null;
            // self.editingAnnotationId = 0;
            if (a && a.id && a.id > 0) {
              self.$A.AnnotationService.UpdateAnnotation(a).then(
                  (annotationUpdated) => {
                    if (annotationUpdated !== null && annotationUpdated.id > 0) {
                      a = annotationUpdated;
                    }
                    // console.log("annotation move saved",a)
                  }
              );
            }
          };

          calcNewCords($event, a);
          this.$refs.review_window.onmousemove = (e) => {
            calcNewCords(e, a);
          };
        },
        updateAnnotationResolvedStatus: function (annotation) {
          let s = this;
          setTimeout(function () {
            s.$A.AnnotationService.UpdateAnnotationResolvedStatus(
                annotation.id,
                annotation.resolved
            );
          }, 1);
        },
        getAnnotationLayerImage: async function () {
          let target = this.$refs.annotationLayer.$el;
          let bounding = this.isLiveProofView ? this.$refs.liveProofIframe[0].getBoundingClientRect() : this.$refs.review_window.getBoundingClientRect();
          let maxHeight = Math.max(this.drawToolHeight, bounding.bottom);
          let maxWidth = this.isLiveProofView ? this.liveProofViewWidth : this.curFile.zoomWidth
          let options = {
            x: 0,
            y: 0,
            width: maxWidth,
            height: maxHeight,
          }

          let canvas = await html2canvas(target, options);
          return canvas.toDataURL();
        },
        getContextImage: async function (a) {
          if (!this.isContextImageEnabled) {
            return "";
          }

          if (this.useAnnotationLayerAsContextImage) {
            return this.getAnnotationLayerImage();
          }

          let bounding = this.isLiveProofView ? this.$refs.liveProofIframe[0].getBoundingClientRect() : this.$refs.review_window.getBoundingClientRect();
          let target = document.getElementById(this.label + "-review-container");

          let imageStartX = a.startX ? a.startX : a.x;
          let imageStartY = a.startY ? a.startY : a.y;
          let imageEndX = a.endX ? a.endX : a.x;
          let imageEndY = a.endY ? a.endY : a.y;

          // add padding of parent container
          imageStartX += 15;
          imageStartY += 10;
          imageEndX += 15;
          imageEndY += 10;

          let maxHeight = Math.max(this.drawToolHeight, bounding.bottom, imageEndY);
          let maxWidth = this.curFile.zoomWidth + 15;

          let width = imageEndX - imageStartX;
          let height = imageEndY - imageStartY;

          let largestDimension = Math.max(width, height, 180);
          let imageDimension = largestDimension + 20; // includes padding at each side

          let paddingX = Math.ceil((imageDimension - width) / 2);
          let paddingY = Math.ceil((imageDimension - height) / 2);

          imageStartX = Math.max(15, imageStartX - paddingX);
          imageStartY = Math.max(10, imageStartY - paddingY);
          imageEndX = Math.min(maxWidth, imageEndX + paddingX);
          imageEndY = Math.min(maxHeight, imageEndY + paddingY);

          width = imageEndX - imageStartX;
          height = imageEndY - imageStartY;

          // if the image width is less that desired width, use additional space in x
          if (width < imageDimension) {
            // lets use more space to the left
            imageStartX = Math.max(15, imageStartX - imageDimension + width);
            // lets use more space to the left
            imageEndX = Math.min(maxWidth, imageEndX + imageDimension - width);
          }

          // if the image height is less that desired height, use additional space in y
          if (height < imageDimension) {
            // lets use more space to the top
            imageStartY = Math.max(10, imageStartY - imageDimension + height);
            // lets use more space to the bottom
            imageEndY = Math.min(maxHeight, imageEndY + imageDimension - height);
          }

          width = imageEndX - imageStartX;
          height = imageEndY - imageStartY;

          let options = {
            x: imageStartX,
            y: imageStartY,
            width: width,
            height: height,
            // proxy: 'https://ashoreapp.com:3000'
          }

          let canvas = await html2canvas(target, options);
          return canvas.toDataURL();
        },
        prepNewAnnotation: function () {
          if (this.curAnnotationType.type === "na") {
            return;
          }

          let a = {
            id: this.$A.GetUUID(),
            accountId: this.curAccount.id,
            proofFileId: this.curFile.id,
            proofVersionId: this.proof.currentProofVersionId,
            proofId: this.curFile.proofId,
            type: this.curAnnotationType.type,
            docType: this.curFile.docTypeGroup,
            userId: this.curUser && this.curUser.id ? this.curUser.id : 0,
            approverId:
                this.curApprover && this.curApprover.id ? this.curApprover.id : 0,
            ownerName:
                this.curApprover && this.curApprover.name
                    ? this.curApprover.name
                    : this.curUser && this.curUser.name
                        ? this.curUser.name
                        : "",
            createdAt: new Date(),
            resolved: false,
            proofFileAnnotationComments: [],
            zoom: this.curFile.zoom,
          };
          a.ownerInitials = a.ownerName.match(/\b\w/g).join("");
          a.containerWidth = this.curFile.zoomWidth;
          // special doc additional data
          if (a.docType === "document") {
            a.pdfPage = this.curFile.pdf.pageToView;
          }
          if (a.docType === "audio") {
            a.curTime = this.curFile.player.curPlayerTime;
            a.type = "timeline";
          }
          if (a.docType === "video") {
            a.curTime = this.curFile.player.curPlayerTime;
          }
          if (this.isLiveProofView) {
            a.liveWebProofingUrl = this.liveProofActiveUrl
            a.liveWebProofingResolution = this.liveProofSizeSelected.size
          }

          return a;
        },
        appendNewAnnotation: async function (a, isExistingAnnotation) {
          this.sidebarVisible = true;

          let self = this;
          self.curAnnotation = a;

          if (!isExistingAnnotation) {
            await this.setEditingAnnotation(a);
            this.curFile.proofFileAnnotations.push(self.curAnnotation);
          }

          self.curAnnotation = null;
          clearTimeout(window.scrollFunc);
          window.scrollFunc = setTimeout(function () {
            if (typeof self.scrollAnimationTask === "function") {
              self.scrollAnimationTask();
            }
            if (typeof self.scrollAnimationRootTask === "function") {
              self.scrollAnimationRootTask();
            }
            self.scrollAnimationTask = firstScrollTo(
                `#${self.label}-annotation_side_bar_${a.id}`,
                500,
                {cancelable: true, container: `#${self.label}-sidebar_right`}
            );
            self.scrollAnimationRootTask = secondScrollTo(
                `#${self.label}-annotation_side_bar_${a.id}`,
                500,
                {cancelable: true, offset: -30}
            );
          }, 100);
        },
        handleNewAudioCommentCreated: async function ($event) {
          let a = this.prepNewAnnotation();
          a.x = $event.offsetX;
          a.y = 0;

          await this.appendNewAnnotation(a);
        },
        handleDrawingWindowMouseDown: function ($event) {
          let drawingToolRef = this.$refs.drawing_tool;
          if (drawingToolRef && this.curAnnotationType.type === 'draw') {
            let videoToolRef = this.$refs['audioAndVideoReview_id_' + this.curFile.id];
            let isVideo = this.curFile.docTypeGroup === 'video';
            if (isVideo && videoToolRef && videoToolRef.length > 0) {
              videoToolRef[0].pause();
            }
            if (Number.isInteger(this.editingAnnotationId)) {
              this.editingAnnotationId = 0;
              this.curAnnotation = null;
              this.handleWindowMouseDown($event);
            } else {
              let editingA = this.getEditingAnnotation();
              if (editingA) {
                if (!editingA.x || !editingA.y) {
                  let bounding = this.isLiveProofView ? this.$refs.liveProofIframe[0].getBoundingClientRect() : this.$refs.review_window.getBoundingClientRect();
                  editingA.x = $event.x - bounding.left;
                  editingA.y = $event.y - bounding.top;
                }
                this.handleAnnotationMove(editingA, true);
              } else {
                this.handleWindowMouseDown($event);
              }
            }
          }
        },
        handleWindowMouseDown: function ($event) {
          if (!this.$refs.review_window || this.isRecording || (this.isLiveProofView && !this.liveProofCommentsEnabled)) {
            return;
          }
          // pause when new comment added

          let s = this.$refs['audioAndVideoReview_id_' + this.curFile.id];
          if (s !== undefined && s[0] !== undefined) {
            s[0].pause();
          }

          let bounding = this.isLiveProofView ? this.$refs.liveProofIframe[0].getBoundingClientRect() : this.$refs.review_window.getBoundingClientRect();

          let a = this.prepNewAnnotation();
          a.x = $event.x - bounding.left;
          a.y = $event.y - bounding.top;

          this.handleAnnotationMove(a);
        },
        normalizeCoordinates: function (x, y) {
          const degrees = this.toDegrees(this.curFile.rotation || 0);
          // remove padding
          x -= 15;
          y -= 10;
          let xP = x * Math.cos(degrees) + y * Math.sin(degrees);
          let yP = -1 * x * Math.sin(degrees) + y * Math.cos(degrees);
          return [xP, yP];
        },
        toDegrees: function (angle) {
          return angle * (Math.PI / 180);
        },
        handleAnnotationMove: function (a, skipAppend) {
          let self = this;
          a.down = true;

          if (a.type === "rect" || a.type === "arrow" || a.type === 'draw') {
            this.$refs.review_window.onmousemove = (e) => {
              let bounding = this.isLiveProofView ? this.$refs.liveProofIframe[0].getBoundingClientRect() : this.$refs.review_window.getBoundingClientRect();
              let px = e.x - bounding.left;
              let py = e.y - bounding.top;
              a.x2 = px;
              a.y2 = py;

              if (a.type === 'draw') {
                a.startX = Math.min(a.x, a.x2, a.startX ? a.startX : a.x);
                a.startY = Math.min(a.y, a.y2, a.startY ? a.startY : a.y);
                a.endX = Math.max(a.x, a.x2, a.endX ? a.endX : a.x);
                a.endY = Math.max(a.y, a.y2, a.endY ? a.endY : a.y);
              } else {
                a.startX = Math.min(a.x, a.x2);
                a.startY = Math.min(a.y, a.y2);
                a.endX = Math.max(a.x, a.x2);
                a.endY = Math.max(a.y, a.y2);
              }

              a.w = a.endX - a.startX;
              a.h = a.endY - a.startY;

              self.curAnnotation = a;
            };
          }

          document.onmouseup = (e) => {
            document.onmouseup = null;
            this.$refs.review_window.onmousemove = null;
            self.annotationUnFocusedOpacity = 0;
            a.down = false;
            if (a.x && a.y) {
              [a.x, a.y] = self.normalizeCoordinates(a.x, a.y);
            }
            if (a.x2 && a.y2) {
              [a.x2, a.y2] = self.normalizeCoordinates(a.x2, a.y2);
            }
            if (a.type === "rect" || a.type === "arrow" || a.type === 'draw') {
              [a.startX, a.startY] = self.normalizeCoordinates(a.startX, a.startY);
              [a.endX, a.endY] = self.normalizeCoordinates(a.endX, a.endY);
            }

            self.appendNewAnnotation(a, skipAppend).then(() => {
              self.getContextImage(a).then(data => {
                self.annotationUnFocusedOpacity = 0.5;
                a.contextImageBase64Encoded = data;
                // let img = document.createElement("img");
                // img.src = a.contextImageBase64Encoded;
                // document.body.append(img);
                // console.log(data)
              });
            });
          };
        },
        handleWheelZoomEvent: function ($event, isSmoothFileType = true) {
          // console.log("zoom called");
          // only zoom if we combine the wheel scroll with ctrl key, otherwise actual view scroll functionality will break
          if ($event.ctrlKey) {
            $event.preventDefault();
            // scale based on vertical wheel scroll
            let delta = isSmoothFileType ? $event.deltaY * -0.003 : Math.sign($event.deltaY) * -0.05;
            let scrollZoom = Math.max(this.curFile.zoom + delta, 0.5);
            this.updateZoom(scrollZoom);
          }
        },
        reviewFile: function (file) {
          if (this.isRecording) {
            return;
          }
          let self = this;

          this.liveProofScriptDetected = true;
          this.liveProofLoaded = false;
          this.isLiveProofView = (file.isLiveWebProofing) ? file.isLiveWebProofing : false;
          this.liveProofActiveUrl = this.isLiveProofView ? file.name : null;

          //start tracking the loading status
          if (file.startedLoading === 0)
            file.startedLoading = self.curUnixTime;

          //pause the the player if it is playing
          if (self.curFile) {
            let s = self.$refs['audioAndVideoReview_id_' + self.curFile.id];
            if (
                s !== undefined && s[0] !== undefined &&
                !document.activeElement.isContentEditable
            ) {
              s[0].state === 1
                  ? s[0].pause()
                  : null;
            }
          }

          //save the last observed file
          self.$A.SaveDataCache(
              "proof_version_id:" + self.proof.currentVersion.id + "_fileId",
              file.id
          );

          //set the currently active file
          this.proof.currentVersion.versionFiles.forEach(function (i) {
            if (i.id === file.id) {
              i.active = true;
            } else {
              i.active = false;
            }
          });

          // this.curFile = undefined;

          this.$nextTick(() => {
            self.curFile = file;
            self.$emit("curFileChanged", file);

            let s = self.$refs['audioAndVideoReview_id_' + self.curFile.id];
            if (
                s !== undefined && s[0] !== undefined &&
                !document.activeElement.isContentEditable
            ) {
              this.$root.$emit("player:progress:change", s[0].lastTimeUpdateSecond);
            }

            self.updateZoom(1)
            self.triggerHeightUpdate();
            self.resetDrawing();

            self.$scrollTo(
                `#${self.label}-review-container`,
                500,
                {container: `#${self.label}-review-container`, offset: -200, cancelable: true}
            );
          })
        },
        viewAnnotation: function ({annotation, scroll, toggle}) {
          if (this.isRecording) {
            return;
          }

          this.setEditingAnnotation(annotation, toggle);

          if (this.liveProofCommentsEnabled === false && this.isLiveProofView) {
            this.liveProofCommentsEnabled = true;
          }

          if (this.isLiveProofView) {
            let urlChanged = annotation.liveWebProofingUrl !== this.liveProofActiveUrl;
            this.liveProofActiveUrl = annotation.liveWebProofingUrl;
            let nextSize = this.predefinedSizes.find(s => s.size === annotation.liveWebProofingResolution);
            let sizeChanged = nextSize != this.liveProofSizeSelected;
            this.liveProofSizeSelected = nextSize;
            if (sizeChanged && !urlChanged) {
              this.requestIframeValues();
            }
          }

          if (annotation.docType === "document") {
            this.curFile.pdf.pageToView = annotation.pdfPage;
          }

          let s = this.$refs['audioAndVideoReview_id_' + this.curFile.id];
          if (s !== undefined && s[0] !== undefined) {
            if (annotation.docType === "video" || annotation.docType === "audio") {
              s[0].goTo(annotation.curTime);
            }
          }

          if (scroll !== false) {
            let self = this;
            this.$nextTick().then(function () {
              setTimeout(function () {
                if (typeof self.scrollAnimationTask === "function") {
                  self.scrollAnimationTask();
                }
                self.scrollAnimationTask = self.$scrollTo(
                    `#${self.label}-annotation_id_${annotation.id}`,
                    500,
                    {container: `#${self.label}-review-container`, offset: -200, cancelable: true}
                );
              }, 500)
            })
          }
        },
        editComment: function (a, c) {
          if (this.proof.archived) {
            this.$A.AlertUser("The Proof Is Archived, No Changes Can Be Made.");
            return;
          }

          if (
              this.proof.blockCommentingAfterProofDecision &&
              this.approverFeedback !== null
          ) {
            this.$A.AlertUser(`${this.getLabel('approver_decision_modal_labels', 'approver_alert_after_submit')}`);
            return;
          }
          this.editingCommentId = c.id;
          let self = this;
          this.$nextTick().then(function () {
            let refs = self.$refs["editing_comment_redactor" + c.id];
            if (refs) {
              let redactorRef = refs[0];
              setTimeout(() => {
                redactorRef.setFocusEnd();
                redactorRef.setTextBody(c.text);
              }, 200);
            }
          });
        },
        saveExistingComment: async function (c) {
          if (this.proof.archived) {
            this.$A.AlertUser("The Proof Is Archived, No Changes Can Be Made.");
            return;
          }

          if (
              this.proof.blockCommentingAfterProofDecision &&
              this.approverFeedback !== null
          ) {
            this.$A.AlertUser("You have already submitted your response. The proof is now locked.");
            return;
          }

          let redactorRef = this.$refs["editing_comment_redactor" + c.id][0];
          let newText = redactorRef.getTextBody();
          redactorRef.setTextBody("");
          c.text = newText;
          let commentUpdated = await this.$A.AnnotationService.UpdateComment(c) || {};

          if (!_.isEmpty(commentUpdated) && commentUpdated.id > 0) {
            c = commentUpdated;
            this.editingCommentId = 0;
          }
        },
        deleteAnnotation: async function (a) {
          if (this.proof.archived) {
            this.$A.AlertUser("The Proof Is Archived, No Changes Can Be Made.");
            return;
          }

          if (
              this.proof.blockCommentingAfterProofDecision &&
              this.approverFeedback !== null
          ) {
            this.$A.AlertUser(`${this.getLabel('approver_decision_modal_labels', 'approver_alert_after_submit')}`);
            return;
          }

          if (!Number.isInteger(a.id)) {
            this.curAnnotations.splice(this.curAnnotations.indexOf(a), 1);
            return;
          }
          // console.log("deleteing id " + a.id);
          let response = await this.$A.AnnotationService.DeleteAnnotation(a.id);
          if (response.success) {
            this.curAnnotations.splice(this.curAnnotations.indexOf(a), 1);
          }
        },
        deleteComment: async function (a, c) {
          if (this.proof.archived) {
            this.$A.AlertUser("The Proof Is Archived, No Changes Can Be Made.");
            return;
          }

          if (
              this.proof.blockCommentingAfterProofDecision &&
              this.approverFeedback !== null
          ) {
            this.$A.AlertUser("You have already submitted your response. The Proof is now locked.");
            return;
          }

          let response = await this.$A.AnnotationService.DeleteComment(c.id);
          if (response && response.success) {
            a.proofFileAnnotationComments.splice(
                a.proofFileAnnotationComments.indexOf(c),
                1
            );
            if (
                a.proofFileAnnotationComments.length === 0 ||
                a.proofFileAnnotationComments === null ||
                a.proofFileAnnotationComments === undefined
            ) {
              await this.deleteAnnotation(a);
            }
          }
        },
        getTimeFormatFromSeconds(t) {
          return new Date(t * 1000).toISOString().substr(11, 8);
        },
        rotateImage: async function (srcBase64, degrees) {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const image = new Image();

          image.src = srcBase64;
          await image.decode();

          canvas.width = degrees % 180 === 0 ? image.width : image.height;
          canvas.height = degrees % 180 === 0 ? image.height : image.width;

          ctx.translate(canvas.width / 2, canvas.height / 2);
          ctx.rotate(degrees * Math.PI / 180);
          ctx.drawImage(image, image.width / -2, image.height / -2);

          return canvas.toDataURL();
        },
        addNewCommentToAnnotationThread: async function (a) {
          if (this.proof.archived) {
            this.$A.AlertUser("The Proof Is Archived, No Changes Can Be Made.");
            return;
          }

          if (
              this.proof.blockCommentingAfterProofDecision &&
              this.approverFeedback !== null
          ) {
            this.$A.AlertUser("You have already submitted your response. The Proof is now locked.");
            return;
          }

          let redactorRef = this.$refs["new_comment_" + a.id][0];
          let newText = redactorRef.getTextBody();
          redactorRef.setTextBody("");

          if (
              a.proofFileAnnotationComments === undefined ||
              a.proofFileAnnotationComments === null ||
              a.proofFileAnnotationComments.length === 0
          ) {
            let isDrawingType = this.curAnnotationType.type === "draw";

            // validate we have drawn something
            if (isDrawingType && this.$refs.drawing_tool && a.docType !== "audio") {
              if (this.$refs.drawing_tool.isEmpty()) {
                this.$A.AlertUser("You are creating a drawing comment but nothing has been drawn yet.");
                return;
              }
            }

            if (isDrawingType) {
              const degrees = 360 - this.curFile.rotation;
              const rotatedImage = await this.rotateImage(this.drawToolImage, degrees);
              a.drawingLayerImageBase64Encoded = rotatedImage;
              a.contextImageBase64Encoded = rotatedImage;
            } else {
              a.drawingLayerImageBase64Encoded = "";
            }

            if (!a.contextImageBase64Encoded) {
              a.contextImageBase64Encoded = "";
            }

            let annotationCreated = await this.$A.AnnotationService.CreateAnnotation(a) || {};

            if (!_.isEmpty(annotationCreated) && annotationCreated.id > 0) {
              if (annotationCreated.proofFileAnnotationComments === null) {
                annotationCreated.proofFileAnnotationComments = [];
              }
              a.id = annotationCreated.id;
              a.drawingLayerImageUrl = annotationCreated.drawingLayerImageUrl;
              this.editingAnnotationId = a.id;
            }
          }

          if (Number.isNaN(a.id)) {
            this.$A.AlertUser(this.$A.ReviewService.Lang("error-save-comment-later"));
            return;
          }

          if (a.proofFileAnnotationComments.length === 0 && a.curTime > 0) {
            newText =
                "@" + this.getTimeFormatFromSeconds(a.curTime) + " \n" + newText;
          }

          let newComment = {
            id: this.$A.GetUUID(),
            proofAnnotationId: a.id,
            proofId: a.proofId,
            proofVersionId: a.proofVersionId,
            proofFileId: a.proofFileId,
            userId: this.curUser && this.curUser.id ? this.curUser.id : 0,
            approverId:
                this.curApprover && this.curApprover.id ? this.curApprover.id : 0,
            ownerName:
                this.curApprover && this.curApprover.name
                    ? this.curApprover.name
                    : this.curUser && this.curUser.name
                        ? this.curUser.name
                        : "",
            createdAt: new Date(),
            text: newText,
            drawingLayerImageBase64Encoded: ""
          };

          newComment.ownerInitials = newComment.ownerName.match(/\b\w/g).join("");

          let savedComment = await this.$A.AnnotationService.CreateComment(
              newComment
          );

          if (savedComment !== null && savedComment.id > 0) {
            a.proofFileAnnotationComments.push(savedComment);
          } else {
            this.$A.AlertUser("Error Encountered, Please Save This Comment Later.");
          }
          if (this.$refs.drawing_tool) {
            this.$refs.drawing_tool.reset();
            // this.editingAnnotationId = 0;
          }
        },
        downloadThisFile: function (e) {
          e.preventDefault();
          let self = this;
          if (self.curFile === undefined || self.isLiveProofView) {
            return;
          }
          let file = self.curFile;
          if (this.curApprover && this.curApprover.token !== undefined) {
            this.$A.ProofFileService.DownloadSingle(
                this.publicId,
                this.proof.currentVersion.id,
                file.id,
                0,
                this.curApprover.token,
                name
            );
          }
          if (this.curUser && this.curUser.apiToken !== undefined) {
            this.$A.ProofFileService.DownloadSingle(
                this.publicId,
                this.proof.currentVersion.id,
                file.id,
                this.curUser.apiToken.token,
                "",
                name
            );
          }
        },
        downloadAllFiles: function (e) {
          e.preventDefault();
          if (this.proof === null) {
            return;
          }
          let name =
              this.proof.name +
              "_" +
              this.proof.currentVersion.versionNumber +
              ".zip";
          if (this.curApprover && this.curApprover.token !== undefined) {
            this.$A.ProofFileService.DownloadAll(
                this.publicId,
                this.proof.currentVersion.id,
                0,
                this.curApprover.token,
                name
            );
          }
          if (this.curUser && this.curUser.apiToken !== undefined) {
            this.$A.ProofFileService.DownloadAll(
                this.publicId,
                this.proof.currentVersion.id,
                this.curUser.apiToken.token,
                "",
                name
            );
          }
        },
        isModalOpen: function () {
          let approverResponseModal = this.$parent.$refs.approverResponseModal;
          return approverResponseModal && approverResponseModal.visible;
        },
        disableContextMenu: function () {
          let self = this;
          let interval = setInterval(() => {
            if (self.$refs.reviewContainer) {
              self.$refs.reviewContainer.addEventListener("contextmenu", function (evt) {
                evt.preventDefault();
                return false;
              });
              clearInterval(interval);
            }
          }, 50);
        },
        handleKeyDown: function () {
          let self = this;
          window.document.addEventListener("keydown", (event) => {
            if (self.isModalOpen() || self.editingAnnotationId != 0 || (!self.isReviewMode && !self.isMouseOver) || !self.curFile) return;
            switch (event.key) {
              case "F1":
                self.toggleZoomMode();
                return;
              case "ArrowRight":
                event.preventDefault();
                self.GoToNextPage();
                return;
              case "ArrowLeft":
                event.preventDefault();
                self.GoToPreviousPage();
                return;
              case "ArrowUp":
                if (!window.selectingUserToTag) {
                  event.preventDefault();
                  self.GoToPreviousProofFile();
                }
                return;
              case "ArrowDown":
                if (!window.selectingUserToTag) {
                  event.preventDefault();
                  self.GoToNextProofFile();
                }
                return;
              case " ":
                if (self.curFile) {
                  let s = self.$refs['audioAndVideoReview_id_' + self.curFile.id];
                  if (
                      s !== undefined && s[0] !== undefined &&
                      !document.activeElement.isContentEditable
                  ) {
                    s[0].state === 1
                        ? s[0].pause()
                        : s[0].play();
                  }
                }
            }
          });
        },
        resize: function () {
          return this.curFile ? this.updateZoom(this.curFile.zoom) : 0;
        },
        setRecording: function (status) {
          this.isRecording = status;
        },
        handleIframeLoaded: function ($event, versionFile) {
          this.liveProofLoaded = true;
          this.handleFileLoaded($event, versionFile);
          this.checkLiveProofScriptDetected();
          this.requestIframeValues();
        },
        checkLiveProofScriptDetected: function () {
          if (this.liveProofLoaded) {
            let self = this;
            if (this.isLiveProofView) {
              this.$A.LiveProofService.checkUrl(this.liveProofActiveUrl).then(status => {
                self.liveProofScriptDetected = status.scriptFound;
                if (!status.loaded) {
                  let message = "Unable to contact site at given URL";
                  self.$A.AlertUser(message);
                } else if (!status.scriptFound) {
                  self.liveProofViewHeight = 6000;
                  let message = `${self.getLabel('review_screen_labels', 'embed_script_no_detected_alert')}\n\n`
                      + `&lt;script src="${self.$A.LiveProofService.scriptUrl}"&gt;&lt;/script&gt;`;
                  self.$A.AlertUser(message);
                }
              })
            }
          }
        },
        requestIframeValues: function () {
          let self = this;
          clearInterval(this.liveProofInterval);
          this.liveProofInterval = setInterval(function () {
            const liveProofIframe = _.get(self, '$refs.liveProofIframe[0].contentWindow');
            if (liveProofIframe) {
              let message = JSON.stringify({key: 'ashore', id: self.editingUniqueAnnotationId, method: 'get'});
              liveProofIframe.postMessage(message, "*");
            } else {
              clearInterval(self.liveProofInterval);
            }
          }, 200)
        },
        setLiveProofSizeSelected: function (value) {
          let self = this;
          this.liveProofSizeSelected = value;
          this.$nextTick().then(function () {
            self.liveProofViewHeight = 6000;
            self.requestIframeValues();
          });
        },
        toggleColorPicker: function () {
          this.colorPickerVisible = !this.colorPickerVisible;
        },
        undoDrawing: function () {
          if (this.$refs.drawing_tool) {
            this.$refs.drawing_tool.undo();
          }
        },
        resetDrawing: function () {
          if (this.$refs.drawing_tool && !this.$refs.drawing_tool.isEmpty() && this.curFile.docTypeGroup !== 'audio') {
            this.$refs.drawing_tool.reset();
          }
        },
        refreshDrawing: function () {
          if (this.$refs.drawing_tool) {
            this.$refs.drawing_tool.redraw();
          }
        },
        getColor: function (userId, luminosity = "dark") {
          if (this.colorPerUserId[userId]) {
            return this.colorPerUserId[userId]
          }
          let seed = Object.keys(this.colorPerUserId).length * 1000;
          let color = this.$randomColor({luminosity: luminosity, seed: seed})
          this.colorPerUserId[userId] = color;
          return color;
        },
        shouldDisplayPdfAsImage: function (file) {
          let isPdfImage = file &&
              file.mimeType &&
              file.mimeType === "application/pdf" &&
              file.pdfExtractedJpgPages &&
              file.pdfExtractedJpgPages.length > 0;

          if (isPdfImage) {
            file.pdf.totalPages = file.pdfExtractedJpgPages.length;
          }

          return isPdfImage;
        },
        shouldDisplayAsImage: function (file) {
          return !this.isLiveProofView &&
              (file.docTypeGroup === 'image' || file.docTypeGroup === 'html' || this.shouldDisplayPdfAsImage(file));
        },
        getLabel: function (section, key) {
          return this.$A.LangService.getLabel(section, key);
        },
        refreshIsSmallMobile: function () {
          this.isSmallMobileView = window.innerWidth < 576;
        },
        shouldShowLoader: function (curFile) {
          return curFile && (!curFile.loaded || curFile.converting) && curFile.docTypeGroup !== 'document';
        },
        shouldShowTimer: function (curFile) {
          return curFile.converting && curFile.docTypeGroup !== 'document';
        }
      },
  computed: {
    curUsersTaggable: function () {
      let taggableUsers = [];

      let curApprovers = _.get(this.proof, 'currentVersion.currentStage.approvers', []);
      let curReviewers = _.get(this.proof, 'currentVersion.currentStage.reviewers', []);

      const curUsers = _.get(this.proof, 'engagedUsers', []);
      const curUserId = _.get(this.curUser, 'id');
      const curSender = _.get(this.proof, 'currentVersion.sender');
      const curSenderId = _.get(curSender, 'id');
      const curApproverId = _.get(this.curApprover, 'id');

      const toAddUsers = [];
      // add current user
      if (this.curUser) toAddUsers.push(this.curUser);
      // add sender
      if (curSender && curSenderId !== curApproverId) toAddUsers.push(curSender);
      // add reviewers
      toAddUsers.push(...curReviewers.filter(a => a.id !== curApproverId));
      // add engaged users
      toAddUsers.push(...curUsers.filter(a => a.id !== curApproverId));

      const toAddApprovers = [];
      // add current approver
      if (this.curApprover) toAddApprovers.push(this.curApprover);
      // add approvers
      toAddApprovers.push(...curApprovers.filter(a => a.id !== curUserId));

      // lookup map for those ids already added as taggable users
      const added = {};

      // adds a new taggable user if not added already
      const addTaggable = (a, type) => {
        if (added[a.id]) return;
        taggableUsers.push({
          type,
          name: a.name,
          color: a.color,
          email: a.email,
          initials: a.initials,
          publicId: a.publicId,
          id: a.id,
        });
        added[a.id] = true;
      };

      // add users
      toAddUsers.forEach(a => addTaggable(a, 'user'));

      // add approvers
      toAddApprovers.forEach(a => addTaggable(a, 'approver'));

      return taggableUsers;
    },
    curReviewers: function () {
      return this.proof &&
      this.proof.currentVersion &&
      this.proof.currentVersion.currentStage &&
      this.proof.currentVersion.currentStage.reviewers
          ? this.proof.currentVersion.currentStage.reviewers
          : [];
    },
    curApprovers: function () {
      return this.proof &&
      this.proof.currentVersion &&
      this.proof.currentVersion.currentStage &&
      this.proof.currentVersion.currentStage.approvers
          ? this.proof.currentVersion.currentStage.approvers
          : [];
    },
    curUsers: function () {
      return this.proof && this.proof.engagedUsers
          ? this.proof.engagedUsers
          : [];
    },
    files: function () {
      let a = this.proof &&
      this.proof.currentVersion &&
      this.proof.currentVersion.versionFiles
          ? this.proof.currentVersion.versionFiles
          : [];
      return a;
    },
    filesLoaded: function () {
      let a = this.proof &&
      this.proof.currentVersion &&
      this.proof.currentVersion.versionFiles
          ? this.proof.currentVersion.versionFiles
          : [];
      return a.filter(file => file.startedLoading !== 0 || file.loaded);
    },
    versions: function () {
      return this.proof === null ? [] : this.proof.versionIds;
    },
    curAnnotations: function () {
      if (this.curFile) {
        this.curFile.proofFileAnnotations.forEach((a, i) => {
          a.isExpanded = (!a.resolved)
          a.index = i + 1;
        });
        return this.curFile.proofFileAnnotations;
      } else {
        return [];
      }
    },
    annotationTypes: function () {
      if (this.curFile) {
        let annotationTypes = [
          {
            docTypes: ["document", "video", "image", "html", "web_url"],
            type: "point",
            title: this.getLabel('review_screen_labels', 'anno_single_click'),
            image: "/img/icon-doubleclick.svg",
            cursor: "/img/icon-doubleclick.png",
          },
          {
            docTypes: ["document", "video", "image", "html", "web_url"],
            type: "rect",
            title: this.getLabel('review_screen_labels', 'anno_rect'),
            image: "/img/icon-rectangle.svg",
            cursor: "/img/icon-rectangle.png",
          },
          {
            docTypes: ["document", "video", "image", "html", "web_url"],
            type: "arrow",
            title: this.getLabel('review_screen_labels', 'anno_arrow'),
            image: "/img/icon-arrow.svg",
            cursor: "/img/icon-arrow.png",
          },
          {
            docTypes: ["document", "video", "image", "html", "web_url"],
            type: "draw",
            title: this.getLabel('review_screen_labels', 'anno_draw'),
            image: "/img/draw.svg",
            cursor: ""
          },
        ];

        if (this.isMobile) {
          return annotationTypes.filter((i) => (i.type === "point" || i.type === "draw"));
        }

        return annotationTypes.filter(
            (i) => i.docTypes.indexOf(this.curFile.docTypeGroup) > -1
        );
      } else {
        return [];
      }
    },
    reviewWindowHeightPx: function () {
      if (this.reviewWindowHeight === 0) {
        return '';
      }
      return this.reviewWindowHeight + 'px';
    },
    reviewContainerClasses: function () {
      switch (this.viewMode) {
        case ViewMode.Review:
          return "review-container " + (this.sidebarVisible ? "col-lg-9 col-sm-12" : "review-container col-12");
        case ViewMode.Compare:
          return "review-container col-lg-12";
        default:
          return "review-container col-lg-3 col-sm-12";
      }
    },
    sidebarClasses: function () {
      switch (this.viewMode) {
        case ViewMode.Review:
          return "review-sidebar col-lg-3 col-sm-12 mb-2";
        case ViewMode.Compare:
          return "review-sidebar col-lg-12";
        default:
          return "review-sidebar col-lg-3 col-sm-12";
      }
    },
    currentUser: function () {
      return (this.curUser && this.curUser === null) ? {id: 0} : this.curUser;
    },
    currentApprover: function () {
      return (this.curApprover && this.curApprover === null) ? {id: 0} : this.curApprover;
    },
    curSender: function () {
      return this.proof &&
      this.proof.currentVersion &&
      this.proof.currentVersion.sender
          ? this.proof.currentVersion.sender
          : {};
    },
    liveProofViewWidth: function () {
      if (this.liveProofSizeSelected.size > 0) {
        return this.liveProofSizeSelected.size;
      }
      let self = this;
      let reviewScreenWidthSize = this.calcReviewWindowWidth();

      this.predefinedSizes.forEach(sizeOption => {
        let optionFitsOnScreen = sizeOption.size <= reviewScreenWidthSize
        let optionIsLargerThanCurrent = sizeOption.size > self.liveProofSizeSelected.size
        if (optionFitsOnScreen && optionIsLargerThanCurrent) {
          self.liveProofSizeSelected = sizeOption;
        }
      })

      return this.liveProofSizeSelected.size;
    },
    editingUniqueAnnotationId: function () {
      return this._uid + "_" + this.editingAnnotationId;
    },
    drawToolHeight: function () {
      if (this.curFile) {
        if (this.curFile.docTypeGroup === 'video') {
          if (this.drawToolVideoHeight == 0) {
            this.triggerHeightUpdate();
          }
          return this.drawToolVideoHeight;
        }
        if (this.isLiveProofView) {
          return this.liveProofViewHeight;
        }
        return this.curFile.zoomHeight;
      }
      return 500;
    },
    curFileSrcImageUrl: function () {
      if (this.shouldDisplayPdfAsImage(this.curFile)) {
        return this.curFile.pdfExtractedJpgPages[this.curFile.pdf.pageToView - 1].uri;
      }
      if (this.curFile) {
        return this.curFile.src;
      }
      return "";
    },
    tourSteps: function () {
      let self = this;
      return this.steps.filter(step => {
        if (step.disableOnFileTypes && self.curFile && step.disableOnFileTypes.includes(self.curFile.docTypeGroup)) {
          return false;
        }
        if (step.disableOnModes && step.disableOnModes.includes(self.mode)) {
          return false;
        }
        return true;
      }).map(step => {
        if (step.label && !step.content) {
          step.content = self.getLabel('tour_labels', step.label);
        }
        return step;
      });
    },
    tourLabels: function () {
      let self = this;
      let labels = {};
      this.tourLabelKeys.forEach(tl => {
        labels[tl.button] = self.getLabel('tour_labels', tl.label);
      })
      return labels;
    },
    getPredefinedSizesLabels: function () {
      let self = this;
      return this.predefinedSizes.filter(size => {
        if (size.disableOnFileTypes && self.curFile && size.disableOnFileTypes.includes(self.curFile.docTypeGroup)) {
          return false;
        }
        if (size.disableOnModes && size.disableOnModes.includes(self.mode)) {
          return false;
        }
        return true;
      }).map(size => {
        if (size.label) {
          size.label = self.getLabel('review_screen_labels', size.label);
        }
        return size;
      });
    },
    shouldShowFilesToReview() {
      return _.get(this, 'proof.currentVersion.versionFiles', []).length > 1 || this.isCompareMode;
    }
  },
  watch: {
    sidebarVisible: function () {
      let self = this;
      this.$nextTick().then(function () {
        if (self.curFile)
          self.updateZoom(self.curFile.zoom);
      });
    },
    editingAnnotationId: function () {
      let drawingToolRef = this.$refs.drawing_tool;
      if (drawingToolRef && this.curAnnotationType.type === 'draw'
          && !drawingToolRef.isEmpty()) {
        if (Number.isInteger(this.editingAnnotationId) && this.editingAnnotationId > 0) {
          this.resetDrawing();
        }
      }
    },
    curAnnotationType: function () {
      if (this.curAnnotationType.type === 'draw') {
        this.resetDrawing();
        let a = this.prepNewAnnotation();
        this.appendNewAnnotation(a);
      }
    },
    annotationTypes: {
      handler(newVal) {
        const selectedAnnotation = newVal.find(annotation => annotation.type === this.curAnnotationType.type);
        if (selectedAnnotation) {
          this.curAnnotationType.title = _.get(selectedAnnotation, 'title', '').toString();
        }
      },
      deep: true
    }
  },
};

</script>

<template>
  <div>
    <!-- Second Navigation-->
    <div
        v-if="!accessDenied"
        class="review-navbar secondary-menu"
        :id="`${label}-reviewSecondaryNav`"
        ref="reviewSecondaryNav"
    >
      <div class="container-fluid">
        <div class="row">
          <!-- shows on compare mode -->
          <div v-if="proof && isCompareMode" :class=" (isLiveProofView) ? 'col-lg-6 border-left':'col-lg-4'"
               v-show="curFile">
            <v-select
                label="versionNumber"
                :options="versions"
                class="proof-options"
                :clearable="false"
                :value="proof.currentVersion"
                @input="(selected) => viewProofVersion(selected.proofVersionId)"
            >
              <template slot="selected-option" slot-scope="option">
                V{{ option.versionNumber }}
              </template>
              <template slot="option" slot-scope="option">
                Version #{{ option.versionNumber }}
              </template>
            </v-select>
          </div>
          <!-- always shows -->
          <div
              :class="'border-991 ' + (isCompareMode ? (isLiveProofView ? 'col-lg-6 border-left' : 'col-lg-8'):(isLiveProofView ? 'col-lg-4 col-md-4' : 'col-lg-4'))"
              :id="`${label}-available-tools`">
            <v-select
                v-model="curAnnotationType"
                label="title"
                :options="annotationTypes"
                class="proof-options"
                :clearable="false"
                v-show="(curFile && curFile.docTypeGroup !== 'audio')"
                :disabled="(isLiveProofView && !liveProofCommentsEnabled)"
            >
              <template slot="selected-option" slot-scope="option">
                <img :src="option.image"/>
                {{ option.title  }}
              </template>
              <template slot="option" slot-scope="option">
                <img :src="option.image"/>
                {{ option.title }}
              </template>
            </v-select>
          </div>
          <!-- shows on live proof view mode -->
          <div v-if="proof && isLiveProofView"
               :class=" 'pt-3 ' + ((isCompareMode) ? 'col-lg-6 col-md-6 border-top':'col-lg-3 col-md-5 border-left')"
               v-show="curFile">
            <v-select :value="liveProofSizeSelected"
                      @input="setLiveProofSizeSelected"
                      :options="getPredefinedSizesLabels"
                      :clearable="false"
                      data-cy="liveProofSizeSelectedInput">

            </v-select>
          </div>
          <!-- live proof view comments mode toggle -->
          <div v-if="proof && isLiveProofView"
               :class="'pt-4 border-left border-right ' + ((isCompareMode) ? 'col-lg-6 col-md-6 border-top':'col-lg-2 col-md-4') ">
            <div class="ashore-toggle" :id="`${label}-live-toggle-comments`">
              <label class="switch">
                <input type="checkbox" v-model="liveProofCommentsEnabled" :disabled="isRecording"/>
                <div class="slider round"></div>
              </label>
              <span>
                 {{ getLabel('review_screen_labels', 'web_comment_mode_toggle') }} <img id="live-toggle-comments-icon"
                                                                                        src="/img/icon-help.svg"
                                                                                        style="height: 20px"/>
                <b-tooltip target="live-toggle-comments-icon" triggers="hover">
                  {{ getLabel('review_screen_labels', 'web_comment_mode_toggle_info') }}
                </b-tooltip>
              </span>
            </div>
          </div>
          <!-- shows on audio/video -->
          <div v-if="!isMobile"
               :class="'col-sm-2 text-center zoom-section border-736 noselect ' +  (isCompareMode ? 'col-lg-4' : 'col-lg-1')"
               v-show="curFile && (curFile.docTypeGroup === 'audio')"
          ></div>
          <!-- shows on non audio -->
          <div
              :class="'col-sm-2 text-center zoom-section border-736 option-section-borderless noselect ' + (isCompareMode ? 'col-lg-4' : 'col-lg-1 border-left border-right')"
              v-show="curFile && curFile.docTypeGroup !== 'audio' && !isLiveProofView"
          >
            <a class="item-select" @click="updateZoom((curFile.zoom += 0.1))">
              <img src="/img/zoom-in.svg"/>
            </a>
            <a class="item-select" v-if="!isMobile" @click="toggleZoomMode()">
              <img src="/img/full-height.svg" v-if="curFile && curFile.zoomMode===1"/>
              <img src="/img/full-width.svg" v-if="curFile && curFile.zoomMode===0"/>
            </a>
            <a class="item-select" @click="updateZoom((curFile.zoom -= 0.1))">
              <img src="/img/zoom-out.svg"/>
            </a>
          </div>
          <!-- shows only on images and pdf files -->
          <div
              :class="isSmallMobileView ? 'col-sm-12 text-center pb-2': 'col-sm-1 text-center align-items-center d-flex'"
              v-if="curFile && (curFile.docTypeGroup === 'document' || curFile.docTypeGroup === 'image')">
            <a @click="rotate">
              <img src="/img/rotate-right-icon.svg" class="rotate-icon"/>
            </a>
          </div>

          <!-- shows on non document -->
          <div v-if="!isMobile"
               :class="'col-sm-10 option-section-borderless ' + (isCompareMode ? 'col-lg-8' : 'col-lg-3')"
               v-show="curFile && curFile.docTypeGroup !== 'document' && !isLiveProofView"
          ></div>
          <!-- shows on document -->
          <div :class="'col-sm-10 option-section ' + (isCompareMode ? 'col-lg-8' : 'col-lg-3')"
               v-show="curFile && curFile.docTypeGroup === 'document'"
          >
            <div class="row no-gutters">
              <div class="col-4 col-lg-6 col-xl-4 text-left noselect">
                <a
                    class="item-select"
                    :class="{ disabled: curFile && curFile.pageToView === 1 }"
                    style="margin-left: 5px"
                    @click="GoToPreviousPage"
                >
                  <img src="/img/arrow-left-icon.svg"/>
                  {{ getLabel('review_screen_labels', 'previous_page') }}
                </a>
              </div>
              <div class="col-4 col-xl-4 text-center drop-1200 show-991">
                  <span
                      class="item-select item-page-picker"
                      @click="ShowGoToPage"
                  >
                    {{ getLabel('review_screen_labels', 'pagination_page') }} <span>{{
                      (curFile && curFile.pdf) ? curFile.pdf.pageToView : 0
                    }}</span> {{ getLabel('review_screen_labels', 'pagination_page_of') }}
                    {{ (curFile && curFile.pdf) ? curFile.pdf.totalPages : 0 }}
                  </span>
                <div
                    v-if="goToPageInputValue !== -1"
                    style="position: absolute; z-index: 2; top: 39px"
                    v-on:blur="goToPageInputValue = -1"
                >
                  <input
                      type="text"
                      v-model="goToPageInputValue"
                      ref="goToPageInput"
                      v-on:keydown="
                        $event.key === 'Enter' ? GoToPage(goToPageInputValue) : 0
                      "
                      style="
                        width: 104px;
                        height: 40px;
                        font-size: 22px;
                        text-align: center;
                      "
                  />
                </div>
              </div>
              <div class="col-4 col-lg-6 col-xl-4 text-right noselect">
                <a
                    class="item-select"
                    style="margin-right: 5px"
                    :class="{ disabled: ((curFile && curFile.pdf)?curFile.pdf.pageToView:0) + 1 > (curFile && curFile.pdf)?curFile.pdf.numPages:0 }"
                    @click="GoToNextPage"
                >
                  {{ getLabel('review_screen_labels', 'next_page') }}
                  <img src="/img/arrow-right-icon.svg"/>
                </a>
              </div>
            </div>
          </div>
          <!-- shows on review mode -->
          <div class="col-lg-3 text-center border-left drop-991 " v-if="isReviewMode">
            <a
                class="item-select"
                visible
                v-model="sidebarVisible"
                v-b-toggle="`${label}-sidebar_right`"
            >
              {{
                sidebarVisible ? getLabel('review_screen_labels', 'collapse_sidebar') : getLabel('review_screen_labels', 'expand_sidebar')
              }}
              <img src="/img/icon-collaspe.svg"/>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Review Files -->

    <div
        v-if="accessDenied"
        class="container-fluid"
        :style="{ height: reviewWindowHeightPx }"
    >
      <h2>{{ accessDeniedMessage }}</h2>
    </div>

    <div
        v-if="!accessDenied"
        class="container-fluid"
        :id="`${label}-reviewContainerRow`"
        ref="reviewContainerRow"
        :style="(isMobile ? '' : {height: reviewWindowHeightPx, overflowY: 'auto'})"
    >
      <div
          :id="`${label}-your-canvas`"
          class="row"
          ref="containerRow"
          :style="(isMobile) ? '': { height: reviewWindowHeightPx }"
      >
        <!-- Live Web Proofing Warning Message -->
        <div v-if="!liveProofScriptDetected && isLiveProofView"
             :class="'d-flex justify-content-between bg-white p-2 ' + (!isCompareMode ? 'col-9': 'col-12 border-left')">
          <span class="ml-2"> <span class="rounded-circle bg-danger d-inline-block mr-1"
                                    style="width: 7px; height: 7px;"></span> {{
              getLabel('review_screen_labels', 'web_script_error')
            }}</span>
          <a class="mr-2 font-weight-bold" href="https://kb.ashoreapp.com/ashore-embed-script/"
             target="_blank">{{ getLabel('review_screen_labels', 'embed_script_instructions') }}<img
              src="/img/icon-arrow-right.svg" class="ml-1"/> </a>
        </div>

        <!-- Proof Window -->
        <b-col
            :id="`${label}-review-container`"
            ref="reviewContainer"
            :class="reviewContainerClasses"
            :style="(isMobile) ? '': {
              height: reviewWindowHeightPx,
              cursor: (curFile && curFile.docTypeGroup !== 'audio' ? 'url(' + curAnnotationType.cursor + '), auto' : 'default'),
            }"
            @mouseover="isMouseOver=true"
            @mouseleave="isMouseOver=false"
        >
          <div v-if="shouldShowLoader(curFile)">
            <img
                src="/img/review-screen-loading.gif"
                class="loading-img"
            />
            <p class="loading-text" v-if="shouldShowTimer(curFile)">
              File Processing<br/>
              ( {{ curFile.curTimer }} )
            </p>
            <p class="loading-text" v-if="!curFile.converting">File Loading</p>
          </div>
          <div
              class="review-window"
              ref="review_window"
              :id="`${label}-review-window`"
              draggable="false"
          >
            <!-- draw tool -->
            <div
                v-if="curFile && curAnnotationType.type==='draw' && curFile.docTypeGroup !== 'audio' && (!isLiveProofView || liveProofCommentsEnabled)"
                @mousedown="handleDrawingWindowMouseDown"
                :style="{
                    'border': '0px',
                    'position': 'absolute',
                    'overflow': 'visible',
                    'z-index': '900',
                    'height': drawToolHeight + 'px',
                    'width': curFile.zoomWidth + 'px'
                }">
              <vue-drawing-canvas ref="drawing_tool"
                                  :image.sync="drawToolImage"
                                  :eraser="drawToolEraser"
                                  :lineWidth="drawToolLineWidth"
                                  :color="colorPickerColor.hex8 ? colorPickerColor.hex8 : colorPickerColor"
                                  :background-color="drawToolBackgroundColor"
                                  :background-image="drawToolBackgroundImage"
                                  :width="isLiveProofView ? liveProofViewWidth : curFile.zoomWidth"
                                  :height="drawToolHeight"
                                  :disabled="drawToolDisabled"
                                  saveAs="png"
                                  :styles="{
                                          'cursor': 'url(' + curAnnotationType.cursor + '), auto',
                                           'border': '0px',
                                           'position': 'absolute',
                                           'overflow': 'visible',
                   'z-index': '2000',
                                  }"
              />
            </div>
            <!-- end draw tool -->
            <annotationsLayer
                ref="annotationLayer"
                :parent-id="label"
                :editingAnnotationId="editingAnnotationId"
                :curAnnotations="curAnnotations"
                :curAnnotation="curAnnotation"
                :viewAllComments="viewAllComments && (!isLiveProofView || liveProofCommentsEnabled)"
                :pdf-page="(curFile && curFile.pdf)?curFile.pdf.pageToView:1"
                :approvers="curApprovers"
                :reviewers="curReviewers"
                :users="curUsers"
                :cur-container-width="(isLiveProofView&&liveProofCommentsEnabled)?liveProofViewWidth:(curFile && curFile!==null)?curFile.zoomWidth:0"
                :cur-container-height="curFile ? curFile.zoomHeight : 0"
                :live-proof-active-url="liveProofActiveUrl"
                :live-proof-size-selected="liveProofSizeSelected"
                :unFocusedOpacity="annotationUnFocusedOpacity"
                v-on:onannotationmoved="handleAnnotationMoveEvent($event)"
                v-on:mousedown="handleWindowMouseDown($event)"
                :rotation="curFile ? curFile.rotation : 0"
            />
            <div v-for="versionFile in filesLoaded.filter(i=>i.isLiveWebProofing)"
                 :key="`liveproof_layer_${versionFile.id}`"
                 v-if="curFile && curFile.id === versionFile.id"
                 v-show="liveProofCommentsEnabled"
                 class="live-proof-layer"
                 :style="{width: liveProofViewWidth + 'px', height: liveProofViewHeight + 'px'}"
                 v-on:mousedown="handleWindowMouseDown($event)"
            ></div>
            <iframe class="d-block border-0 overflow-scroll"
                    v-for="versionFile in filesLoaded.filter(i=>i.isLiveWebProofing)"
                    :id="`liveproof_id_${versionFile.id}`"
                    :key="`liveproof_id_${versionFile.id}`"
                    ref="liveProofIframe"
                    draggable="false"
                    v-on:load="handleIframeLoaded($event, versionFile)"
                    v-if="curFile && curFile.id === versionFile.id"
                    :src="liveProofActiveUrl?liveProofActiveUrl:versionFile.name"
                    :width="liveProofViewWidth"
                    :height="liveProofViewHeight"
            />
            <!-- WIP Added transform:.. -->
            <img
                v-for="versionFile in filesLoaded.filter(file=>shouldDisplayAsImage(file))"
                :ref="`image_id_${versionFile.id}`"
                :key="`image_id_${versionFile.id}`"
                :id="`image_id_${versionFile.id}`"
                draggable="false"
                v-on:mousedown="handleWindowMouseDown($event)"
                v-on:wheel="handleWheelZoomEvent($event)"
                v-show="curFile && curFile.id === versionFile.id"
                :src="curFileSrcImageUrl"
                v-on:load="handleFileLoaded($event, versionFile)"
                :class="`rotate${curFile&&curFile.rotation?curFile.rotation:0}`"
                :style=" (curFile)?{
                    width: curFile.zoomWidth + 'px',
                    height: curFile.zoomHeight+ 'px'
                }:{}"
            />
            <div class="click-window-pdf"
                 v-for="versionFile in filesLoaded.filter(i=>i.docTypeGroup === 'document' && !shouldDisplayPdfAsImage(i))"
                 :key="`pdfItem_id_${versionFile.id}`"
                 v-show="curFile && curFile.id === versionFile.id"
                 v-on:mousedown="handleWindowMouseDown($event)"
                 v-on:wheel="handleWheelZoomEvent($event, false)"
                 :style=" (curFile && curFile.loaded)?{
                    transform: `rotate(${curFile.rotation}deg)`,
                    width: curFile.zoomWidth + 'px',
                    height: curFile.zoomHeight+ 'px',
                    'z-index': curFile.z_index,
                    'position':(isMobile)?'':'absolute',
                    'margin-bottom':(isMobile)?'10px':'',
                  }:{}"
            >
              <pdf
                  v-if="curFile"
                  :ref="`pdfItem_id_${versionFile.id}`"
                  draggable="false"
                  :src="versionFile.src"
                  :page="curFile.pdf.pageToView"
                  @loaded="handleFileLoaded($event, versionFile)"
                  @num-pages="handleNumPagesLoaded($event, versionFile)"
              />
            </div>
            <audioAndVideoReview
                v-for="versionFile in filesLoaded.filter(i=>i.docTypeGroup === 'video' || i.docTypeGroup === 'audio')"
                :key="`audioAndVideoReview_id_${versionFile.id}`"
                :ref="`audioAndVideoReview_id_${versionFile.id}`"
                draggable="false"
                v-show="curFile && curFile.id === versionFile.id"
                v-on:onplayerloaded="handleFileLoaded($event, versionFile)"
                v-on:mousedown="handleWindowMouseDown($event, versionFile)"
                v-on:oncommentcreated="handleNewAudioCommentCreated($event, versionFile)"
                :doc-type="versionFile.docTypeGroup"
                :contentType="versionFile.convertedMimeType"
                :annotations="versionFile.proofFileAnnotations"
                :src="versionFile.src"
                :poster="versionFile.thumbnailFileKey"
                :curWidth="(curFile)?curFile.zoomWidth:0"
                :isMobile="isMobile"
                :curHeight="(curFile)?curFile.zoomHeight:0"
                :editingAnnotationId="editingAnnotationId"
                :is-recording="isRecording"
            />
          </div>

        </b-col>
        <!-- Proof Sidebar -->
        <b-collapse
            :id="`${label}-sidebar_right`"
            ref="sidebar_right"
            visible
            v-model="sidebarVisible"
            :class="sidebarClasses"
            class="h-100"
            v-if="filesLoaded.length>0"
        >
          <div :class="'review-sidebar-content d-flex flex-column h-100' + (isCompareMode ? ' pt-4': '')" :style="{ height: (sideBarHeight>0)?sideBarHeight + 'px':'' }">
            <div
                v-if="mode === PROOF_OWNER_MODE && curUser !== null && isReviewMode"
                class="approver-list-item"
            >
              <div class="row">
                <div class="col-12">
                  <div
                      class="approver-color"
                      :style="{ background: curUser.color }"
                  >
                    {{ curUser.initials }}
                  </div>
                  <h4>{{ curUser.name }}</h4>
                </div>
              </div>
            </div>

            <div v-if="curApprover !== null" class="approver-list-item">
              <div class="row">
                <div class="col-12">
                  <div
                      class="approver-color"
                      :style="{ background: curApprover.color }"
                  >
                    {{ curApprover.initials }}
                  </div>
                  <h4>{{ curApprover.name }}</h4>
                </div>
              </div>
            </div>

            <div class="approver-list-item" v-for="user in curUsers">
              <div
                  class="row"
                  v-if="curUser === null || curUser.id !== user.id"
              >
                <div class="col-12">
                  <div
                      class="approver-color"
                      :style="{ background: user.color }"
                  >
                    {{ user.initials }}
                  </div>
                  <h4>{{ user.name }}</h4>
                </div>
              </div>
            </div>

            <div
                v-if="isReviewMode && (mode === PROOF_OWNER_MODE || (proof !== null && proof.allowViewAllComments))"
                class="approver-list-item"
                v-for="approver in curApprovers"
            >
              <div
                  class="row"
                  v-if="curApprover === null || curApprover.id !== approver.id "
              >
                <div class="col-12">
                  <div
                      class="approver-color"
                      :style="{ background: approver.color }"
                  >
                    {{ approver.initials }}
                  </div>
                  <h4>{{ approver.name }}</h4>
                </div>
              </div>
            </div>
            <div
                v-if="isReviewMode && (mode === PROOF_OWNER_MODE || (proof !== null && proof.allowViewAllComments))"
                class="approver-list-item"
                v-for="reviewer in curReviewers"
            >
              <div
                  class="row"
                  v-if="curApprover === null || curApprover.id !== reviewer.id "
              >
                <div class="col-12">
                  <div
                      class="approver-color"
                      :style="{ background: reviewer.color }"
                  >
                    {{ reviewer.initials }}
                  </div>
                  <h4>{{ reviewer.name }}</h4>
                </div>
              </div>
            </div>

            <!-- Files to Review -->
            <div class="row" v-if="shouldShowFilesToReview">
              <hr v-if="isReviewMode"/>
              <div class="col-md-6">{{ getLabel('review_screen_labels', 'files_to_review') }}</div>
              <div class="col-md-6 sidebar-collapse text-right">
                <a v-b-toggle="`${label}-files`">
                  {{
                    filesVisible ? getLabel('review_screen_labels', 'collapse_file_option') : getLabel('review_screen_labels', 'expand_file_option')
                  }}
                  <img
                      src="/img/caret-dark.svg"
                      :class="{
                        rotateimg180: filesVisible,
                      }"
                  />
                </a>
              </div>
            </div>
            <div clas="row" style="margin-top: 25px" v-if="shouldShowFilesToReview">
              <b-collapse
                  visible
                  :id="`${label}-files`"
                  v-model="filesVisible"
                  class="review-files"
              >
                <div
                    :id="`${label}-available-files`"
                    class="review-item"
                    :class="{ active: file.active === true }"
                    @click="reviewFile(file)"
                    v-for="file in proof &&
                    proof.currentVersion &&
                    proof.currentVersion.versionFiles
                      ? proof.currentVersion.versionFiles
                      : []"
                    v-bind:key="file.id"
                >
                  <div class="row">
                    <div class="col-10">
                      {{ file.name }}
                    </div>
                    <div class="col-2">
                      <div class="comment-bubble">
                        {{ file.proofFileAnnotations.length }}
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>

            <!-- Comments -->
            <div class="row">
              <hr/>
              <div class="col-md-6">{{ getLabel('review_screen_labels', 'comment_toggle_header') }}</div>
              <div class="col-md-6 sidebar-collapse text-right">
                <a v-b-toggle="`${label}-comments`" :disabled="isRecording">
                  {{
                    commentsVisible ? getLabel('review_screen_labels', 'collapse_file_option') : getLabel('review_screen_labels', 'expand_file_option')
                  }}
                  <img
                      src="/img/caret-dark.svg"
                      :class="{
                        rotateimg180: commentsVisible,
                      }"
                  />
                </a>
              </div>
              <div class="col-md-12" style="margin-top: 25px">
                <div class="ashore-toggle" :id="`${label}-proof_toggle_download`">
                  <label class="switch">
                    <input type="checkbox" v-model="viewAllComments" :disabled="isRecording"/>
                    <div class="slider round"></div>
                  </label>
                  {{ getLabel('review_screen_labels', 'comment_toggle') }}
                </div>
              </div>
            </div>
            <div clas="row comment-box-holder" style="margin-top: 5px" data-cy="commentsWrapper">
              <b-collapse
                  visible
                  :id="`${label}-comments`"
                  v-model="commentsVisible"
                  class="review-files"
              >
                <div
                    class="review-item"
                    :class="{
                      'resolved-comment': annotation.resolved && curUser !== null,
                    }"
                    v-for="(annotation, index) in curAnnotations"
                    :style="{
                      opacity:
                        editingAnnotationId !== 0 &&
                        editingAnnotationId !== annotation.id
                          ? 0.5
                          : 1,
                    }"
                    :data-cy="`comment_${index + 1}`"
                >
                  <div
                      class="row"
                      :id="`${label}-annotation_side_bar_${annotation.id}`"
                      :key="`${label}-annotation_side_bar_${annotation.id}`"
                  >
                    <div class="col-8 d-flex justify-content-start"
                         @click="viewAnnotation({annotation, toggle : true})">
                      <div
                          class="approver-color"
                          v-b-tooltip.hover
                          :title="annotation.ownerName"
                          :style="{
                            background:
                              $refs.annotationLayer
                                ? $refs.annotationLayer.getColor(annotation)
                                : '',
                          }"
                      >
                        {{ annotation.ownerInitials }}
                      </div>
                      <p class="mt-1">{{ getLabel('review_screen_labels', 'comment_number') }}{{ index + 1 }}</p>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                      <div
                          class="comment-bubble mr-2"
                          v-show="
                            annotation.proofFileAnnotationComments.length - 1 > 0
                          "
                      >
                        {{ annotation.proofFileAnnotationComments.length }}
                      </div>
                      <div
                          @click="viewAnnotation({annotation, toggle : true})"
                          class="comment-thread-drop-down-caret" data-cy="expandComment">
                        <img
                            src="/img/caret-dark.svg"
                            :class="{
                            rotateimg180: annotation.isExpanded,
                          }"
                        />
                      </div>
                    </div>

                  </div>


                  <div
                      class="ashore-toggle resolved-slider"
                      v-if="
                        curUser !== null && editingAnnotationId === annotation.id
                      "
                  >
                    <label class="switch">
                      <input
                          type="checkbox"
                          v-model="annotation.resolved"
                          v-on:change="updateAnnotationResolvedStatus(annotation)"
                      />
                      <div class="slider round"></div>
                    </label>
                    {{
                      annotation.resolved ? getLabel('review_screen_labels', 'comment_resolved') : getLabel('review_screen_labels', 'comment_not_resolved')
                    }}
                  </div>

                  <div
                      class="comment-item"
                      @click="viewAnnotation({annotation})"
                      v-if="annotation !== undefined &&
                        Array.isArray(annotation.proofFileAnnotationComments) &&
                        annotation.isExpanded
                      "
                      v-for="comment in annotation.proofFileAnnotationComments"
                      :id="`${label}-annotation_comment_item_${comment.id}`"
                      :key="`${label}-annotation_comment_item_${comment.id}`"
                  >
                    <hr/>
                    <div class="row no-gutters mb-20">
                      <div class="col-9">
                        <div
                            class="approver-color"
                            v-b-tooltip.hover
                            :title="comment.ownerName"
                            :style="{
                              background: $refs.annotationLayer.getColor(comment),
                            }"
                        >
                          {{ comment.ownerInitials }}
                        </div>
                        <span class="comment-date">
                          {{
                            $A.FormatDate(comment.createdAt, true)
                          }}</span>
                      </div>
                      <div
                          class="col-3 comment-options text-right"
                          v-if="
                            (currentUser && comment.userId === currentUser.id)
                             ||
                            (currentApprover && comment.approverId === currentApprover.id)

                          "
                      >
                        <img
                            src="/img/pencil-icon.svg"
                            @click="editComment(annotation, comment)"
                        />
                        <img
                            src="/img/trash-icon.svg"
                            @click="deleteComment(annotation, comment)"
                        />
                      </div>
                    </div>
                    <div class="row border-0">
                      <div class="col-12">
                        <div
                            class="comment-text"
                            v-html="comment.text"
                            v-if="editingCommentId !== comment.id"
                            :data-cy="`comment_text_${index + 1}`"
                        ></div>
                        <redactor
                            :commentApiUrl="commentFileApi"
                            :curUsersTaggable="curUsersTaggable"
                            :text="comment.text"
                            v-if="editingCommentId === comment.id"
                            :ref="'editing_comment_redactor' + comment.id"
                            :show-source="false"
                            @onrecording="setRecording"
                        />
                        <button
                            v-if="editingCommentId === comment.id"
                            class="button button-green button-block button-light"
                            @click="saveExistingComment(comment)"
                        >
                          Update Comment
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr
                      v-if="
                        annotation !== undefined &&
                        Array.isArray(annotation.proofFileAnnotationComments) &&
                        editingAnnotationId === annotation.id
                      "
                  />
                  <div
                      class="row"
                      v-if="
                        annotation !== undefined &&
                        Array.isArray(annotation.proofFileAnnotationComments) &&
                        editingAnnotationId === annotation.id
                      "
                  >
                    <div class="col-12">
                      <!-- draw tool ui-->
                      <div
                          v-if="curAnnotationType.type === 'draw' && curFile.docTypeGroup !== 'audio' && (!Number.isInteger(editingAnnotationId) || editingAnnotationId === 0)"
                          class="d-flex mb-3 draw-wrapper"
                      >
                        <b-col col lg="2" md="2" xs="1" class="border-draw-right p-0 circle-style">
                          <span
                              class="draw-colorpicker"
                              @click="toggleColorPicker"
                              :style="{'background-color': colorPickerColor.hex8 ? colorPickerColor.hex8 : colorPickerColor, minWidth: 15, minHeight:15, color: colorPickerColor.hex8 ? colorPickerColor.hex8 : colorPickerColor}"
                          ></span>
                          <div v-show="colorPickerVisible" class="color-picker-modal">
                            <chrome-picker v-model="colorPickerColor"></chrome-picker>
                          </div>
                        </b-col>
                        <b-col cols="7" xs="10" class="border-draw-right p-0">
                          <v-select
                              :options="drawToolLineWidthOptions"
                              id="drawing-tool"
                              class=""
                              :clearable=false
                              v-model="drawToolLineWidth"
                          >
                            <template slot="selected-option" slot-scope="option">
                              <span class="draw-line" :style="{height: option.label + 'px'}"></span>
                              <span class="font-weight-normal draw-line-option-label"> {{ option.label }}px</span>
                            </template>
                            <template slot="option" slot-scope="option">
                              <span class="draw-option">
                                <span class="draw-line" :style="{height: option.label + 'px'}"></span>
                                <span class="font-weight-normal draw-line-option-label">{{ option.label }}px</span>
                              </span>
                            </template>
                          </v-select>
                        </b-col>
                        <b-col col lg="2" sm="1" class="d-flex align-items-center justify-content-around ">
                          <img src="/img/undo.svg" style="height: 22px" @click="undoDrawing"
                               class="mr-md-3 ml-md-2 ml-lg-3">
                          <img src="/img/close.svg" style="height: 15px" @click="resetDrawing">
                        </b-col>
                      </div>
                      <!-- ends draw tool ui-->
                      <redactor
                          :curUsersTaggable="curUsersTaggable"
                          :placeholder="getLabel('review_screen_labels', 'redactor_placeholder')"
                          :commentApiUrl="commentFileApi"
                          :ref="'new_comment_' + annotation.id"
                          :show-source="false"
                          :text="isLiveProofView?`<p><strong><a href='${liveProofActiveUrl}' style='color:black'>${liveProofActiveUrl}</a></strong></p><p data-cy='size_selected_text'>${liveProofSizeSelected.label}</p><br>`:''"
                          @onrecording="setRecording"
                          data-cy="commentTextArea"
                      />
                      <button
                          class="button button-green button-block button-light"
                          @click="addNewCommentToAnnotationThread(annotation)"
                          data-cy="saveCommentBtn"
                      >
                        {{ getLabel('review_screen_labels', 'save_comment_btn') }}
                      </button>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Free User Promo -->
            <div class="flex-grow-1"></div>
            <proof-view-free-user-promo v-if="!isCompareMode && sidebarVisible && curAccount.isFreeSku" :avatar="curLogo" :cur-account="curAccount" :proof-view-version="proofViewVersion"/>
          </div>
        </b-collapse>
      </div>

      <v-tour
          v-if="isReviewMode"
          v-show="curFile"
          name="review-screen"
          :steps="tourSteps"
          :callbacks="tourCallbacks"
          :options="{labels: tourLabels}"
      ></v-tour>
    </div>
  </div>
</template>

<style scoped>

.style-chooser .vs__dropdown-toggle {
  border: none;
}

.draw-line {
  background-color: black;
  width: 15px;
  position: absolute;
}

.draw-option {
  display: flex;
  align-items: center;
}

.draw-line-option-label {
  padding-left: 25px;
}

.live-proof-layer {
  background-color: transparent !important;
  position: absolute;
  display: block;
  z-index: 100;
}

.resolved-comment {
  background: #dfffec !important;
  opacity: 0.4 !important;
}

.resolved-slider {
  margin-top: 20px;
}

.comment-date {
  font-size: 14px;
  color: rgba(68, 68, 68, 0.75);
  line-height: 25px;
}

.comment-text {
  overflow-wrap: break-word;
  word-wrap: break-word; /* Compatibility for older browsers */
  font-weight: normal;
  max-width: 100% !important;
  hyphens: auto; /* Adds hyphens where applicable */
}

.comment-thread-drop-down-caret:hover {
  opacity: 0.7;
}

.comment-thread-drop-down-caret {
  cursor: pointer;
}

.rotate0 {
  transform: rotate(0deg);
  transform-origin: center center;
}

.rotate90 {
  transform: rotate(90deg) translateY(-100%);
  transform-origin: top left;
}

.rotate180 {
  transform: rotate(180deg);
  transform-origin: center center;
}

.rotate270 {
  transform: translateX(-100%) rotate(-90deg);
  transform-origin: top right;
}

.item-select {
  display: inline-block;
  line-height: 60px;
  font-weight: bold;
  transition: all 0.5s ease;
  cursor: pointer;
}

.item-select.disabled {
  opacity: 0.5;
}

.item-select:hover,
.menu-option:hover span,
.sidebar-collapse:hover {
  opacity: 0.8;
  transition: all 0.5s ease;
}

.option-section {
  border-left: 1px solid #e9ebed;
  height: 60px;
  line-height: 60px;
}

.option-section-borderless {
  height: 60px;
  line-height: 60px;
}

.zoom-section .item-select {
  width: 30%;
}

.review-sidebar {
  overflow-y: scroll;
}

.collapsing .review-sidebar-content {
  transition: all 0.1s;
  opacity: 0 !important;
}

.proof-options {
  z-index: 10004;
  margin-top: 15px;
  margin-bottom: 15px;
}

.sidebar-collapse {
  font-weight: bold;
  opacity: 0.5;
  cursor: pointer;
}

.review-sidebar-content {
  font-weight: bold;
  padding-top: 10px;
}

.review-item {
  background: #ffffff;
  padding: 20px;
  border-radius: 2.5px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.review-item p {
  margin-bottom: 0;
}

.review-item:hover {
  box-shadow: 0 3px 20px 0 rgba(84, 110, 122, 0.1);
  transform: translateY(-1px);
  transition: all 0.5s ease;
}

.review-item.active {
  background: #dee3ec;
}

.comment-bubble {
  border-radius: 50%;
  background-color: #aec2b9;
  width: 25px;
  height: 25px;
  font-size: 12px !important;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  line-height: 25px;
  display: inline-block;
  vertical-align: top;
}

.approver-list-item h4 {
  line-height: 25px;
  display: inline-block;
  vertical-align: top;
}

.approver-color {
  height: 25px;
  width: 25px;
  border-radius: 8px;
  background-color: #000000;
  color: #ffffff;
  display: inline-block;
  margin-right: 5px;
  text-align: center;
  line-height: 25px;
  font-size: 10px;
  font-weight: 900;
}

.loading-img {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  position: absolute;
  z-index: 2;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
}

.draw-wrapper {
  border: 1px solid #6A8B94;
  border-radius: 10px;
}

.draw-colorpicker {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-picker-modal {
  position: absolute;
  z-index: 3000;
  left: 0px;
  top: 40px;
}

.border-draw-right {
  border-right: 1px solid #6A8B94;
}

.circle-style {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Comment Highlight*/

.highlight-comment {
  -webkit-animation-name: fade;
  -moz-animation-name: fade;
  -ms-animation-name: fade;
  -o-animation-name: fade;
  animation-name: fade;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}

.loading-text {
  font-weight: bold;
  text-align: center;
  background: #ffffff;
  padding: 10px;
  width: 140px;
  border-radius: 4px;
  -webkit-box-shadow: 0 -3px 10px 0 rgba(106, 139, 148, 0.2);
  box-shadow: 0 -3px 10px 0 rgba(106, 139, 148, 0.2);
  position: absolute;
  z-index: 2;
  left: calc(50% - 68px);
  top: calc(50% - -69px);
}

.dropdown-menu-left-far-right {
  right: auto;
  left: 0;
}

.dropdown-menu-left-far-right >>> ul {
  right: auto;
  left: 0;
  margin-left: 0px !important;
}

.review-item hr {
  margin: 20px 0;
}

/*added rotate icon class*/
.rotate-icon {
  height: 19px;
  cursor: pointer;
}

.comment-options img {
  height: 18px;
  margin-left: 5px;
}

.comment-options img:hover {
  pointer: cursor;
  opacity: 0.7;
}

.comment-text >>> img {
  max-width: 100% !important;
}

.comment-text >>> a {
  text-decoration: underline;
  color: blue;
}

.comment-thread-file-link img {
  height: 15px;
  margin-right: 5px;
}

hr {
  height: 5px;
  width: 100%;
  background: #dee3ec;
  border-top: none;
  border-radius: 2px;
}

.container-fluid {
  max-width: 100%;
}

.review-navbar {
  margin-bottom: 0;
  width: 100%;
  z-index: 888;
  background: #ffffff;
  border-bottom: 1px solid #e9ebed;
  font-size: 14px;
}

.review-nav img {
  max-height: 40px;
  max-width: 200px;
}

.menu-option {
  display: inline-block;
  height: 40px;
}

.menu-option img {
  width: 14px;
}

.menu-option .menu-label {
  line-height: 40px;
}

.menu-option a {
  cursor: pointer;
}

.item-select img {
  height: 14px;
}

body {
  min-height: 100% !important;
  margin: 0;
}

.review-container {
  width: 100%;
  background: #dee3ec;
  padding-top: 10px;
  overflow: auto;
}

.proof-options img {
  width: 20px;
  margin-right: 15px;
}

@media screen and (max-width: 1200px) {
  .drop-1200 {
    display: none !important;
  }
}

@media screen and (max-width: 1100px) {
  .menu-option {
    font-size: 12px;
  }
}

@media screen and (max-width: 991px) {
  .drop-991 {
    display: none !important;
  }

  .show-991 {
    display: inline-block !important;
  }

  .review-navbar {
    height: auto;
  }

  .menu-option {
    text-align: center;
    display: inline-block;
    overflow: hidden;
  }

  .border-991 {
    border-bottom: 1px solid #e9ebed;
  }
}

@media (max-width: 767px) {
  .review-navbar {
    position: relative !important;
  }
}

@media screen and (max-width: 736px) {
  .menu-option {
    font-size: 10px;
  }

  .button-long .menu-label {
    margin-top: 10px;
    line-height: 10px;
    display: block;
  }
}

.item-page-picker span {
  border-bottom: 1px solid #000000;
  min-width: 20px;
}

@-webkit-keyframes fade {
  from {
    background-color: #ffeb3bc4;
  }
  to {
    background-color: transparent;
  }
}

@-moz-keyframes fade {
  from {
    background-color: #ffeb3bc4;
  }
  to {
    background-color: transparent;
  }
}

@-ms-keyframes fade {
  from {
    background-color: #ffeb3bc4;
  }
  to {
    background-color: transparent;
  }
}

@-o-keyframes fade {
  from {
    background-color: #ffeb3bc4;
  }
  to {
    background-color: transparent;
  }
}

@keyframesfade {
  from {
    background-color: #ffeb3bc4;
  }

  to {
    background-color: transparent;
  }
}
/* END OF COMMENT HIGHTLIGHT*/

@media (max-width: 991px) {
  .product-tour-button {
    display: none;
  }
}

.mr-15 {
  margin-right: 15px;
}

.dropdown-item {
  cursor: pointer;
  display: flex;
  justify-content: center;
}


</style>
