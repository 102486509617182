<script>
import ProofViewCommentEditor from "./proof-view-comment-editor";
import commentActions from "./comment-actions";


export default {
  name: "proof-view-comment",
  props: [
    "label", "comment", "isActive", "curFile", "curUsersTaggable", "commentFileApi",
    "isLiveProofView", "liveProofActiveUrl", "liveProofSizeSelected",
    "curAnnotationType", "usersColor", "curUser", "curApprover", "disabled", "allowViewAllComments"
  ],
  components: {
    ProofViewCommentEditor,
    commentActions
  },
  data() {
    return {
      showReplies: false,
      editing: false,
      activeComment: null
    };
  },
  methods: {
    isOwner: function (comment) {
      return comment.userId === _.get(this.curUser, 'id', -1) ||
          comment.approverId === _.get(this.curApprover, 'id', -1);
    },
    shouldShowAllComments: function (comment){
      return comment.userId || comment.approverId === _.get(this.curApprover, 'id', -1);
    },
    saveComment: function (comment, newText) {
      this.$emit('on-save-comment', this.comment, newText);
      console.log('saveComment', comment);
    },
    editComment: function (comment) {
      this.activeComment = comment;
      this.editing = true;
    },
    deleteComment: function (comment) {
      this.$emit('on-delete-comment', this.comment, comment);
    },
    updateComment: function (comment, newText) {
      const updatedComment = _.get(this.comment, 'proofFileAnnotationComments', []).find(c => c.id === comment.id);
      updatedComment.text = newText;
      this.$emit('on-update-comment', updatedComment, newText);
      this.editing = false;
      this.activeComment = null;
    },
    setActiveComment: function () {
      this.$emit('on-set-active-comment', this.comment);
    },
    onEditorShow: function () {
      // debugger
      this.$emit('on-editor-show', this.comment.id);
    },
    onEditorHide: function () {
      this.$emit('on-editor-show', 0);
    },
    viewReplies: function (emitActive = true) {
      this.showReplies = true;
      if (emitActive) {
        this.setActiveComment();
      }
    },
    getColor: function (c) {
      const id = c.userId || c.approverId;
      return this.usersColor[id];
    },
    getInitialText: function () {
      if (this.isLiveProofView) {
        return `<p><strong><a href='${this.liveProofActiveUrl}' style='color:black'>${this.liveProofActiveUrl}</a></strong></p><p data-cy='size_selected_text'>${this.liveProofSizeSelected ? this.liveProofSizeSelected.label() : ""}</p><br>`
      }
      return '';
    },
    getLabel: function (section, key) {
      return this.$A.LangService.getLabel(section, key);
    },
    updateAnnotationResolvedStatus: function (comment) {
      let s = this;
      setTimeout(function () {
        s.$A.AnnotationService.UpdateAnnotationResolvedStatus(
            comment.id,
            comment.resolved
        );
      }, 1);
    },

  },
  computed: {
    mainComment: function () {
      return _.get(this.comment, ['proofFileAnnotationComments', 0], {
        ownerInitials: this.comment.ownerInitials,
        ownerName: this.comment.ownerName,
        createdAt: this.comment.createdAt,
        approverId: this.comment.approverId,
        userId: this.comment.userId
      });
    },
    replies: function () {
      let allComments = _.get(this.comment, 'proofFileAnnotationComments', []);
      return allComments.length > 1 ? allComments.slice(1) : [];
    },
    repliesInitials: function () {
      const replyInitials = {};
      const uniqueCommenters = new Set();

      this.replies.forEach(r => {
        replyInitials[r.ownerInitials] = replyInitials[r.ownerInitials] || {color: this.getColor(r), count: 0};
        replyInitials[r.ownerInitials].count++;
        uniqueCommenters.add(r.ownerInitials);
      });

      const allResults = Object.keys(replyInitials).map(key => {
        const commenterCount = replyInitials[key].count;
        const ownerName = this.replies.find(r => r.ownerInitials === key)?.ownerName || '';

        return {
          ownerInitials: key,
          color: replyInitials[key].color,
          ownerName: ownerName,
          commenterCount: commenterCount
        };
      });

      if (allResults.length > 5) {
        allResults[5].textColor = 'black';
      }

      const visibleResults = allResults.slice(0, 5);
      const remainingResults = allResults.length > 5 ? allResults.slice(5) : [];

      const displayPlusN = uniqueCommenters.size > 5;

      return {
        visible: visibleResults,
        remaining: displayPlusN ? remainingResults.concat({
          ownerInitials: `+${uniqueCommenters.size - 5}`,
          color: 'white'
        }) : remainingResults
      };
    },
    shouldDisplayRemainingSection() {
      return this.repliesInitials.remaining.length > 0 && this.replies.length > 5;
    },
    remainingNamesTooltip() {
      return this.replies.slice(5).map(re => re.ownerName);
    },
    editingComment: function () {
      return this.activeComment || this.mainComment;
    },
    addingReply: function () {
      return this.isActive && (this.showReplies || this.comment.proofFileAnnotationComments.length === 0);
    },
  },
  watch: {
    colorPickerColor: function (newColor) {
      this.$emit('on-draw-color-change', newColor);
    },
    drawToolLineWidth: function (newWidth) {
      this.$emit('on-draw-line-width-change', newWidth);
    }
  }
}
</script>

<template>
  <div v-if="allowViewAllComments || curUser || shouldShowAllComments(mainComment)"
       :id="`${label}-comment-${comment.id}`"
       class="comment px-0"
       :style="comment.resolved ? { 'background-color': 'rgba(240, 249, 244, 1)' } : {}"
  >
    <div class="p-4" :class="{ 'pb-0' : !replies.length }">
      <div @click="setActiveComment"
           class="d-flex align-items-center gap-10 mb-2"
      >
        <div :style="{'background-color': getColor(mainComment)}"
             class="avatar-big fs-12 position-relative fw-semibold text-white rounded-circle d-flex align-items-center justify-content-center position-relative"
             style="overflow: hidden;"
        >
          <img v-if="comment.resolved"
               style="height: 28px !important; width: 28px !important; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
               src="/img/comment-resolved-icon.svg"
          />
          {{ !comment.resolved ? comment.index : '' }}
        </div>
        <div class="flex-fill">
          <h6 class="text-gray-600 mb-0 font-weight-normal text-break">{{ mainComment.ownerName }}</h6>
          <p class="text-gray-500 fs-12 mb-0 mt-1" style="color:#909092;">{{
              $A.FormatDate(mainComment.createdAt, true)
            }}</p>
        </div>

        <!-- comment actions -->
        <comment-actions v-if="!disabled && isOwner(mainComment) && comment.proofFileAnnotationComments.length"
                         @on-edit-comment="editComment(mainComment)" @on-delete-comment="deleteComment(mainComment)"/>
      </div>
      <div v-show="!editing" class="comment-text fs-14 mb-3" v-html="mainComment.text"></div>

      <!-- editing main comment -->
      <proof-view-comment-editor
          :key="'comment-editor-' + mainComment.id"
          :comment="mainComment"
          v-if="!disabled && editing && editingComment === mainComment"
          :cur-file="curFile"
          :save-label="getLabel('review_screen_labels','update_comment_btn') || 'Update Comment'"
          :cur-annotation-type="curAnnotationType"
          :cur-users-taggable="curUsersTaggable"
          :comment-file-api="commentFileApi"
          v-on="{ ...$listeners, 'on-save-comment' : updateComment, 'on-show' : onEditorShow, 'on-hide' : onEditorHide }"
      />
      <div>
        <!--resolved comments -->
        <div class="pb-2"
             v-show="curUser !== null && !disabled && comment.proofFileAnnotationComments.length > 0"
        >
          <div class="px-3 py-12 bg-gray-50 rounded-4">
            <div class="d-flex align-items-center ">
              <label class="form-check mb-0 p-0 fs-14 text-gray-900 form-switch d-flex gap-2 align-items-center">
                <input class="form-check-input d-none"
                       type="checkbox"
                       role="switch"
                       id="comments-switch"
                       v-model="comment.resolved"
                       v-on:change="updateAnnotationResolvedStatus(comment)"
                >
                <span></span>
                <span class="text-normal font-weight-normal">{{comment.resolved ? getLabel('review_screen_labels', 'comment_resolved') : getLabel('review_screen_labels', 'comment_not_resolved') }}</span>
              </label>
            </div>
          </div>
        </div>
        <!--  ends resolved comments -->
        <div v-show="!disabled && !replies.length && !editing && !addingReply">
          <div class="d-flex align-items-center">
            <a @click="viewReplies" class="text-success-500 fw-semibold text-decoration-none fs-12"
               style="cursor: pointer"> {{ getLabel('review_screen_labels', 'comment_reply') }}</a>
          </div>
        </div>
        <!--  replies initials   -->
        <div v-show="!showReplies">
          <div class="d-flex align-items-center">
            <a v-show="replies.length" @click="viewReplies"
               class="text-success-500 fw-semibold text-decoration-none fs-12"
               style="cursor: pointer">{{ getLabel('review_screen_labels', 'view_comment_replies') }}</a>
            <!-- Replies initials and avatar group -->
            <div v-show="replies.length" class="avatar-group d-flex ml-auto">
              <div v-for="(ri, index) in repliesInitials.visible" :key="index"
                   :id="'reply-avatar'+ri.id"
                   :title="ri.ownerName"
                   :style="{ 'background-color': ri.color, 'color': index === 5 ? 'black' : 'white' }"
                   class="avatar fs-12 position-relative fw-semibold rounded-circle d-flex align-items-center justify-content-center">
                {{ ri.ownerInitials }}
                <b-tooltip :target="'reply-avatar'+ri.id" triggers="hover">{{ ri.ownerName }}</b-tooltip>
              </div>
            </div>
            <div v-if="shouldDisplayRemainingSection" id="remaining-names"
                 class="avatar fs-12 position-relative fw-semibold rounded-circle d-flex align-items-center justify-content-center">
              +{{ (repliesInitials.remaining.length) - 1 }}
              <b-tooltip target="remaining-names" triggers="hover">
                <ul style="text-align: left; padding: 0; margin: 0;">
                  <li v-for="(ri, index) in repliesInitials.remaining.slice(0, -1)" :key="index">
                    {{ ri.ownerName }}
                  </li>
                </ul>
              </b-tooltip>
            </div>
          </div>
        </div>
        <!--  replies  -->
        <div v-show="showReplies && replies.length" class="showReplies">
          <div class="d-flex gap-2 align-items-center mb-3">
            <a @click.prevent="showReplies = false" class="text-success-500 fw-semibold text-decoration-none fs-12"
               style="cursor: pointer">
              {{ getLabel('review_screen_labels', 'view_less_comment_replies') }} </a>
            <div class="flex flex-fill border-top"></div>
          </div>
          <div class="d-flex flex-column gap-3">
            <div v-for="reply of replies" class="p-12 bg-gray-50 border border-gray-100 rounded-3">
              <div class="d-flex align-items-center gap-10 mb-2">
                <div :style="{'background-color': getColor(reply)}"
                     class="avatar-big avatar-green-dark fs-12 position-relative fw-semibold text-white rounded-circle d-flex align-items-center justify-content-center position-relative">
                  {{ reply.ownerInitials }}
                </div>
                <div class="flex-fill">
                  <h6 class="text-gray-600 mb-0">{{ reply.ownerName }}</h6>
                  <p class="text-gray-500 fs-12 mb-0">{{ $A.FormatDate(reply.createdAt, true) }}</p>
                </div>
                <!-- comment actions -->
                <comment-actions v-if="isOwner(reply)" @on-edit-comment="editComment(reply)"
                                 @on-delete-comment="deleteComment(reply)"/>
              </div>
              <div class="comment-text fs-14" v-html="reply.text"></div>
              <!-- editing a reply -->
              <proof-view-comment-editor
                  :comment="reply"
                  v-show="editing && editingComment === reply"
                  :cur-file="curFile"
                  :save-label="getLabel('review_screen_labels','update_comment_btn') || 'Update Comment'"
                  :cur-annotation-type="curAnnotationType"
                  :cur-users-taggable="curUsersTaggable"
                  :comment-file-api="commentFileApi"
                  @on-save-comment="updateComment"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- create a comment or a reply-->
      <proof-view-comment-editor
          class="pt-3"
          ref="comment_editor"
          v-if="!disabled && addingReply"
          :comment="{text:getInitialText()}"
          :cur-file="curFile"
          :text="getInitialText()"
          :save-label="getLabel('review_screen_labels','save_comment_btn')"
          :cur-annotation-type="curAnnotationType"
          :cur-users-taggable="curUsersTaggable"
          :comment-file-api="commentFileApi"
          v-on="{ ...$listeners, 'on-save-comment' : saveComment, 'on-show' : onEditorShow, 'on-hide' : onEditorHide }"
      />
    </div>
  </div>
</template>

<style scoped>
.draw-wrapper {
  border: 1px solid #6A8B94;
  border-radius: 10px;
}

.draw-colorpicker {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-picker-modal {
  position: absolute;
  z-index: 3000;
  left: 0px;
  top: 40px;
}

.border-draw-right {
  border-right: 1px solid #6A8B94;
}

.circle-style {
  display: flex;
  justify-content: center;
  align-items: center;
}

.comment-text {
  overflow-wrap: break-word; /* Ensures text wraps within the container */
  word-wrap: break-word; /* Compatibility for older browsers */
  hyphens: auto; /* Adds hyphens where applicable */
}

.comment-text a,
.comment-text a:hover,
.comment-text a:focus {
  color: #2B6CB0 !important;
}

.comment-text img {
  max-width: 100%;
}

</style>
