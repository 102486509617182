<script>
import Vue from 'vue';
import ProofViewNewAnnotations from "./proof-view-new-annotations";
import ProofViewZoomTools from "./proof-view-zoom-tools";
import ProofViewPagination from "./proof-view-pagination";
import ProofViewLiveProofSize from "./proof-view-live-proof-size";
import ProofViewLiveProofComments from "./proof-view-live-proof-comments";

export default {
  name: "proof-view-tools",
  components: {
    ProofViewLiveProofComments,
    ProofViewLiveProofSize,
    ProofViewNewAnnotations,
    ProofViewZoomTools,
    ProofViewPagination,
  },
  props: [
    "curFile", "isMobile", "curAnnotationType", "setCurAnnotationType",
    "curZoom", "curZoomMode", "updateZoom", "rotate", "toggleZoomMode",
    "GoToNextPage", "GoToPreviousPage", "GoToPage",
    "isLiveProofView", "liveProofSizeSelected", "liveProofPredefinedSizes", "liveProofCommentsEnabled",
    "mode", "isRecording", "isCompareMode", "showRotateTool", "showZoomTools", "disabled"
  ],
  mounted() {
    this.$emit('height-changed', this.$el.offsetHeight);
  }
}


</script>

<template>
  <div ref="proofTools"
       id="proofViewToolsBar"
       class="py-lg-2 px-lg-3 d-flex flex-column flex-lg-row gap-lg-2 align-items-center bg-ashore-white"
       :style="curFile && curFile.docTypeGroup === 'audio' ? 'height:55px' : ''"
       :class="{'flex-wrap border-end border-gray-100': isCompareMode}"
  >
    <!-- Annotations Tools -->
    <proof-view-new-annotations
        id="review-available-tools"
        :cur-file="curFile"
        :cur-annotation-type="curAnnotationType"
        :is-mobile="isMobile"
        :set-cur-annotation-type="setCurAnnotationType"
        v-if="curFile && curFile.docTypeGroup !== 'audio'"
        :class="{'col-12 w-100 justify-content-center': isCompareMode}"
    />
    <proof-view-live-proof-size
        :active-file="curFile"
        :mode="mode"
        :live-proof-size-selected="liveProofSizeSelected"
        :live-proof-predefined-sizes="liveProofPredefinedSizes"
        v-on="$listeners"
        v-if="isLiveProofView"
        :class="{'col-12 w-100 justify-content-center': isCompareMode}"
    />
    <proof-view-live-proof-comments
        :live-proof-comments-enabled="liveProofCommentsEnabled"
        :is-recording="isRecording"
        v-on="$listeners"
        v-if="isLiveProofView"
        :class="{'col-12': isCompareMode}"
    />
    <!-- Zoom Tools -->
    <proof-view-zoom-tools
        :cur-zoom="curZoom"
        :zoom-mode="curZoomMode"
        :update-zoom="updateZoom"
        :rotate="rotate"
        :toggle-zoom-mode="toggleZoomMode"
        :is-compare="isCompareMode"
        v-if="showZoomTools"
        :class="{'col-12 w-100 justify-content-center': isCompareMode}"
        :show-rotate="showRotateTool"
    />
    <div 
        :class="'col-sm-10 option-section-borderless ' + (isCompareMode ? 'col-lg-8' : 'col-lg-3')"
        v-show="curFile && curFile.docTypeGroup !== 'document' && !isLiveProofView"
    ></div>
    <!-- Pagination -->
    <proof-view-pagination
        v-if="curFile && curFile.docTypeGroup === 'document' && curFile.pdf"
        :num-pages="curFile.pdf.totalPages"
        :cur-page="curFile.pdf.pageToView"
        :go-to-next-page="GoToNextPage"
        :go-to-previous-page="GoToPreviousPage"
        :go-to-page="GoToPage"
        :is-compare="isCompareMode"
        :class="{'col-12 w-100 justify-content-center': isCompareMode}"
    />
  </div>
</template>


<style scoped>
.option-section-borderless {
  height: 26px;
  line-height: 26px;
}

@media (min-width: 992px)
{
  .gap-lg-2 {
    gap: 0.5rem!important;
  }
}
</style>