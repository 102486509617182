<script>
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from "@microsoft/signalr";
import approverResponseModal from "../components/review/modals/modal-approver-response";
import Vue from "vue";
import VueRandomColor from "vue-randomcolor";
// import pdf from "../vendor/vue-pdf";
import approverRegistrationModal from "../components/review/modals/approverRegistrationModal";
import navigationBeforeDecisionModal from "../components/review/modals/navigationBeforeDecisionModal";
import ProofView from "../components/review/proofView.v1";
import { PROOF_VIEW_MODES } from "../utils/constants";

const { PROOF_OWNER_MODE, COMMENTER_MODE, APPROVER_MODE } = PROOF_VIEW_MODES;
const ViewMode = {
  Review: 1,
  Compare: 2,
}
Vue.use(VueRandomColor);
Vue.mixin({
  // beforeRouteLeave (to, from , next) {
  //   // const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
  //   // if (answer) {
  //   //   next()
  //   // } else {
  //   //   next(false)
  //   // }
  // }
})
export default {
  components: {
    ProofView,
    approverResponseModal,
    navigationBeforeDecisionModal,
    approverRegistrationModal,
  },
  props: {
    publicId: {
      required: true,
      type: String,
    },
    approverPublicId: {
      type: String,
    },
    curAccount: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      curFile: null,
      curProof: null,
      curDisplayLang: "En",
      curLang: "en-us",
      accessDeniedMessage: "",
      accessDenied: true,
      curUser: null,
      curApprover: null,
      mode: -1, // 1 = Agency Review Screen, 0 = Approver Review Screen, 2 = Reviewer Only Screen
      viewMode: ViewMode.Review,
      initialProof: null,
      approverIpAddress: "",
      alertMessage: "",
      alertType: "success",
      scrollAnimationRootTask: null,
      scrollAnimationTask: null,
      approverFeedback: null,
      realTimeConnection: null,
      topNavBarOffsetHeight: 0,
      isMobile: window.innerWidth < 701,
      APPROVER_MODE,
    };
  },
  async created() {
  },
  async mounted() {
    let self = this;

    window.onresize = this.refreshIsMobile();

    window.addEventListener('keydown', function (e) {
      if (e.key === ' ' && e.target === document.body) {
        e.preventDefault();
      }
    });

    this.ConnectToReviewHub();

    if (document.getElementById("hubspot-messages-iframe-container") !== null) {
      let a = document.getElementById("hubspot-messages-iframe-container");
      a.parentElement.removeChild(a);
    }

    this.$root.$on("alert::user", (message, type, timeout) => {
      if (timeout === undefined) {
        timeout = 5000;
      }
      self.alertMessage = message;
      self.alertType = type;
      setTimeout(function () {
        self.alertMessage = "";
        self.alertType = "success";
      }, timeout);
    });
    this.$root.$on("api::working", () => {
      self.loadingData = true;
    });
    this.$root.$on("api::idle", () => {
      self.loadingData = false;
    });

    this.approverIpAddress = await this.$A.ReviewService.WhoAmI();

    this.loadUserLang();
    await this.$A.LangService.loadLanguage(this.curAccount.id);

    document.title = this.curAccount !== null && this.curAccount.whiteLabelEnabled ? this.curAccount.name : "Ashore App";

    if (!this.$A.viewToViewData) await this.LoadViewData();

    if (this.$A.viewToViewData !== null && this.$A.viewToViewData.proof !== undefined && this.approverPublicId === undefined) {
      this.accessDenied = false;
      this.mode = PROOF_OWNER_MODE;
      this.curUser = this.$A.Auth;
      const authToken = _.get(this, '$A.Auth.apiToken.token', "");
      await this.setReviewProof({ proofId: this.$A.viewToViewData.proof.id });
      await this.RegisterRealTimeConnection(
          authToken,
          this.publicId
      );
    } else {
      this.mode = APPROVER_MODE;
      await this.GetApproverIdentity();
    }

    this.loadTopNavBarHeight();
    this.loadCustomStyle();

    window.onresize = () => {
      self.resize();
    }
    window.addEventListener('beforeunload', this.preventNavBeforeDecision);
  },
  methods: {
    LoadViewData: async function () {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      if (this.$A.Auth !== null) {
        let proof = await this.$A.ProofService.GetProofFromPubId(this.publicId);
        this.$A.viewToViewData = {
          proof: proof
        }
        if (params.tl) { // checking to see if the token used was valid and the user was logged in. else act like normal link
          let clean_uri = location.protocol + "//" + location.host + location.pathname;
          window.history.replaceState({}, document.title, clean_uri);
        }
      }
    },
    ConnectToReviewHub: function () {
    },
    RegisterRealTimeConnection: async function (token, publicId, approverPublicId) {
      let self = this;
      if (this.realTimeConnection !== null || !token) {
        return;
      }
      this.realTimeConnection = new HubConnectionBuilder()
          .withUrl(window.$A.Env.apiHost + "/review_hub", {
            transport: HttpTransportType.WebSockets,
            skipNegotiation: false,
          })
          .withAutomaticReconnect([7000])
          .configureLogging(LogLevel.Error)
          .build();
      this.realTimeConnection.on("ReadyForRegistration", async () => {
        try {
          await self.realTimeConnection.invoke(
              "RegisterReviewerConnection",
              token,
              publicId,
              "reviewPage"
          );
          if (approverPublicId)
            await self.realTimeConnection.invoke(
                "LogLocalIdentity",
                JSON.stringify(window.$A.LoadDataCache(`approver_keychain_${publicId}_${approverPublicId}`, true))
            );
        } catch (err) {
          console.error(err);
        }
      });
      this.realTimeConnection.on("Registered", async () => {
      });
      this.realTimeConnection.start();
    },
    GetApproverIdentity: async function () {
      const curApproverPublicId = this.approverPublicId || this.$A.LoadDataCache(`approver_id_${this.publicId}`, true);
      this.$A.ReviewService.TryToGetApproverIdentity(
          this.publicId,
          curApproverPublicId
      ).then(this.handleApproverRegistered)
    },
    handleApproverRegistered: async function (approverIdentity) {
      if (approverIdentity !== null && approverIdentity.error !== undefined) {
        this.accessDeniedMessage = approverIdentity.error;
        this.accessDenied = true;
        return;
      }
      if (approverIdentity === "accessDenied") {
        this.accessDenied = true;
        this.accessDeniedMessage = "Access Denied";
        return;
      } else {
        this.accessDenied = false;
      }

      if (approverIdentity === null) {
        this.$refs.approverRegistrationModal.open();
        return;
      }

      if (approverIdentity.workflowStageApproverStatus !== undefined) {
        // load approver feedback result if the stage is the same and the approver did have feedback

        if (approverIdentity.approverType == 2) {
          this.mode = COMMENTER_MODE;
        }

        let statusCode = approverIdentity.workflowStageApproverStatus;

        if (statusCode <= 2) {
          this.approverFeedback = null;
        }
        if (statusCode > 2) {
          this.approverFeedback = {
            approvalChoice: statusCode === 3 ? 0 : statusCode === 4 ? 1 : 2,
            feedBackText: "",
            filesApprovalStatus: [],
          };
        }
      }

      this.$A.ReviewService.SetLoggedInApprover(approverIdentity);
      this.$A.Auth = {account: this.curAccount};
      this.curApprover = approverIdentity;
      await this.LoadViewData();
      await this.setReviewProof({ proofId: this.$A.viewToViewData.proof.id });

      this.$A.SaveDataCache(`approver_id_${this.publicId}`, approverIdentity.publicId , 90, true);

      await this.RegisterRealTimeConnection(
          approverIdentity.token,
          this.publicId,
          approverIdentity.publicId
      );
    },
    handleApproverResponse: async function (approverResponse) {
      // console.log(approverResponse);
      this.approverFeedback = approverResponse;
      let status = approverResponse.approvalChoice;

      // approve indv. files then override
      // > 1 ApprovedWithChanges  => ApprovedWithChanges
      // > 1 not approved => not approved
      if (
          approverResponse.filesApprovalStatus !== undefined &&
          approverResponse.filesApprovalStatus.length > 0
      ) {
        let indvFiles = approverResponse.filesApprovalStatus;

        let approvedWithChangesCounter = 0;
        let notApprovedCounter = 0;

        for (let i = 0; i < indvFiles.length; i++) {
          if (indvFiles[i].approvedWithChanges) {
            approvedWithChangesCounter++;
          }
          if (!indvFiles[i].approved && !indvFiles[i].approvedWithChanges) {
            notApprovedCounter++;
          }
        }

        // map to server side ids (server: client modal mode)
        // 5=2 ApprovedWithChanges
        // 4=1 Approved
        // 3=0 NotApproved

        if (notApprovedCounter > 0) {
          approverResponse.approvalStatus = 3;
        } else if (approvedWithChangesCounter > 0) {
          approverResponse.approvalStatus = 5;
        } else {
          approverResponse.approvalStatus = 4;
        }
      } else {
        // map to server side ids (server: client modal mode)
        // 5=2 ApprovedWithChanges
        // 4=1 Approved
        // 3=0 NotApproved
        approverResponse.approvalStatus =
            status === 0 ? 3 : status === 1 ? 4 : 5;
      }

      window.scrollTo(0, 0);
      let response = await this.$A.ReviewService.ProcessApproverDecision(
          approverResponse
      );
      if (response && response.success) {
        this.$A.AlertUser(
            `${this.getLabel('approver_decision_modal_labels', 'approver_confirm_alert')}`,
            "success",
            10000
        );
      } else {
        this.$A.AlertUser(
            `${this.getLabel('approver_decision_modal_labels', 'approver_error_alert')}`,
            "warning",
            10000
        );
      }
    },
    preventNavBeforeDecision: function (e) {
      if (this.approverFeedback === null && this.mode === APPROVER_MODE) {
        this.$refs.navigationBeforeDecisionModal.open();
        e.preventDefault();
        e.returnValue = new Date();
        return new Date();
      }
    },
    setReviewProof: async function ({ proofId, proofVersion = 0, proof }) {
      let self = this;
      let interval = setInterval(async function () {
        if (self.$refs.proofView) {
          clearInterval(interval);
          self.$refs.proofView.proof = {
            id: proof ? proof.id : proofId
          };
          const curProofVersion = proof ? proof.currentVersion.id : proofVersion;
          await self.$refs.proofView.viewProofVersion(curProofVersion, proof);
        }
      }, 50)
    },
    setCompareProof: async function (proofId, proofVersion = 0) {
      let self = this;
      let interval = setInterval(async function () {
        if (self.$refs.compareProofView) {
          clearInterval(interval);
          self.$refs.compareProofView.proof = {
            id: proofId
          };
          await self.$refs.compareProofView.viewProofVersion(proofVersion);
        }
      }, 50)
    },
    switchToCompareViewMode: async function () {
      this.viewMode = ViewMode.Compare;
      this.$refs.proofView.updateZoom(1);
      let proofVersion = this.curProof.versionIds[this.curProof.versionIds.length - 2].proofVersionId;
      await this.setCompareProof(this.$refs.proofView.proof.id, proofVersion);
    },
    switchToReviewViewMode: function () {
      this.viewMode = ViewMode.Review;
    },
    setCurFile(file) {
      this.curFile = file;
    },
    setCurProof(proof) {
      this.curProof = proof;
    },
    loadTopNavBarHeight: function () {
      let self = this;
      let interval = setInterval(function () {
        if (self.$refs.reviewTopNav) {
          clearInterval(interval)
          self.topNavBarOffsetHeight = self.$refs.reviewTopNav.offsetHeight;
        }
      }, 50)
    },
    resize: function () {
      this.$refs.proofView.resize();
      if (this.isCompareMode) this.$refs.compareProofView.resize();
    },
    loadCustomStyle: function () {
      if (this.curAccount && this.curAccount.css) {
        let css = document.createElement("style");
        css.setAttribute("type", "text/css");
        css.innerHTML = this.curAccount.css;
        document.head.appendChild(css);
      }
    },
    getLabel: function (section, key) {
      return this.$A.LangService.getLabel(section, key);
    },
    setUserLang: function (lang) {
      this.$A.LangService.setUserLang(lang);
      this.loadUserLang();
      window.removeEventListener('beforeunload', this.preventNavBeforeDecision);
      window.location.reload();
    },
    loadUserLang: function () {
      this.curLang = this.$A.LangService.getUserLang();
      this.curDisplayLang = (this.curLang === 'es-us') ? 'Es' : 'En';
    },
    refreshIsMobile: function () {
      this.isMobile = window.innerWidth < 701;
    }
  },
  computed: {
    showApprovedButton: function () {
      if (this.curProof === null) {
        return false;
      }
      if (this.allowApproveIndividualFiles) {
        return false;
      }

      if (!this.curApprover) return false;

      if (this.curProof.allowApproveWithChanges) {
        for (let i = 0; i < this.curProof.currentVersion.versionFiles.length; i++) {
          let vf = this.curProof.currentVersion.versionFiles[i];
          if (vf.proofFileAnnotations && vf.proofFileAnnotations.length > 0) {
            let annotationsByCurApprover = vf.proofFileAnnotations.filter(annotation => annotation.approverId === this.curApprover.id && annotation.proofFileAnnotationComments.length > 0);
            return annotationsByCurApprover.length === 0;
          }
        }
      }
      return true;
    },
    commentFileApi: function () {
      if (
          this.curProof &&
          this.curProof !== null &&
          this.curProof !== undefined &&
          this.curProof !== null
      ) {
        let apiUrl = "/annotation/comment-file/" + this.curProof.publicId;
        if (this.curUser !== null) {
          apiUrl += "?u=" + this.curUser.apiToken.token;
        }
        if (this.curApprover !== null) {
          apiUrl += "?a=" + this.curApprover.token;
        }
        return apiUrl;
      }
      return "";
    },
    curUnixTime: function () {
      return new Date().getTime() / 1000;
    },
    allowApproveIndividualFiles: function () {
      return this.curProof !== null &&
      this.curProof.allowApproveIndividualFiles !== undefined
          ? this.curProof.allowApproveIndividualFiles
          : false;
    },
    curLogo: function () {
      return this.curAccount !== null &&
      this.curAccount.whiteLabelEnabled &&
      this.curAccount.whiteLabelLogoUri !== "" &&
      this.curAccount.whiteLabelLogoUri !== null
          ? this.curAccount.whiteLabelLogoUri
          : "https://high-seas.s3.amazonaws.com/email/logo.svg";
    },
    allowDownloads: function () {
      return this.mode === PROOF_OWNER_MODE || _.get(this, 'curProof.allowDownload', false);
    },
    isReviewMode: function () {
      return this.viewMode === ViewMode.Review;
    },
    isCompareMode: function () {
      return this.viewMode === ViewMode.Compare;
    },
  },
  watch: {},
};
</script>

<template>
  <div style="overflow-x: hidden;" data-cy="proofViewPage">
    <b-alert
        :variant="alertType"
        id="nav-alert"
        class="nav-alert"
        :show="alertMessage.length > 0"
        dismissible
    ><span v-html="alertMessage"></span
    ></b-alert>

    <!-- Navigation -->
    <div
        v-if="!accessDenied"
        class="review-navbar review-top-nav"
        id="reviewTopNav"
        ref="reviewTopNav"
    >
      <div class="review-padding">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-3 review-nav">
              <a
                  target="_blank"
                  v-if="curAccount !== null"
                  :href="curAccount == null || curAccount.whiteLabelEnabled === false ? 'https://ashoreapp.com' : '#'
                "
              >
                <img class="review-logo mb-2" :src="curLogo"/>
              </a>
            </div>
            <div class="col-lg-9">
              <div class="float-right-992 d-flex justify-content-around mobile-view">
                <b-spinner
                    type="grow"
                    label="Spinning"
                    class="loading_data_spinner"
                    v-show="(curFile&&(!curFile.loaded || curFile.converting))"
                ></b-spinner>

                <div class="menu-option drop-991 mr-15" v-if="isReviewMode">
                  <span class="menu-label" style="font-weight: bold"
                  >V{{
                      this.curProof &&
                      this.curProof.currentVersion
                          ? this.curProof.currentVersion.versionNumber : ""
                    }}</span
                  >
                </div>
                <div class="menu-option drop-991 mr-15 mobile-btn-view"
                     v-if="isReviewMode">
                  <a
                      class="menu-label"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                  >
                    <span class="dropdown-text">{{ getLabel('review_screen_labels', 'versions_dropdown') }}</span>
                    <img
                        class="ml-1"
                        src="/img/caret-dark.svg"
                    />
                  </a>
                  <div
                      class="dropdown-menu dropdown-menu-left-far-right"
                      aria-labelledby="version_dropdown"
                  >
                    <ul class="versions-menu" :if="this.$refs.proofView">
                      <li class="dropdown-item"
                          v-for="version in this.curProof === null ? [] : this.curProof.versionIds"
                          @click="$refs.proofView.viewProofVersion(version.proofVersionId)"
                      >
                        <a class="nav-link">{{ getLabel('review_screen_labels', 'versions_dropdown_option') }}
                          #{{ version.versionNumber }}</a>
                      </li>
                      <li
                          class="dropdown-item"
                          v-if="this.$refs.proofView && this.$refs.proofView.versions && this.$refs.proofView.versions.length > 1"
                      >
                        <div class="menu-option button-green button-proof noselect mobile-btn-view"
                             @click="switchToCompareViewMode">
                          <a class="btn-link font-weight-bold">
                            {{ getLabel('review_screen_labels', 'compare_versions') }}
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div :class="isMobile ? 'menu-option drop-991 mr-15 col-md-12': 'menu-option drop-991 mr-15'"
                     v-if="allowDownloads && isReviewMode">
                  <a
                      class="menu-label"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                  >
                    <span data-cy="downloadFile">{{ getLabel('review_screen_labels', 'download_dropdown') }}</span>
                    <img
                        class="ml-1"
                        src="/img/caret-dark.svg"
                    />
                  </a>
                  <div
                      class="dropdown-menu dropdown-menu-left-far-right"
                      aria-labelledby="version_dropdown"
                  >
                    <ul>
                      <li class="dropdown-item ">
                        <a class="nav-link" @click="$refs.proofView.downloadThisFile($event)"
                        >{{ getLabel('review_screen_labels', 'download_single_file') }}</a
                        >
                      </li>
                      <li class="dropdown-item ">
                        <a class="nav-link" @click="$refs.proofView.downloadAllFiles($event)"
                        >{{ getLabel('review_screen_labels', 'download_all_files') }}</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>

                <div id="approval-button-group" v-if="isReviewMode">
                  <div
                      v-show="mode === APPROVER_MODE && showApprovedButton"
                      class="menu-option button-green button-light button-proof noselect mr-15 mobile-btn-view"
                      :style="{
                      opacity:
                        approverFeedback === null &&
                        curProof !== null &&
                        !curProof.archived
                          ? 1
                          : 0.4,
                    }"
                      @click="
                      curProof !== null && curProof.archived
                        ? $A.AlertUser('Proof Is Locked')
                        : $refs.approverResponseModal.open(1, approverFeedback)
                    "
                  >
                    <a class="btn-link font-weight-bold" data-cy="approvedBtn"
                    >{{ getLabel('review_screen_labels', 'approve_btn') }}
                      {{
                        approverFeedback !== null &&
                        approverFeedback.approvalChoice === 1
                            ? "&#10004;"
                            : ""
                      }}</a
                    >
                  </div>
                  <div
                      v-show="
                      mode === APPROVER_MODE &&
                      curProof !== null &&
                      curProof.allowApproveWithChanges &&
                      !allowApproveIndividualFiles
                    "
                      class="menu-option button-black button-light button-proof button-long noselect mr-15 mobile-btn-view"
                      :style="{
                      opacity:
                        approverFeedback === null &&
                        curProof !== null &&
                        !curProof.archived
                          ? 1
                          : 0.4,
                    }"
                      @click="
                      curProof !== null && curProof.archived
                        ? $A.AlertUser('Proof Is Locked')
                        : $refs.approverResponseModal.open(2, approverFeedback)
                    "
                  >
                    <a class="btn-link font-weight-bold"
                    >{{ getLabel('review_screen_labels', 'approve_with_changes_btn') }}
                      {{
                        approverFeedback !== null &&
                        approverFeedback.approvalChoice === 2
                            ? "&#10004;"
                            : ""
                      }}</a
                    >
                  </div>
                  <div
                      v-show="mode === APPROVER_MODE && !allowApproveIndividualFiles"
                      class="menu-option button-red button-light button-proof noselect mobile-btn-view"
                      :style="{
                      opacity:
                        approverFeedback === null &&
                        curProof !== null &&
                        !curProof.archived
                          ? 1
                          : 0.4,
                    }"
                      @click="
                      curProof !== null && curProof.archived
                        ? $A.AlertUser('Proof Is Locked')
                        : $refs.approverResponseModal.open(0, approverFeedback)
                    "
                  >
                    <a class="btn-link font-weight-bold" data-cy="notApprovedBtn"
                    >{{ getLabel('review_screen_labels', 'not_approved_btn') }}
                      {{
                        approverFeedback !== null &&
                        approverFeedback.approvalChoice === 0
                            ? "&#10004;"
                            : ""
                      }}</a
                    >
                  </div>
                  <div
                      v-show="mode === APPROVER_MODE && allowApproveIndividualFiles"
                      class="menu-option button-red button-light button-proof noselect"
                      data-cy="submitDecisionBtn"
                      :style="{
                      opacity:
                        approverFeedback === null &&
                        curProof !== null &&
                        !curProof.archived
                          ? 1
                          : 0.4,
                    }"
                      @click="
                      curProof !== null && curProof.archived
                        ? $A.AlertUser('Proof Is Locked')
                        : $refs.approverResponseModal.open(3, approverFeedback)
                    "
                  >
                    <a class="btn-link font-weight-bold"
                    >{{ getLabel('approver_decision_modal_labels', 'submit_decision_btn') }}
                      {{
                        approverFeedback !== null &&
                        approverFeedback.approvalChoice === 0
                            ? "&#10004;"
                            : ""
                      }}</a
                    >
                  </div>
                  <!-- todo: call this click from from the default proof view component so the tour will work properly -->
                  <img
                      src="/img/icon-help-2.svg"
                      class="product-tour-button"
                      @click="$tours['review-screen'].start()"
                      v-show="this.curUser == null"
                  />
                </div>
                <!-- lang wrapper -->
                <div class="menu-option mr-15 lang-menu lang-menu-no-mobile lang-mobile"
                     v-if="isReviewMode"
                >
                  <a
                      class="menu-label"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                  >
                    <span class="language-toggle rounded-circle border p-1 font-weight-bold"><span
                        class="text-center cur-lang-text">{{ curDisplayLang }}</span></span>
                    <img src="/img/caret-dark.svg" class=" language-toggle-img rounded-circle mt-3 pr-1 pl-1"/>
                  </a>
                  <div
                      class="dropdown-menu dropdown-menu-left-far-right lang-dropdown-options"
                      aria-labelledby="version_dropdown"
                  >
                    <ul class="pl-0">
                      <li
                          class="dropdown-item"
                          @click="setUserLang('en-us')"
                      >
                        <a class="nav-link">English</a>
                      </li>
                      <li
                          class="dropdown-item"
                          @click="setUserLang('es-us')"
                      >
                        <a class="nav-link">Spanish</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- compare mode -->
                <div v-if="isCompareMode">
                  <div class="menu-option red-btn button-light noselect">
                    <a class="align-items-center" @click="switchToReviewViewMode">
                      {{ getLabel('review_screen_labels', 'return_compare_btn') }}
                    </a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Content -->
    <div class="row">
      <!-- Left/Main Review View -->
      <div ref="review-version" :class="isCompareMode ? 'col-lg-6 col-sm-12 pr-0' : 'col'">
        <proof-view label="review"
                    ref="proofView"
                    :mode="mode"
                    :view-mode="viewMode"
                    :cur-user="curUser"
                    :cur-approver="curApprover"
                    :cur-account="curAccount"
                    :cur-lang="curLang"
                    :cur-logo="curLogo"
                    :public-id="publicId"
                    :approver-feedback="approverFeedback"
                    :approver-public-id="approverPublicId"
                    :access-denied="accessDenied"
                    :access-denied-message="accessDeniedMessage"
                    :is-review-mode="isReviewMode"
                    :is-compare-mode="isCompareMode"
                    :top-nav-bar-offset-height="topNavBarOffsetHeight"
                    :comment-file-api="commentFileApi"
                    v-on:proofLoaded="setCurProof($event)"
                    v-on:curFileChanged="setCurFile($event)"
        />
      </div>
      <!-- Right Panel Compare View -->
      <div ref="compare-version" v-if="isCompareMode" class="col-lg-6 col-sm-12 pl-0">
        <proof-view label="compare"
                    ref="compareProofView"
                    :mode="mode"
                    :view-mode="viewMode"
                    :cur-user="curUser"
                    :cur-approver="curApprover"
                    :cur-account="curAccount"
                    :public-id="publicId"
                    :cur-lang="curLang"
                    :approver-feedback="approverFeedback"
                    :approver-public-id="approverPublicId"
                    :access-denied="accessDenied"
                    :access-denied-message="accessDeniedMessage"
                    :is-review-mode="isReviewMode"
                    :is-compare-mode="isCompareMode"
                    :top-nav-bar-offset-height="topNavBarOffsetHeight"
                    :comment-file-api="commentFileApi"
        />
      </div>
    </div>

    <!-- Modals -->
    <navigationBeforeDecisionModal
        ref="navigationBeforeDecisionModal"
    />
    <approverResponseModal
        ref="approverResponseModal"
        v-on:approverResponded="handleApproverResponse($event)"
        :proof="curProof"
        :account="curAccount"
        :commentApiUrl="commentFileApi"
    />
    <approverRegistrationModal
        ref="approverRegistrationModal"
        :public-proof-id="publicId"
        v-on:onapproverregistered="handleApproverRegistered($event)"
    />
  </div>
</template>

<style scoped>

.button-red {
  background-image: none;
  background: #d44d68;
  transition: all 0.5s ease;
}

.button-red:hover {
  background: #9a3146;
  transition: all 0.5s ease;
}

.red-btn {
  height: 36px;
  background: #D44D68;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
  padding: 9px 17px !important;
  color: white !important;
}

.red-btn:hover {
  cursor: pointer;
  opacity: 0.9;
}

ul.versions-menu {
  padding-left: 0px;
  margin-bottom: 0px;
}

#hubspot-messages-iframe-container {
  display: none;
}

.dropdown-menu-left-far-right {
  right: auto;
  left: 0;
}

.dropdown-menu-left-far-right >>> ul {
  right: auto;
  left: 0;
  margin-left: 0px !important;
}

.review-item hr {
  margin: 20px 0;
}

.comment-options img {
  height: 18px;
  margin-left: 5px;
}

.comment-options img:hover {
  pointer: cursor;
  opacity: 0.7;
}

.comment-text >>> img {
  max-width: 100% !important;
}

.comment-text >>> a {
  text-decoration: underline;
  color: blue;
}

.comment-thread-file-link img {
  height: 15px;
  margin-right: 5px;
}

#nav-alert {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  min-height: 40px;
  z-index: 99999999;
}

hr {
  height: 5px;
  width: 100%;
  background: #dee3ec;
  border-top: none;
  border-radius: 2px;
}

.loading_data_spinner {
  margin-top: 0px !important;
  margin-right: 0px !important;
}

.container-fluid {
  max-width: 100%;
}

.review-navbar {
  margin-bottom: 0;
  width: 100%;
  z-index: 888;
  background: #ffffff;
  border-bottom: 1px solid #e9ebed;
  font-size: 14px;
}

.review-padding {
  padding: 10px 0;
}

.review-nav img {
  max-height: 40px;
  max-width: 200px;
}

.menu-option {
  display: inline-block;
  height: 40px;
}

.menu-option img {
  width: 14px;
}

.menu-option .menu-label {
  line-height: 40px;
}

.menu-option .btn-link {
  color: #fff !important;
  font-family: 'Open Sans';
  color: #fff !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-text {
  color: #444 !important;
}

.button-proof {
  border-radius: 2.5px;
  padding: 0 25px;
  font-weight: bold;
}

.item-select img {
  height: 14px;
}

body {
  min-height: 100% !important;
  margin: 0;
  overflow: hidden;
}

.proof-options img {
  width: 20px;
  margin-right: 15px;
}

.lang-selector {
  position: absolute;
  width: 10px;
  height: 10px;
  left: 3px;
  top: 3px;
}

.language-toggle {
  width: 30px;
  height: 30px;
  background: #DEE3EC;
  display: block;
  position: absolute;
  top: 10%;
  color: black;
}

.language-toggle-img {
  width: 13px;
  height: 13px;
  background: #EEF2F5;
  position: relative;
}

.cur-lang-text {
  top: -10px;
  display: inline-block;
  position: relative;
  right: -2px;
}

.lang-dropdown-options {
  z-index: 100000;
}

@media screen and (min-width: 992px) {
  .float-right-992 {
    float: right;
  }

}

@media screen and (min-width: 1100px) {
  .language-toggle-img {
    left: 20px;
  }
}

@media screen and (max-width: 991px) {
  .drop-991 {
    display: none !important;
  }

  .review-navbar {
    height: auto;
  }

  .button-proof {
    padding: 0 5px;
  }

  .menu-option {
    text-align: center;
    display: inline-block;
    overflow: hidden;
  }

  .review-logo {
    margin: 0 auto;
    display: block;
  }

  #approval-button-group {
    /*margin: 15px 30px 0px 0px;*/
  }

}

@media screen and (max-width: 736px) {
  .menu-option {
    font-size: 10px;
  }

  .button-long .menu-label {
    margin-top: 10px;
    display: block;
  }

  .lang-mobile {
    display: none !important;
  }

  .mobile-btn-view {
    width: 100% !important;
  }

  .product-tour-button {
    display: none !important;
  }
}

.item-page-picker span {
  border-bottom: 1px solid #000000;
  min-width: 20px;
}

@-webkit-keyframes fade {
  from {
    background-color: #ffeb3bc4;
  }
  to {
    background-color: transparent;
  }
}

@-moz-keyframes fade {
  from {
    background-color: #ffeb3bc4;
  }
  to {
    background-color: transparent;
  }
}

@-ms-keyframes fade {
  from {
    background-color: #ffeb3bc4;
  }
  to {
    background-color: transparent;
  }
}

@-o-keyframes fade {
  from {
    background-color: #ffeb3bc4;
  }
  to {
    background-color: transparent;
  }
}

@keyframesfade {
  from {
    background-color: #ffeb3bc4;
  }

  to {
    background-color: transparent;
  }
}
/* END OF COMMENT HIGHTLIGHT*/

.product-tour-button {
  display: inline-block;
  height: 40px;
  transition: all 0.5s ease;
}

.product-tour-button:hover {
  opacity: 0.5;
  cursor: pointer;
  transition: all 0.5s ease;
}

@media (min-width: 736px) and (max-width: 992px) {

  .product-tour-button {
    display: none;
  }

  #approval-button-group {
    margin: 1px 0px 0px 0px !important;
  }

  .mobile-view {
    justify-content: center !important;
  }

  .lang-menu-no-mobile {
    margin-left: 15px !important;
  }

}

.mr-15 {
  margin-right: 15px;
}

.dropdown-item {
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.menu-option a {
  cursor: pointer;
  font-family: 'Open Sans';
  color: #000000;
  font-style: normal;
  font-size: 14px;
}

.button-green {
  padding: 9px 17px;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border-radius: 6px;
}

.button-green:hover {
  background: #436148;
  text-decoration: none;
  transition: all .5s ease;
  opacity: 1;
}

.button-black {
  background: #444444;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #fff !important;
  padding: 9px 17px;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
  padding: 9px 17px;
}

.button-black:hover {
  background: #000000;
  text-decoration: none;
  transition: all .5s ease;
  opacity: 1;
}

.button-red {
  background: #d44d68;
  padding: 9px 17px;
  height: 38px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

</style>
