<script>
import proofView from "../components/review/proofView.v2.vue";
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from "@microsoft/signalr";
import approverResponseModal from "../components/review/modals/modal-approver-response";
import Vue from "vue";
import VueRandomColor from "vue-randomcolor";
import approverRegistrationModal from "../components/review/modals/approverRegistrationModal";
import navigationBeforeDecisionModal from "../components/review/modals/navigationBeforeDecisionModal";
import ProofViewCompareNavigation from "../components/review/proof-view-compare-navigation";
import moment from "moment";
import { isEqual } from "lodash";
import { PROOF_VIEW_MODES } from "../utils/constants";

const { PROOF_OWNER_MODE, COMMENTER_MODE, APPROVER_MODE } = PROOF_VIEW_MODES;
const ViewMode = {
  Review: 1,
  Compare: 2,
};
const defaultFileProperties = {
  startedLoading: 0,
  loaded: false,
  zoom: 1,
  zoomMode: 0,
  height: 0,
  zoomHeight: 0,
  rotation: 0,
  width: 0,
  zoomWidth: 0,
  player: {
    curPlayerTime: 0,
  },
  pdf: {
    totalPages: 0,
    curPage: 0,
    pageToView: 1,
  },
  element: null,
  curTimer: "",
};
const CONNECTION_EVENTS = {
    CONNECTED : "Connected",
    DISCONNECTED: "Disconnected",
    RECONNECTED: "Reconnected"
};
const { CONNECTED, DISCONNECTED, RECONNECTED } = CONNECTION_EVENTS;
Vue.use(VueRandomColor);
Vue.mixin({
  // beforeRouteLeave (to, from , next) {
  //   // const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
  //   // if (answer) {
  //   //   next()
  //   // } else {
  //   //   next(false)
  //   // }
  // }
})

export default {
  components: {
    ProofViewCompareNavigation,
    proofView,
    approverResponseModal,
    navigationBeforeDecisionModal,
    approverRegistrationModal,
  },
  props: {
    publicId: {
      required: true,
      type: String,
    },
    approverPublicId: {
      type: String,
    },
    curAccount: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      curFile: null,
      curProof: null,
      curDisplayLang: "En",
      curLang: "en-us",
      accessDeniedMessage: "",
      accessDenied: true,
      curUser: null,
      activeUsers: {},
      activeUsersList: [],
      activeUserInactivityInMinutes: 3,
      proofEvents: [],
      curApprover: null,
      mode: -1, // 1 = Agency Review Screen, 0 = Approver Review Screen, 2 = Reviewer Only Screen
      viewMode: ViewMode.Review,
      initialProof: null,
      approverIpAddress: "",
      alertMessage: "",
      alertType: "success",
      scrollAnimationRootTask: null,
      scrollAnimationTask: null,
      approverFeedback: null,
      realTimeConnection: null,
      realTimeCursorConnection: null,
      realTimeHeartbeatTimer: null,
      inactivityTimeout: null,
      inactivityTimeoutAfter: 600000, // 10 mins,
      isDisconnectedDueToInactivity: false,
      isConnecting: false,
      isCursorConnecting: false,
      isMobile: window.innerWidth < 701,
      reviewVersionId: 0,
      compareVersionId: 0,
      connectedUsers: [],
    };
  },
  async created() {
  },
  async mounted() {
    let self = this;

    window.onresize = this.refreshIsMobile();

    self.logOutChannel = new BroadcastChannel('logoutChannel');

    self.logOutChannel.addEventListener('message', (event) => {
      if (event.data === 'logout') {
        //close the tab
        window.close();
      }
    });

    window.addEventListener('keydown', function (e) {
      if (e.key === ' ' && e.target === document.body) {
        e.preventDefault();
      }
    });

    this.ConnectToReviewHub();

    if (document.getElementById("hubspot-messages-iframe-container") !== null) {
      let a = document.getElementById("hubspot-messages-iframe-container");
      a.parentElement.removeChild(a);
    }

    this.$root.$on("alert::user", (message, type, timeout) => {
      if (timeout === undefined) {
        timeout = 5000;
      }
      self.alertMessage = message;
      self.alertType = type;
      setTimeout(function () {
        self.alertMessage = "";
        self.alertType = "success";
      }, timeout);
    });
    this.$root.$on("api::working", () => {
      self.loadingData = true;
    });
    this.$root.$on("api::idle", () => {
      self.loadingData = false;
    });

    this.approverIpAddress = await this.$A.ReviewService.WhoAmI();

    this.loadUserLang();
    await this.$A.LangService.loadLanguage(this.curAccount.id);

    document.title = this.curAccount !== null && this.curAccount.whiteLabelEnabled ? this.curAccount.name : "Ashore App";

    if (!this.$A.viewToViewData) await this.LoadViewData();

    if (this.$A.viewToViewData !== null && this.$A.viewToViewData.proof !== undefined && this.approverPublicId === undefined) {
      this.accessDenied = false;
      this.mode = PROOF_OWNER_MODE;
      this.curUser = this.$A.Auth;
      const authToken = _.get(this, '$A.Auth.apiToken.token', "");
      await this.setReviewProof({ proofId: this.$A.viewToViewData.proof.id });
      await this.RegisterRealTimeConnection(
          authToken,
          this.publicId
      );
    } else {
      this.mode = APPROVER_MODE;
      await this.GetApproverIdentity();
    }

    this.loadCustomStyle();

    window.onresize = () => {
      self.resize();
    }
    window.addEventListener('beforeunload', this.preventNavBeforeDecision);
  },
  methods: {
    LoadViewData: async function () {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      if (this.$A.Auth !== null) {
        let proof = await this.$A.ProofService.GetProofFromPubId(this.publicId);
        this.$A.viewToViewData = {
          proof: proof
        }
        if (params.tl) { // checking to see if the token used was valid and the user was logged in. else act like normal link
          let clean_uri = location.protocol + "//" + location.host + location.pathname;
          window.history.replaceState({}, document.title, clean_uri);
        }
      }
    },
    ConnectToReviewHub: function () {
    },
    RealtimeHeartbeat: function () {
      let self = this;
      if (this.isDisconnectedDueToInactivity || this.isConnecting) return;
      // Clear the existing timer
      clearTimeout(this.realTimeHeartbeatTimer);
      // Start a new timer
      this.realTimeHeartbeatTimer = setTimeout(function () {
        try {
          self.realTimeConnection.invoke("HeartBeat");
        } catch (e) {
          console.log("Error sending heartbeat:", e);
        }
        self.filterActiveUsers();
        self.RealtimeHeartbeat();
      }, 1000 * 10)
    },
    resetInactivityTimeout() {
      let self = this;
      // Clear the existing timer
      clearTimeout(this.inactivityTimeout);
      // Start a new timer
      this.inactivityTimeout = setTimeout(() => {
        self.isDisconnectedDueToInactivity = true;
        self.realTimeConnection.stop().then(() => {
          console.log("Review hub disconnected due to inactivity.");
          clearTimeout(self.realTimeHeartbeatTimer);
        }).catch(err => {
          console.error("Error disconnecting:", err);
        });
        self.realTimeCursorConnection.stop().then(() => {
          console.log("Cursor hub disconnected due to inactivity.");
        }).catch(err => {
          console.error("Error disconnecting:", err);
        });
      }, self.inactivityTimeoutAfter);
    },
    RegisterRealTimeCursorConnection: async function () {
      if (this.realTimeCursorConnection !== null) return;
      this.realTimeCursorConnection = this.$A.RealtimeCursorService.Connect();

      // ReceiveUserCursorUpdate
      this.realTimeCursorConnection.on("ReceiveUserCursorUpdate", async (userCursor) => {
        let self = this;
        const targetId = (userCursor.approverId) ? `a:${userCursor.approverId}` : `u:${userCursor.userId}`;

        if (_.get(self.activeUsers, `${targetId}.targetX`) == userCursor.x &&
          _.get(self.activeUsers, `${targetId}.targetY`) == userCursor.y &&
          _.get(self.activeUsers, `${targetId}.zoom`) == userCursor.zoom &&
          _.get(self.activeUsers, `${targetId}.zoomMode`) == userCursor.zoomMode &&
          _.get(self.activeUsers, `${targetId}.rotation`) == userCursor.rotation) {
          return;
        }

        self.activeUsers[targetId] = Object.assign(self.activeUsers[targetId] || {}, { ...userCursor, targetId });
        self.activeUsersList = Object.values(self.activeUsers);
        await self.filterActiveUsers();
        await self.updateUsersPointer(_.get(self.activeUsers, `${targetId}`));
      });

      // ReceiveAudioVideoTimelineCursorUpdate
      this.realTimeCursorConnection.on("ReceiveAudioVideoTimelineCursorUpdate", async (userCursor) => {
        // user audio-video cursor update
        // console.log(userCursor)
      });

      this.startCursorConnection()
    },
    startCursorConnection: async function () {
      if (this.isCursorConnecting) return;

      try {
        this.isCursorConnecting = true;
        await this.realTimeCursorConnection.start();

        console.log("realtime cursor connected.");
        this.isCursorConnecting = false;
        if(this.curProof) {
          const groupInfo = {
            accountId: this.curProof.accountId,
            proofId: this.curProof.id,
          }
          await this.realTimeCursorConnection.invoke("AddToGroup", groupInfo);
        }
      } catch (err) {
        console.error("Error connecting to realtime cursor hub:", err);
        setTimeout(this.startCursorConnection, 5000); // Retry connection after 5 seconds
      }
    },
    RegisterRealTimeConnection: async function (token, publicId, approverPublicId) {
      let self = this;
      if (this.realTimeConnection !== null || !token) {
        return;
      }
      this.realTimeConnection = this.$A.RealtimeService.Connect();

      //ReadyForRegistration
      this.realTimeConnection.on("ReadyForRegistration", async () => {
        try {
          await self.realTimeConnection.invoke(
              "RegisterReviewerConnection",
              token,
              publicId,
              "reviewPage"
          );
          if (approverPublicId)
            await self.realTimeConnection.invoke(
                "LogLocalIdentity",
                JSON.stringify(window.$A.LoadDataCache(`approver_keychain_${publicId}_${approverPublicId}`, true))
            );
        } catch (err) {
          console.error(err);
        }
      });

      //Registered
      this.realTimeConnection.on("Registered", async () => {
        self.RealtimeHeartbeat()
      });

      //ReconnectRequest
      this.realTimeConnection.on("ReconnectRequest", async (ipAddress) => {
        //reconnect real time at address
        //if fails after 3 attempts connect to api again.
        // console.log(ipAddress)
      });

      //PresenceUpdate
      this.realTimeConnection.on("PresenceUpdate", async (users) => {
        const ids = {};
        users.forEach(u => {
          const targetId = (u.approverId) ? `a:${u.approverId}` : `u:${u.userId}`;
          ids[targetId] = true;

          self.activeUsers[targetId] = Object.assign(self.activeUsers[targetId] || {}, u);
        });

        Object.keys(self.activeUsers).forEach(id => {
          if (!ids[id]) {
            delete self.activeUsers[id];
          }
        });

        self.activeUsersList = Object.values(self.activeUsers);
        await self.filterActiveUsers();
      });

      //ReceiveProofEvents
      this.realTimeConnection.on("ReceiveProofEvents", async (appEvent) => {
        const createdByCurrentUser = (appEvent.appEventObject.approverId === _.get(self.curApprover, 'id', -1) ||
            appEvent.appEventObject.userId === _.get(self.curUser, 'id', -1));

        // console.log("appEvent", appEvent);
        // if event created by same user do not include
        if (appEvent.eventType !== 34 && createdByCurrentUser) {
          return;
        }

        let proofVersionId = _.get(appEvent, 'appEventObject.commentAnnotation.proofVersionId');
        let fileId = _.get(appEvent, 'appEventObject.commentAnnotation.proofFileId');
        let comment = _.get(appEvent, 'appEventObject.commentAnnotation');
        let commentId = _.get(comment, 'id');
        let file = _.get(self.curProof, 'currentVersion.versionFiles', []).find(f => f.id === fileId);
        let isCurrentVersion = proofVersionId === _.get(self.curProof, 'currentVersion.id');
        let approver = _.get(appEvent, 'appEventObject.approver', null);
        let user = _.get(appEvent, 'appEventObject.user', null);
        const userId = _.get(appEvent, 'appEventObject.userId', 0);
        const approverId = _.get(appEvent, 'appEventObject.approverId', 0);

        switch (appEvent.eventType) {
          case 34:
            self.curProof.versionIds.push(appEvent.appEventObject.proofVersionCreated);
            if (!createdByCurrentUser) {
              self.proofEvents.push({
                ...appEvent.appEventObject,
                event: 'Created New Version'
              });
            }
            break;
          case 38:
            if (userId && user) {
              const engagedUsers =  _.get(self, 'curProof.engagedUsers', []);
              _.set(appEvent, 'appEventObject.user.color', self.$refs.proofView.getColor(userId));
              engagedUsers.push(appEvent.appEventObject.user);
              _.set(self, 'curProof.engagedUsers', engagedUsers);
            } else if (approverId && approver) {
              _.set(appEvent, 'appEventObject.approver.color', self.$refs.proofView.getColor(approverId));
              if (approver.approverType === 2) {
                const reviewers = _.get(self, 'curProof.currentVersion.currentStage.reviewers', []);
                reviewers.push(approver);
                _.set(self, 'curProof.currentVersion.currentStage.reviewers', reviewers);
              } else {
                const approvers = _.get(self, 'curProof.currentVersion.currentStage.approvers', [])
                approvers.push(approver);
                _.set(self, 'curProof.currentVersion.currentStage.approvers', approvers);
              }
            }

            break;
          case 39:
            const files = _.get(appEvent, 'appEventObject.filesApprovalStatus', []);
            const approvedFiles = files.filter(f => f.approved);
            const approvedWithChangesFiles = files.filter(f => f.approvedWithChanges);
            const notApprovedFiles = files.filter(f => !f.approved && !f.approvedWithChanges);
            let messages = [];
            if (approvedFiles.length) {
              messages.push(`${approvedFiles.length} file(s) approved`);
            }
            if (approvedWithChangesFiles.length) {
              messages.push(`${approvedWithChangesFiles.length} file(s) approved with changes`);
            }
            if (notApprovedFiles.length) {
              messages.push(`${notApprovedFiles.length} file(s) not approved`);
            }

            this.shouldShowNotifications() && self.proofEvents.push({
              ...appEvent.appEventObject,
              event: messages.join(', ')
            });
            break;
          case 45:
            // self.proofEvents.push({
            //   ...appEvent.appEventObject,
            //   event: 'Added a comment'
            // });
            if (isCurrentVersion && file) {
              file.proofFileAnnotations = file.proofFileAnnotations || [];
              file.proofFileAnnotations.push(comment);
            }
            break;
          case 49:
          case 50:
          case 51:
            if (appEvent.eventType === 49) {
              this.shouldShowNotifications(appEvent) && self.proofEvents.push({
                ...appEvent.appEventObject,
                event: 'Added a comment'
              });
            }
            if (isCurrentVersion && file) {
              if (appEvent.appEventObject.deletedCommentId) {
                comment.proofFileAnnotationComments = comment.proofFileAnnotationComments.filter(c => c.id !== appEvent.appEventObject.deletedCommentId);
              }
              file.proofFileAnnotations = file.proofFileAnnotations.map(a => {
                if (a.id === commentId) {
                  return comment;
                }
                return a;
              });
              if (!comment.proofFileAnnotationComments.length) {
                file.proofFileAnnotations = file.proofFileAnnotations.filter(a => a.id !== commentId);
              }
            }
            break;
          case 62:
            const { file : newFile, proofVersionId } = _.get(appEvent, 'appEventObject', {});
            const shouldUpdateVersionFiles =  _.get(self, 'curProof.currentProofVersionId', 0) === proofVersionId;
            if (!shouldUpdateVersionFiles) break;
            const updatedFile = {
              ...newFile,
              ...defaultFileProperties,
            };
            let newProof = { ...self.curProof };
            const curFileIndex = _.findIndex(_.get(newProof, 'currentVersion.versionFiles', []), { id: updatedFile.id });

            if (curFileIndex !== -1) {
              _.set(newProof, `currentVersion.versionFiles[${curFileIndex}]`, {
              ..._.get(newProof, `currentVersion.versionFiles[${curFileIndex}]`, {}),
              ...updatedFile,
              });
            } else {
              let currentVersionFiles = _.get(newProof, 'currentVersion.versionFiles', []);
              currentVersionFiles.push(updatedFile);
              _.set(newProof, "currentVersion.versionFiles", currentVersionFiles);
            }
            
            let proofEvents = _.get(newProof, 'proofEvents', []);
            proofEvents.push(appEvent);
            _.set(newProof, "proofEvents", proofEvents);

            await self.setReviewProof({ proof: newProof });

            break;
        }
      });
      //Start connection
      await this.startConnection();
    },
    startConnection: async function () {
      if (this.isConnecting) return;
      
      try {
        this.isConnecting = true;
        await this.realTimeConnection.start();
        console.log("realtime Connected.");

        this.isConnecting = false;
        if (this.isDisconnectedDueToInactivity) {
          this.startCursorConnection()
          this.isDisconnectedDueToInactivity = false;
          return;
        }

        await this.RegisterRealTimeCursorConnection();
      } catch (err) {
        console.error("Error connecting to realtime review hub:", err);
        setTimeout(this.startConnection, 5000); // Retry connection after 5 seconds
      }
    },
    filterActiveUsers: async function () {
      let self = this;
      this.activeUsersList = this.activeUsersList
          .filter(u => this.curProof != null && u.proofId === this.curProof.id)
          .filter(u => !u.lastHeartbeat ||
              moment(u.lastHeartbeat).add(this.activeUserInactivityInMinutes, "minute").isAfter(moment())
          );

      if (!self.curProof) return;

      // add users in active list but not in engaged users
      this.activeUsersList.forEach(user => {
        self.curProof.engagedUsers = self.curProof.engagedUsers || [];
        const engagedUserIds = Object.fromEntries(self.curProof.engagedUsers.map(u => [u.userId, true]));
        if (user.userId && !engagedUserIds[user.userId] && user.userId !== _.get(self.curUser, 'id')) {
          user.color = self.$refs.proofView.getColor(user.userId);
          user.id = user.userId;
          self.curProof.engagedUsers.push(user);
        }
      });

      // remove engaged users not in active list
      this.curProof.engagedUsers = this.curProof.engagedUsers || [];
      const activeIds = Object.fromEntries(this.activeUsersList.map(u => [u.userId, true]));
      this.curProof.engagedUsers = this.curProof.engagedUsers.filter(u => activeIds[u.id] || u.id === _.get(this.curUser, 'id'));
    },
    // Smoothly update the user pointer
    updateUsersPointer: async function (user) {
      if (user.approverId === _.get(this.curApprover, 'id', -1) ||
          user.userId === _.get(this.curUser, 'id', -1)) {
        return;
      }
      this.$root.$emit("cursor_position_update", user);
    },
    sendRealtimeCursorUpdate: async function (cursorUpdate) {
      if (this.isCursorConnecting) return;
      if (this.isDisconnectedDueToInactivity) {
        await this.startConnection();
        await this.reloadProof();
        this.resetInactivityTimeout();
        return;
      }
      this.resetInactivityTimeout();
      // do not send update if only user connected
      if (this.activeUsersList.length < 2) return;
      // do not send update if in compare mode
      if (this.isCompareMode) return;
      this.realTimeCursorConnection.invoke("BroadcastMousePosition", cursorUpdate);
    },
    sendRealtimeAudioVideoTimelineCursorUpdate: async function (cursorUpdate) {
      // do not send update if in compare mode
      if (this.isCompareMode) return;
      this.realTimeCursorConnection.invoke("BroadcastAudioVideoTimelineCursorUpdate", cursorUpdate);
    },
    GetApproverIdentity: async function () {
      const curApproverPublicId = this.approverPublicId || this.$A.LoadDataCache(`approver_id_${this.publicId}`, true);
      await this.$A.ReviewService.TryToGetApproverIdentity(this.publicId, curApproverPublicId)
          .then(this.handleApproverRegistered)
    },
    handleApproverRegistered: async function (approverIdentity) {
      if (approverIdentity !== null && approverIdentity.error !== undefined) {
        this.accessDeniedMessage = approverIdentity.error;
        this.accessDenied = true;
        return;
      }
      if (approverIdentity === "accessDenied") {
        this.accessDenied = true;
        this.accessDeniedMessage = "Access Denied";
        return;
      } else {
        this.accessDenied = false;
      }

      if (approverIdentity === null) {
        this.$refs.approverRegistrationModal.open();
        return;
      }

      if (approverIdentity.workflowStageApproverStatus !== undefined) {
        // load approver feedback result if the stage is the same and the approver did have feedback

        if (approverIdentity.approverType == 2) {
          this.mode = COMMENTER_MODE;
        }

        let statusCode = approverIdentity.workflowStageApproverStatus;

        if (statusCode <= 2) {
          this.approverFeedback = null;
        }
        if (statusCode > 2) {
          this.approverFeedback = {
            approvalChoice: statusCode === 3 ? 0 : statusCode === 4 ? 1 : 2,
            feedBackText: "",
            filesApprovalStatus: [],
          };
        }
      }

      this.$A.ReviewService.SetLoggedInApprover(approverIdentity);
      this.$A.Auth = {account: this.curAccount};
      this.curApprover = approverIdentity;
      await this.LoadViewData();
      await this.setReviewProof({ proofId: this.$A.viewToViewData.proof.id });

      this.$A.SaveDataCache(`approver_id_${this.publicId}`, approverIdentity.publicId , 90, true);

      await this.RegisterRealTimeConnection(
          approverIdentity.token,
          this.publicId,
          approverIdentity.publicId
      );
    },
    handleApproverResponse: async function (approverResponse) {
      this.approverFeedback = approverResponse;
      let status = approverResponse.approvalChoice;

      // approve indv. files then override
      // > 1 ApprovedWithChanges  => ApprovedWithChanges
      // > 1 not approved => not approved
      if (
          approverResponse.filesApprovalStatus !== undefined &&
          approverResponse.filesApprovalStatus.length > 0
      ) {
        let indvFiles = approverResponse.filesApprovalStatus;

        let approvedWithChangesCounter = 0;
        let notApprovedCounter = 0;

        for (let i = 0; i < indvFiles.length; i++) {
          if (indvFiles[i].approvedWithChanges) {
            approvedWithChangesCounter++;
          }
          if (!indvFiles[i].approved && !indvFiles[i].approvedWithChanges) {
            notApprovedCounter++;
          }
        }

        // map to server side ids (server: client modal mode)
        // 5=2 ApprovedWithChanges
        // 4=1 Approved
        // 3=0 NotApproved

        if (notApprovedCounter > 0) {
          approverResponse.approvalStatus = 3;
        } else if (approvedWithChangesCounter > 0) {
          approverResponse.approvalStatus = 5;
        } else {
          approverResponse.approvalStatus = 4;
        }
      } else {
        // map to server side ids (server: client modal mode)
        // 5=2 ApprovedWithChanges
        // 4=1 Approved
        // 3=0 NotApproved
        approverResponse.approvalStatus =
            status === 0 ? 3 : status === 1 ? 4 : 5;
      }

      window.scrollTo(0, 0);
      let response = await this.$A.ReviewService.ProcessApproverDecision(
          approverResponse
      );
      if (response && response.success) {
        this.$A.AlertUser(
            `${this.getLabel('approver_decision_modal_labels', 'approver_confirm_alert')}`,
            "success",
            10000
        );
      } else {
        this.$A.AlertUser(
            `${this.getLabel('approver_decision_modal_labels', 'approver_error_alert')}`,
            "warning",
            10000
        );
      }
    },
    preventNavBeforeDecision: function (e) {
      if (this.approverFeedback === null && this.mode === APPROVER_MODE) {
        e.preventDefault();
        this.$refs.navigationBeforeDecisionModal.open();
        e.returnValue = new Date();
        return new Date();
      }
    },
    setReviewProof: async function ({ proofId, proofVersion = 0, proof }) {
      let self = this;
      let interval = setInterval(async function () {
        if (self.$refs.proofView) {
          clearInterval(interval);
          self.$refs.proofView.proof = {
            id: proof ? proof.id : proofId
          };
          const curProofVersion = proof ? proof.currentVersion.id : proofVersion;
          await self.$refs.proofView.viewProofVersion(curProofVersion, proof);
        }
      }, 50)
    },
    setCompareProof: async function (proofId, proofVersion = 0) {
      let self = this;
      let interval = setInterval(async function () {
        if (self.$refs.compareProofView) {
          clearInterval(interval);
          self.$refs.compareProofView.proof = {
            id: proofId
          };
          await self.$refs.compareProofView.viewProofVersion(proofVersion);
        }
      }, 50)
    },
    switchToCompareViewMode: async function () {
      this.viewMode = ViewMode.Compare;
      this.$refs.proofView.updateZoom(1);
      let proofVersion = this.curProof.versionIds[this.curProof.versionIds.length - 2].proofVersionId;
      await this.setCompareProof(this.$refs.proofView.proof.id, proofVersion);
    },
    switchToReviewViewMode: function () {
      this.viewMode = ViewMode.Review;
    },
    setCurFile(file) {
      this.curFile = file;
    },
    setCurProof(proof) {
      this.curProof = proof;
    },
    resize: function () {
      this.$refs.proofView.resize();
      // if (this.isCompareMode) this.$refs.compareProofView.resize();
    },
    loadCustomStyle: function () {
      if (this.curAccount && this.curAccount.css) {
        let css = document.createElement("style");
        css.setAttribute("type", "text/css");
        css.innerHTML = this.curAccount.css;
        document.head.appendChild(css);
      }
    },
    getLabel: function (section, key) {
      return this.$A.LangService.getLabel(section, key);
    },
    setUserLang: function (lang) {
      this.$A.LangService.setUserLang(lang);
      this.loadUserLang();
      window.removeEventListener('beforeunload', this.preventNavBeforeDecision);
      window.location.reload();
    },
    loadUserLang: function () {
      this.curLang = this.$A.LangService.getUserLang();
      this.curDisplayLang = (this.curLang === 'es-us') ? 'Es' : 'En';
    },
    refreshIsMobile: function () {
      this.isMobile = window.innerWidth < 701;
    },
    captureApproverResponse: function (mode) {
      if (_.get(this.curProof, 'archived')) {
        $A.AlertUser('Proof Is Locked')
        return;
      }
      this.$refs.approverResponseModal.open(mode, this.approverFeedback);
    },
    handleReviewVersion: function (versionId) {
      this.reviewVersionId = versionId;
    },
    handleCompareVersion: function (versionId) {
      this.compareVersionId = versionId;
    },
    setReviewVersionId: async function (versionId) {
      await this.setReviewProof({ proofId: this.curProof.id, proofVersion: versionId });
    },
    setCompareVersionId: async function (versionId) {
      await this.setCompareProof(this.curProof.id, versionId);
    },
    reloadProof: async function () {
      await this.$refs.proofView.viewProofVersion(this.curProof.currentVersion.id);
    },
    getEventDefaultFields: function (user) {
      return {
        user,
        commentAnnotation: null,
        userId: user.userId,
        approverId: user.approverId,
        appEventType: null,
        proofVersionCreated: { id: null },
        triggeredAt: Date.now(),
      };
    },
    getUserId: function (user) {
      return user.approverId || user.userId;
    },
    shouldShowNotifications: function (user) {
      const allowViewAllComments = this.curProof.allowViewAllComments || this.curUser;
      if (!user) return !!allowViewAllComments;
      return !!allowViewAllComments || !!user.userId;
    }
  },
  computed: {
    showApprovedButton: function () {
      if (this.curProof === null) {
        return false;
      }
      if (this.allowApproveIndividualFiles) {
        return false;
      }

      if (!this.curApprover) return false;

      if (this.curProof.allowApproveWithChanges) {
        for (let i = 0; i < this.curProof.currentVersion.versionFiles.length; i++) {
          let vf = this.curProof.currentVersion.versionFiles[i];

          if (vf.proofFileAnnotations && vf.proofFileAnnotations.length > 0) {
            let annotationsByCurApprover = vf.proofFileAnnotations.filter(annotation => annotation.approverId === this.curApprover.id && annotation.proofFileAnnotationComments.length > 0);
            return annotationsByCurApprover.length === 0;
          }
        }
      }
      return true;
    },
    commentFileApi: function () {
      if (
          this.curProof &&
          this.curProof !== null &&
          this.curProof !== undefined &&
          this.curProof !== null
      ) {
        let apiUrl = "/annotation/comment-file/" + this.curProof.publicId;
        if (this.curUser !== null) {
          apiUrl += "?u=" + _.get(this, 'curUser.apiToken.token', '');
        }
        if (this.curApprover !== null) {
          apiUrl += "?a=" + _.get(this, 'curApprover.token', '');

        }
        return apiUrl;
      }
      return "";
    },
    allowApproveIndividualFiles: function () {
      return this.curProof !== null &&
      this.curProof.allowApproveIndividualFiles !== undefined
          ? this.curProof.allowApproveIndividualFiles
          : false;
    },
    curLogo: function () {
      return this.curAccount !== null &&
      this.curAccount.whiteLabelEnabled &&
      this.curAccount.whiteLabelLogoUri !== "" &&
      this.curAccount.whiteLabelLogoUri !== null
          ? this.curAccount.whiteLabelLogoUri
          : "https://high-seas.s3.amazonaws.com/email/logo.svg";
    },
    isReviewMode: function () {
      return this.viewMode === ViewMode.Review;
    },
    isCompareMode: function () {
      return this.viewMode === ViewMode.Compare;
    },
    versions: function () {
      return _.get(this.curProof, 'versionIds', []);
    },
  },
  watch: {
    activeUsersList(newActiveUsers, prevActiveUsers) {
      if (isEqual(prevActiveUsers, newActiveUsers)) return;

      const getUserId = this.getUserId.bind(this);
      const prevActiveUserIds = new Set(prevActiveUsers.map(getUserId));
      const newActiveUserIds = new Set(newActiveUsers.map(getUserId));
      const connectedUserIds = new Set(this.connectedUsers.map(getUserId));

      const currentUser = this.curApprover || this.curUser;

      const currentUserFromEvent = newActiveUsers.find(
        (user) => getUserId(user) === currentUser.id
      );

      newActiveUsers.forEach((user) => {
        const userId = getUserId(user);
        const isUserPrevConnected = connectedUserIds.has(userId);
        const isNewActiveUser = !prevActiveUserIds.has(userId);
        const isCurrentUser = currentUser.id === userId;

        if (isCurrentUser) return;

        const event = isUserPrevConnected ? RECONNECTED : CONNECTED;

        const isRecentlyConnectedUser = currentUserFromEvent && new Date(user.connectedAt) > new Date(currentUserFromEvent.connectedAt);

        if (isRecentlyConnectedUser && isNewActiveUser) {
          this.shouldShowNotifications(user) && this.proofEvents.push({
            ...this.getEventDefaultFields(user),
            event,
          });
        }
      });

      prevActiveUsers.forEach((user) => {
        const userId = getUserId(user);
        const isUserActive = newActiveUserIds.has(userId);
        const isCurrentUser = currentUser.id === userId;

        if (!isUserActive && !isCurrentUser) {
          this.shouldShowNotifications(user) && this.proofEvents.push({
            ...this.getEventDefaultFields(user),
            event: DISCONNECTED,
          });
        }
      });

      this.activeUsersList.forEach((activeUser) => {
        const activeUserId = getUserId(activeUser);
        const index = _.findIndex(
          this.connectedUsers,
          (user) => getUserId(user) === activeUserId
        );

        if (index === -1) this.connectedUsers.push(activeUser);
        else this.connectedUsers[index] = activeUser;
      });
    },
  },
};
</script>

<template>
  <div>
    <b-alert
        :variant="alertType"
        id="nav-alert"
        class="nav-alert m-0"
        :show="alertMessage.length > 0"
        dismissible
    >
      <span v-html="alertMessage"></span>
    </b-alert>
    <proof-view-compare-navigation
        v-if="isCompareMode"
        :avatar="curLogo"
        :versions="versions"
        :left-cur-version-id="reviewVersionId"
        :right-cur-version-id="compareVersionId"
        @switch-to-review="switchToReviewViewMode"
        @on-left-version-change="setReviewVersionId"
        @on-right-version-change="setCompareVersionId"
    />
    <div style="overflow-x: hidden;" class="row m-0">
      <div ref="review-version" :class="isCompareMode ? 'col-lg-6 col-sm-12 px-0' : 'col-12 p-0'">
        <proof-view label="review"
                    ref="proofView"
                    :mode="mode"
                    :view-mode="viewMode"
                    :cur-user="curUser"
                    :actives="activeUsersList"
                    :events="proofEvents"
                    :cur-logo="curLogo"
                    :cur-approver="curApprover"
                    :cur-account="curAccount"
                    :cur-lang="curLang"
                    :public-id="publicId"
                    :approver-feedback="approverFeedback"
                    :approver-public-id="approverPublicId"
                    :access-denied="accessDenied"
                    :access-denied-message="accessDeniedMessage"
                    :is-review-mode="isReviewMode"
                    :is-compare-mode="isCompareMode"
                    :comment-file-api="commentFileApi"
                    :cur-display-lang="curDisplayLang"
                    :show-approved-button="showApprovedButton"
                    v-on:proofLoaded="setCurProof($event)"
                    v-on:curFileChanged="setCurFile($event)"
                    @on-set-lang="setUserLang"
                    @on-response="captureApproverResponse"
                    @on-compare="switchToCompareViewMode"
                    @cursor-update="sendRealtimeCursorUpdate"
                    @on-audio-video-cursor-update="sendRealtimeAudioVideoTimelineCursorUpdate"
                    @version-changed="handleReviewVersion"
        />
      </div>
      <div ref="compare-version" v-if="isCompareMode" class="col-lg-6 col-sm-12 pl-0">
        <proof-view label="compare"
                    ref="compareProofView"
                    :mode="mode"
                    :view-mode="viewMode"
                    :cur-user="curUser"
                    :actives="activeUsersList"
                    :events="proofEvents"
                    :cur-approver="curApprover"
                    :cur-account="curAccount"
                    :public-id="publicId"
                    :cur-lang="curLang"
                    :approver-feedback="approverFeedback"
                    :approver-public-id="approverPublicId"
                    :access-denied="accessDenied"
                    :access-denied-message="accessDeniedMessage"
                    :is-review-mode="isReviewMode"
                    :is-compare-mode="isCompareMode"
                    :comment-file-api="commentFileApi"
                    @version-changed="handleCompareVersion"
        />
      </div>
      <!-- Modals -->
      <navigationBeforeDecisionModal ref="navigationBeforeDecisionModal"/>
      <approverResponseModal ref="approverResponseModal"
                             v-on:approverResponded="handleApproverResponse($event)"
                             :proof="curProof"
                             :account="curAccount"
                             :commentApiUrl="commentFileApi"
      />
      <approverRegistrationModal ref="approverRegistrationModal"
                                 :public-proof-id="publicId"
                                 v-on:onapproverregistered="handleApproverRegistered($event)"
      />
    </div>
  </div>
</template>

<style scoped>
#nav-alert {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  min-height: 40px;
  z-index: 99999999;
}
</style>

